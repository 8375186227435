import { Skeleton } from '@mui/material';
import DashboardCard from 'components/commonComponents/cards/DashboardCard';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchInCompleteWills } from 'services/adminService';

const IncompleteWillsCard = () => {
  const [incompleteWillsData, setIncompleteWillsData] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchIncompleteWillsData = async () => {
      try {
        const response = await fetchInCompleteWills();
        const { incompleteWillsCount, testatorIncompleteWillsCount, partnerIncompleteWillsCount } = response.data;
        setIncompleteWillsData({ incompleteWillsCount, testatorIncompleteWillsCount, partnerIncompleteWillsCount });
      } catch (error) {
        console.error('Error fetching total incomplete wills data:', error);
      } finally {
        setLoading(false); // Set loading to false after API call
      }
    };

    fetchIncompleteWillsData();
  }, []);

  const handleClick = () => {
    navigate('/admin/incompleteWills');
  };

  return (
    <>
      {
        loading ? (
          <Skeleton variant="rectangular" height={135} sx={{ borderRadius: '1.25rem' }} />
        ) : (
          <DashboardCard
            title="Total Incomplete Wills (Individual v/s Partner)"
            subtitle={`${incompleteWillsData?.incompleteWillsCount ?? '--'} (${incompleteWillsData?.testatorIncompleteWillsCount ?? '--'
              } / ${incompleteWillsData?.partnerIncompleteWillsCount ?? '--'})`}
            handleClick={handleClick}
            isClickable
          />
        )
      }
    </>
  );
};

export default IncompleteWillsCard;
