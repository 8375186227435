import { Box, Checkbox, Chip, Divider, Grid, IconButton, Step, StepConnector, StepContent, StepLabel, Stepper, styled, Typography, useTheme, } from '@mui/material';
import { ContextProvider } from 'App';
import { CustomAutocomplete } from 'components/DesignSystem/AutoComplete/CustomAutocomplete';
import { ContainedButton1 } from 'components/DesignSystem/Button';
import Icon from 'components/DesignSystem/Icon';
import InputField from 'components/DesignSystem/InputField';
import PhoneField from 'components/PhoneField';
import { useFormik } from 'formik';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { generateWillApi, previewDraftApi } from 'services/myWillService';
import { BankList } from 'utils/helper/BankList';
import formValidation from 'utils/helper/formValidations';
import * as Yup from 'yup';

const CustomInputField = ({ placeholder, name, value, onChange, sx, ...props }) => {
  return (
    <InputField
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
      sx={{
        '& .MuiInputBase-root': {
          height: '30px',
        },
        '& .MuiInputBase-input::placeholder': {
          fontSize: '14px',
        },
        ...sx,
      }}
      {...props}
    />
  );
};

const CustomStepIcon = ({ active, completed, icon }) => {
  const theme = useTheme();
  const color = completed || active ? '#FFF3EA' : theme.palette.grey[200];
  const border = completed || active ? '1px solid #FFA25C' : `1px solid ${theme.palette.grey[100]}`;
  const textColor = completed || active ? '#F47B20' : theme.palette.grey[100];
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 33, height: 33, borderRadius: '50%', border, backgroundColor: color, }} >
      <Typography color={textColor} variant="bodyText2">
        {icon}
      </Typography>
    </Box>
  );
};

const CustomInnerStepIcon = (props) => {
  const { active, completed } = props;
  return (
    <Box sx={{ marginLeft: '5px' }}>
      {completed ? (
        <Icon name="stepper-checked-orange.png" size={15} />
      ) : (
        <Icon name="stepper-unchecked-orange.png" size={15} />
      )}
    </Box>
  );
};

const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
  '& .MuiStepConnector-line': {
    borderColor: '#FFD5B5', // Default color for remaining steps
  },
  '&.Mui-active .MuiStepConnector-line': {
    borderColor: theme.palette.primary.main, // Color for the active step
  },
  '&.Mui-completed .MuiStepConnector-line': {
    borderColor: theme.palette.primary.main, // Color for completed steps
  },
}));

const CustomInnerStepConnector = styled(StepConnector)(({ theme }) => ({
  '& .MuiStepConnector-line': {
    borderLeft: `1px dashed ${theme.palette.primary.light}`,
  },
  '&.Mui-active .MuiStepConnector-line': {
    borderLeft: `1px dashed ${theme.palette.primary.main}`,
  },
  '&.Mui-completed .MuiStepConnector-line': {
    borderLeft: `1px dashed ${theme.palette.primary.main}`,
  },
}));


const WillGenerationChecklist = ({ setHtml }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);
  const [step1Completed, setStep1Completed] = useState([false, false, false, false]);
  const [resp, setResp] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { user, fetchUser } = useContext(ContextProvider);

  const initialValues = {
    executorHasCopy: false,
    copyWithPerson: false,
    copyInBankLocker: false,
    personName: '',
    location: '',
    bankName: '',
    branchName: '',
    bankLockerNo: '',
    branchAddress: '',
    wealthManager: false,
    wealthManagers: [
      {},
    ],
  }

  const formik = useFormik({
    initialValues,
    validate: (values) => {
      const schema = Yup.object({
        ...(values.copyWithPerson && {
          personName: formValidation.inputTextValidation.required("Required"),
          location: formValidation.inputTextValidation.required("Required"),
        }),
        ...(values.copyInBankLocker && {
          bankName: formValidation.inputTextValidation.required('Required field'),
          branchName: formValidation.inputTextValidation.required('Required field'),
          bankLockerNo: formValidation.bankLockerValidation.required('Required field'),
          branchAddress: Yup.string().required("Required"),
        }),
        ...(values.wealthManager && {
          wealthManagers: Yup.array().of(
            Yup.object().shape({
              name: formValidation.inputTextValidation.required('Required field'),
              clientId: formValidation.clientIdValidation.required("Required field"),
              email: formValidation.emailValidation.required("Required field"),
              phone: formValidation.phoneValidation,
              // relationshipManager: formValidation.inputTextValidation,
            })
          ),
        }),
      });

      try {
        schema.validateSync(values, { abortEarly: false });
        return {}; // No errors, return an empty object
      } catch (errors) {
        const errorObject = {};
        errors.inner.forEach((err) => {
          errorObject[err.path] = err.message;
        });
        return errorObject;
      }
    },
    onSubmit: async (values) => {
      setIsLoading(true)
      try {
        const apiPayload = {
          data: {
            executorHasCopy: values.executorHasCopy,
            additionalCopy: values.copyWithPerson,
            additionalCopyPerson: values.personName,
            additionalCopyPlace: values.location,
            bankLockerWillKept: values.copyInBankLocker,
            bankLockerNumber: values.bankLockerNo,
            branchName: values.branchName,
            branchAddress: values.branchAddress,
            bankName: values.bankName,
          },
          wealthManagerInfo: {
            hasWealthManager: values.wealthManager,
            managerData: values.wealthManagers,
          }
        };

        await generateWillApi(apiPayload);
        navigate('/willGenerationSuccess')
      }
      catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false); // End loading
      }
    },
  });

  const previewDraft = async () => {
    try {
      const response = await previewDraftApi();
      const lastWill = response.data?.lastWill;

      if (lastWill?.has_wealth_manager) {
        formik.setFieldValue('wealthManager', lastWill.has_wealth_manager);
        formik.setFieldValue(
          'wealthManagers',
          lastWill.wealth_manager_details || [
            {
              name: '',
              clientId: '',
              email: '',
              phone: '',
              relationshipManager: '',
            },
          ]
        );
      }

      if (lastWill) {
        // Use setFieldValue for each form field individually
        formik.setFieldValue('executorHasCopy', lastWill.executor_copy || false);
        formik.setFieldValue('copyWithPerson', lastWill.additional_copy || false);
        formik.setFieldValue('personName', lastWill.additional_copy_person || '');
        formik.setFieldValue('location', lastWill.additional_copy_place || '');
        formik.setFieldValue('copyInBankLocker', lastWill.bank_locker_will_kept || false);
        formik.setFieldValue('bankName', lastWill.bank_name || '');
        formik.setFieldValue('branchName', lastWill.branch_name || '');
        formik.setFieldValue('bankLockerNo', lastWill.bank_locker_number || '');
        formik.setFieldValue('branchAddress', lastWill.branch_address || '');
      }
      setHtml(response.data.html);
      setResp(response.data);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    // if (user?.role === 'reviewer' && !!review) {
    //   axios.interceptors.request.use((config) => {
    //     config.headers['X-Review-Id'] = +review;
    //     return config;
    //   });
    // }
    previewDraft();
  }, [user]);

  useEffect(() => {
    fetchUser();
  }, []);

  const handleStepClick = (step) => {
    // if (step === 1 && !isPlanPurchased) {
    //   return; // Block navigation to step 2 until the plan is purchased
    // }
    setActiveStep(step);
  };

  const handlePlanPurchase = () => {
    navigate('/Services');
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    formik.setFieldValue(name, checked);
    if (name === 'copyWithPerson' && !checked) {
      formik.setFieldValue('personName', '');
      formik.setFieldValue('location', '');
    }
    if (name === 'copyInBankLocker' && !checked) {
      formik.setFieldValue('bankName', '');
      formik.setFieldValue('branchName', '');
      formik.setFieldValue('bankLockerNo', '');
      formik.setFieldValue('branchAddress', '');
    }
  };

  const handleAddWealthManager = () => {
    if (formik.values.wealthManagers.length < 3) {
      formik.setFieldValue('wealthManagers', [
        ...formik.values.wealthManagers,
        { name: '', clientId: '', email: '', phone: '', relationshipManager: '' },
      ]);
    }
  };

  const handleDeleteWealthManager = (index) => {
    const updatedWealthManagers = formik.values.wealthManagers.filter((_, i) => i !== index);
    formik.setFieldValue('wealthManagers', updatedWealthManagers);
  };

  const handleWealthManagerCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    formik.setFieldValue('wealthManager', isChecked);
    // If unchecked, reset wealth managers
    if (!isChecked) {
      formik.setFieldValue('wealthManagers', [
        {},
      ]);
    }
  };

  const subscribed = useMemo(() => {
    // let consumed = user?.Subscriptions ?.length === 0;
    let consumed = true;
    user?.Subscriptions?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)).forEach((sub) => {
      if (sub) {
        const userSub = sub.UserSubscription;
        if (userSub && userSub.SubscriptionId === 1) {
          // const today = new Date();
          // const subDay = new Date(userSub.createdAt);
          // const subDays = (today - subDay) / 86400 / 1000;
          // const allowed = sub.days;
          // if (subDays > allowed) consumed = true;
          // else consumed = false;
          if (userSub.active === true) consumed = false;
          else consumed = true;
        }
      }
    });
    return !consumed;
  }, [user]);

  const prechecks = useMemo(() => {
    const testator = ['first_name', 'last_name', 'pan', 'dob'].filter(
      (f) => !Object.keys(user?.profile ?? {}).includes(f)
    );
    const distribution = [
      ...(resp?.assets ?? []),
      {
        id: null,
        information: { caption: 'Any other assets not included in the Assets that need to be distributed' },
        type: '',
        sub_type: 'Residual Assets',
      },
    ]
      .map(({ id, name, information }) => {
        let mapped = true;
        [{ id: 0, deaths: [] }, ...(resp?.scenarios ?? [])].forEach((sc) => {
          const will = resp?.wills?.find((w) => w.PropertyId === id && sc.id === w.ScenarioId);
          if (!will) {
            mapped = false;
          } else {
            let value = 100;
            let total = 0;
            if (will?.distribution_type === 'value') {
              value =
                information?.value ??
                information?.amount ??
                information?.insured_amount ??
                information?.investment_amount ??
                information?.deposited_amount;
            } else if (will?.distribution_type === 'quantity') {
              value = information?.quantity ?? information?.qty ?? information?.number;
            }
            will?.mapping?.forEach((mapping) => {
              total += +(mapping.share ?? 0);
            });
            if (Math.abs(Number((value - total).toFixed(2))) <= 0.00) {  // done 0.01 to 0.00
              total = value;
            }
            mapped = mapped && total === value;
          }
        });
        return {
          name:
            information?.name ??
            information?.description ??
            information?.description_or_name ??
            information?.description_of_vehicle,
          mapped,
        };
      })
      .filter((f) => !f.mapped);
    const assets = resp?.assets?.length > 0 ?? false;
    const beneficiaries = resp?.beneficiaries?.length > 0 ?? false;
    const executors = resp?.executors?.length > 0 ?? false;
    const witnesses = subscribed
      ? resp?.witnesses?.length === 2 ?? false
      : resp?.witnesses?.length === 2 || resp?.witnesses?.length === 1 || false;
    return { testator, assets, distribution, beneficiaries, executors, witnesses };
  }, [resp, user]);

  useEffect(() => {
    if (!prechecks) return;
    const { testator, assets, distribution, beneficiaries, executors, witnesses } = prechecks;
    // Check each step condition and update the step1Completed state accordingly
    setStep1Completed([
      testator.length === 0, // Step 1: Personal Details Filled
      beneficiaries && executors && witnesses, // Step 2: My People Added
      assets, // Step 3: Assets Added
      distribution.length === 0, // Step 4: Distribution Completed
    ]);
  }, [prechecks]);

  const handleRedirection = (route) => {
    navigate(route);
  };

  const handleSave = () => {
    console.log("formik.values ", formik.values)
    console.log('formik.errors ', formik.errors);
    formik.handleSubmit();
  };

  const isGenerateButtonDisabled = useMemo(() => {
    const [personalDetailsFilled, myPeopleAdded, assetsAdded, distributionCompleted] = step1Completed;
    const hasCopyWithPerson = formik.values.copyWithPerson;
    const requiredFields = {
      personName: hasCopyWithPerson && !formik.values.personName,
      location: hasCopyWithPerson && !formik.values.location,
    };
    const hasCopyInBankLocker = formik.values.copyInBankLocker;
    const requiredFieldsForBank = {
      bankName: hasCopyInBankLocker && !formik.values.bankName,
      branchName: hasCopyInBankLocker && !formik.values.branchName,
      bankLockerNo: hasCopyInBankLocker && !formik.values.bankLockerNo,
      branchAddress: hasCopyInBankLocker && !formik.values.branchAddress,
    };

    const hasWealthManager = formik.values.wealthManager;
    const isWealthManagerFormValid = () => {
      if (hasWealthManager) {
        const requiredFieldsFilled = formik.values.wealthManagers.every(manager =>
          manager.name && manager.phone && manager.email && manager.clientId
        );
        return requiredFieldsFilled;
      }
      return true;
    };
    const requiredFieldsIncomplete = Object.values(requiredFields).some(Boolean) ||
      Object.values(requiredFieldsForBank).some(Boolean);
    const hasFormikErrors = Object.keys(formik.errors).length > 0;
    // Check all conditions for button enablement
    return !(personalDetailsFilled && myPeopleAdded && assetsAdded && distributionCompleted && subscribed && isWealthManagerFormValid()) || requiredFieldsIncomplete || hasFormikErrors;
  }, [step1Completed, formik]);


  return (
    <Box sx={{ marginLeft: '32px', paddingRight: '80px' }}>
      <Typography variant="heading1" gutterBottom color={theme.palette.secondary.main}>
        Will Generation Checklist
      </Typography>
      <Stepper activeStep={activeStep} orientation="vertical" connector={<CustomStepConnector />}>
        <Step onClick={() => handleStepClick(0)}>
          <StepLabel StepIconComponent={CustomStepIcon} sx={{ marginLeft: '-4px' }}>
            <Typography variant="heading2">Checklist</Typography>
          </StepLabel>
          <StepContent
            sx={{
              borderLeft: activeStep === 0 ? `1px solid #FFD5B5` : theme.palette.primary.main,
            }}
          >
            <Stepper orientation="vertical" connector={<CustomInnerStepConnector />}>
              {[
                { label: 'Personal details filled', route: '/profilePage' },
                { label: 'My People added', route: '/myPeople' },
                { label: 'Assets added', route: '/assets' },
                { label: 'Distribution completed', route: '/distribution' },
              ].map(({ label, route }, index) => (
                <Step key={label} active={step1Completed[index]} completed={step1Completed[index]}>
                  <StepLabel StepIconComponent={CustomInnerStepIcon}>
                    <Typography
                      onClick={() => handleRedirection(route)}
                      variant="smallText"
                      sx={{
                        '&:hover': {
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      {label}
                    </Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </StepContent>
        </Step>

        <Step onClick={() => handleStepClick(1)}>
          <StepLabel StepIconComponent={CustomStepIcon} sx={{ marginLeft: '-4px' }}>
            {subscribed ? (
              <Typography variant="heading2" sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                Add to Cart<Icon name="purchase-plan-icon.png" size={20} />
              </Typography>
            ) : (
              <ContainedButton1
                onClick={handlePlanPurchase}
                sx={{ padding: '14px 20px', textTransform: 'none', maxHeight: '40px' }}
              >
                <Typography variant="bodyText3">Add to Cart</Typography>
              </ContainedButton1>
            )}
          </StepLabel>
        </Step>

        <Step onClick={() => handleStepClick(2)}>
          <StepLabel StepIconComponent={CustomStepIcon} sx={{ marginLeft: '-4px' }}>
            <Typography variant="heading2">Will Custody Log</Typography>
          </StepLabel>
          {activeStep === 2 && (
            <StepContent
              sx={{
                borderLeft: activeStep === 2 ? `1px solid #FFD5B5` : theme.palette.primary.main,
              }}
            >
              <Box component={'form'}>
                {willCustodyOptions.map(({ name, label, textFields }) => (
                  <Box key={name}>
                    <Box sx={{ display: 'flex', gap: '4px', marginBottom: '10px' }}>
                      <Checkbox
                        checked={formik.values[name]}
                        onChange={handleCheckboxChange}
                        name={name}
                        icon={<Icon name="orange-checkbox-default.png" size={15} />}
                        checkedIcon={<Icon name="orange-checkbox-checked.svg" size={15} />}
                        sx={{ padding: 0 }}
                      />
                      <Typography variant="smallText">{label}</Typography>
                    </Box>
                    <Box>
                      {formik.values[name] && (
                        <Grid container md={10} lg={10} spacing={1} columnSpacing={2}>
                          {textFields.map((field, index) => (
                            <Grid item xs={12} sm={12} md={field.name === 'bankName' ? 12 : 6} lg={field.name === 'bankName' ? 12 : 6} key={index}>
                              {field.name === 'bankName' ? (
                                <CustomAutocomplete
                                  name={field.name}
                                  type={'dropDown'}
                                  value={formik.values[field.name]}
                                  placeholder={field.label}
                                  handleChange={formik.handleChange}
                                  options={BankList}
                                  onBlur={formik.handleBlur}
                                  error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
                                  helperText={formik.touched[field.name] && formik.errors[field.name]}
                                  fullWidth
                                  sx={{
                                    '& .MuiInputBase-root': {
                                      height: '30px !important',
                                      display: 'flex',
                                      alignItems: 'center',
                                      padding: '0px 10px !important'
                                    },
                                    '& .MuiInputBase-input::placeholder': {
                                      fontSize: '14px',
                                      padding: 0,
                                      margin: 0,
                                      lineHeight: '30px',
                                    },
                                    '& .MuiAutocomplete-input': {
                                      padding: '0 8px !important',
                                      display: 'flex',
                                      alignItems: 'center',
                                    },
                                    '& .MuiAutocomplete-endAdornment': {
                                      height: '100%',
                                      display: 'flex',
                                      alignItems: 'flex-end',
                                    },
                                    '& .MuiAutocomplete-inputRoot': {
                                      paddingRight: '12px !important'
                                    },
                                  }}
                                />
                              ) : (
                                <CustomInputField
                                  fullWidth
                                  placeholder={field.label}
                                  name={field.name}
                                  value={formik.values[field.name]}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
                                  helperText={formik.touched[field.name] && formik.errors[field.name]}
                                />
                              )}
                            </Grid>
                          ))}
                        </Grid>
                      )}
                    </Box>
                    <Divider sx={{ borderStyle: 'dashed', my: 2 }} variant="fullWidth" orientation="horizontal" />
                  </Box>
                ))}
                <Box>
                  <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center', mb: '16px' }}>
                    <Checkbox
                      checked={formik.values.wealthManager}
                      onChange={handleWealthManagerCheckboxChange} // Updated handler
                      name="wealthManager"
                      icon={<Icon name="orange-checkbox-default.png" size={15} />}
                      checkedIcon={<Icon name="orange-checkbox-checked.svg" size={15} />}
                      sx={{ padding: 0 }}
                    />
                    <Typography variant="smallText">Add Wealth Manager(s)</Typography>
                    {formik?.values?.wealthManager && formik?.values?.wealthManagers?.length < 3 && (
                      <Chip
                        onClick={handleAddWealthManager}
                        label={<Typography variant="suggestiveText">Add +</Typography>}
                        sx={{ backgroundColor: '#F5F5F5', height: '19px', p: '8px 0' }}
                      />
                    )}
                  </Box>
                  {formik.values.wealthManager && (
                    <Box>
                      {formik?.values?.wealthManagers?.map((manager, index) => (
                        <Box key={index} sx={{ marginBottom: 2 }}>
                          <Grid container spacing={2}>
                            <Grid item md={5}>
                              <CustomInputField
                                placeholder="Name*"
                                name={`wealthManagers.${index}.name`}
                                value={manager.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik?.touched?.wealthManagers?.[index]?.name) && Boolean(formik.errors[`wealthManagers[${index}].name`]
                                )}
                                helperText={formik?.touched?.wealthManagers?.[index]?.name && formik.errors[`wealthManagers[${index}].name`]
                                }
                                fullWidth
                              />
                            </Grid>
                            <Grid item md={5}>
                              <CustomInputField
                                placeholder="Client ID*"
                                name={`wealthManagers.${index}.clientId`}
                                value={manager.clientId}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                  formik.touched.wealthManagers?.[index]?.clientId &&
                                  Boolean(formik.errors[`wealthManagers[${index}].clientId`])
                                }
                                helperText={
                                  formik.touched.wealthManagers?.[index]?.clientId &&
                                  formik.errors[`wealthManagers[${index}].clientId`]
                                }
                                fullWidth
                              />
                            </Grid>
                            {formik.values.wealthManagers.length > 1 && (
                              <Grid item md={2}>
                                <IconButton onClick={() => handleDeleteWealthManager(index)}>
                                  <Icon name="delete-icon-pink.svg" size={20} />
                                </IconButton>
                              </Grid>
                            )}
                            <Grid item md={5}>
                              <CustomInputField
                                placeholder="Email ID*"
                                name={`wealthManagers.${index}.email`}
                                value={manager.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                  formik.touched.wealthManagers?.[index]?.email &&
                                  Boolean(formik.errors[`wealthManagers[${index}].email`])
                                }
                                helperText={
                                  formik.touched.wealthManagers?.[index]?.email &&
                                  formik.errors[`wealthManagers[${index}].email`]
                                }
                                fullWidth
                              />
                            </Grid>
                            <Grid item md={5}>
                              <PhoneField
                                name={`wealthManagers.${index}.phone`}
                                value={manager.phone}
                                handleChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                  formik.touched.wealthManagers?.[index]?.phone &&
                                  Boolean(formik.errors[`wealthManagers[${index}].phone`])
                                }
                                helperText={
                                  formik.touched.wealthManagers?.[index]?.phone &&
                                  formik.errors[`wealthManagers[${index}].phone`]
                                }
                                placeholder="Mobile Number*"
                                disabled={false}
                                sx={{
                                  '& .MuiInputBase-root': {
                                    height: '30px',
                                  },
                                  '& .MuiInputBase-input::placeholder': {
                                    fontSize: '14px',
                                  },
                                }}
                              />
                            </Grid>
                            <Grid item md={5}>
                              <CustomInputField
                                placeholder="Relationship Manager"
                                name={`wealthManagers.${index}.relationshipManager`}
                                value={manager.relationshipManager}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                  formik.touched.wealthManagers?.[index]?.relationshipManager &&
                                  Boolean(formik.errors[`wealthManagers[${index}].relationshipManager`])
                                }
                                helperText={
                                  formik.touched.wealthManagers?.[index]?.relationshipManager &&
                                  formik.errors[`wealthManagers[${index}].relationshipManager`]
                                }
                                fullWidth
                              />
                              {/* console.log('formik.errors ', formik.errors["wealthManagers[0].name"]); */}

                            </Grid>
                          </Grid>
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>
              </Box>
            </StepContent>
          )}
        </Step>

        <Step>
          <StepLabel StepIconComponent={CustomStepIcon} sx={{ marginLeft: '-4px' }}>
            <ContainedButton1
              variant="contained"
              color="primary"
              sx={{ height: '40px', padding: '0 8px' }}
              onClick={handleSave}
              disabled={isLoading || isGenerateButtonDisabled}
            >
              <Typography variant="bodyText3" color={theme.palette.common.white}>
                Generate Will
              </Typography>
            </ContainedButton1>
          </StepLabel>
        </Step>
      </Stepper>
    </Box>
  );
};

export default WillGenerationChecklist;

const willCustodyOptions = [
  {
    name: 'executorHasCopy',
    label: 'Each executor has a copy of this Will',
    textFields: [],
  },
  {
    name: 'copyWithPerson',
    label: 'A copy of this Will is also with this person',
    textFields: [
      { label: 'Person Name*', name: 'personName' },
      { label: 'Location*', name: 'location' },
    ],
  },
  {
    name: 'copyInBankLocker',
    label: 'A copy of this Will is also kept in a bank locker',
    textFields: [
      { label: 'Bank Name*', name: 'bankName' },
      { label: 'Branch Name*', name: 'branchName' },
      { label: 'Bank Locker No.*', name: 'bankLockerNo' },
      { label: 'Branch Address*', name: 'branchAddress' },
    ],
  },
];
