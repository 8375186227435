const { Typography, useTheme, ListItemText } = require('@mui/material');

const CustomListItemText = ({ primary, secondary, ...props }) => {
    const theme = useTheme();
    return (
        <ListItemText
            primary={
                <Typography component={'div'} variant="smallText" color={theme.palette.grey[600]}>
                    {primary}
                </Typography>
            }
            secondary={
                <Typography component={'div'} variant="smallText" color={theme.palette.grey[400]}>
                    {secondary}
                </Typography>
            }
            {...props}
        />
    );
};

export { CustomListItemText };
