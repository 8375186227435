import { Box, Typography } from "@mui/material";
import { TextedButton1 } from "components/DesignSystem/Button";
import { useNavigate } from "react-router-dom";

const SkipToWillEditorButton = ({ sx = {} }) => {
    const navigate = useNavigate();

    const handleSkipToWillEditor = () => {
        // Logic for skipping to Will Editor
        navigate('/myPeople');
    };

    return (
        <Box
            sx={{
                ...sx, // Spread the passed position prop
            }}
        >
            <TextedButton1 onClick={handleSkipToWillEditor} sx={{ padding: '10px 20px' }}>
                <Typography variant="bodyText3">Skip to Will Editor</Typography>
            </TextedButton1>
        </Box>
    );
};

export default SkipToWillEditorButton;
