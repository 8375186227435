import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Paper, useTheme } from '@mui/material';
import { fetchFaqsApi } from 'services/landingPageService';

const FAQSection = () => {
  const theme = useTheme();
  const [faqs, setFaqs] = useState([]); // State to hold FAQs
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const response = await fetchFaqsApi(); // Replace with your API endpoint
        setFaqs(response.data); // Assuming data is an array of FAQs
        setLoading(false); // Set loading to false after data is fetched

      } catch (err) {
        setLoading(false);
      }
    };

    fetchFaqs();
  }, []);


  return (
    <Grid container spacing={3} sx={{ display: 'flex', flexDirection: 'row', minWidth: 'max-content', borderRadius: '15px' }}>
      {faqs.filter((row) => [1, 2, 13, 58, 60, 135, 145].includes(row.id)).map((faq, index) => (
        <Grid item key={index}>
          <Paper sx={{
            height: '13.563rem', width: '19.875rem', p: '20px', borderRadius: '15px', boxShadow: 'none', overflowY: 'scroll', // Enable vertical scrolling
            '&::-webkit-scrollbar': {
              display: 'none', // Hide the scrollbar for webkit browsers
            },
          }}>
            <Typography variant="bodyText4" color={theme.palette.grey[600]}>{faq.question}</Typography>
            <Grid item sx={12} mt='16px'>
              <Typography variant="bodyText4" color={theme.palette.grey[400]}>
                <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
              </Typography>
            </Grid>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default FAQSection;
