import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputField from '../InputField';

const CustomDatePicker = ({
    value,
    name,
    error,
    fullWidth,
    inputFormat,
    maxDate,
    maxDateMessage,
    onChange,
    onBlur,
    placeholder,
    fieldError=false,
    fieldHelperText='',
    fieldInputProps={},
    ...props
}) => {
    return (
        <DatePicker
            className="date-picker-container"
            value={value}
            name={name}
            error={error}
            fullWidth={fullWidth}
            inputFormat={inputFormat}
            maxDate={maxDate}
            maxDateMessage={maxDateMessage}
            onChange={onChange}
            onBlur={onBlur}
            renderInput={(params) => (
                <InputField
                    fullWidth
                    {...params}
                    placeholder={placeholder}
                    InputLabelProps={{ shrink: false }}
                    inputProps={{
                        ...params.inputProps,
                        placeholder,
                        ...fieldInputProps
                    }}
                    error={fieldError}
                    helperText={fieldHelperText}
                />
            )}
            PopperProps={{
                sx: {
                    '& .MuiPaper-root': {
                        fontFamily: 'DM Sans, sans-serif !important',
                    },
                    '&.MuiPickersCalendarHeader-root': {
                        fontFamily: 'DM Sans, sans-serif',
                        background: 'red',
                    },
                    '& .MuiPickersDay-root': {
                        fontFamily: 'DM Sans, sans-serif',
                    },
                    '& .MuiTypography-caption': {
                        fontFamily: 'DM Sans, sans-serif',
                    },
                    '& .MuiPickersYear-yearButton': {
                        fontSize: '16px',
                    },
                    '& .MuiPickersCalendarHeader-label': {
                        fontFamily: 'DM Sans, sans-serif',
                    }
                },
            }}
            {...props}
        />
    );
};

export default CustomDatePicker;
