import { Box, Dialog, DialogContent, IconButton } from '@mui/material';
import Icon from 'components/DesignSystem/Icon';

const MyWillModal = ({ open, handleClose, documentHtml }) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullScreen
        >
            <Box sx={{ position: 'absolute', top: 36, left: 30}}>
                <Icon name='will-logo.png' height={90} width={169} />
            </Box>
            <DialogContent
                style={{
                    padding: '48px 64px 32px 64px',
                    backgroundColor: '#C5C5C5',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                }}
            >
                <Box
                    style={{
                        width: '50%',
                        height: '90vh',
                        overflowY: 'scroll',
                        backgroundColor: '#ffffff',
                        padding: '24px',
                        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                        borderRadius: '20px',
                    }}
                >
                    <div
                        className="document-container"
                        dangerouslySetInnerHTML={{ __html: documentHtml }}
                    />
                </Box>
                <IconButton
                    style={{
                        // position: 'absolute',
                        // top: 16,
                        // right: 16,
                        color: '#fff',
                        background: '#fff',
                        marginLeft: '12px'
                    }}
                    onClick={handleClose}
                >
                    <Icon name='cross-icon.png' size={13} />
                </IconButton>

            </DialogContent>
        </Dialog>
    );
};

export default MyWillModal;