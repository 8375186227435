import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Grid, InputAdornment, List, ListItem, ListItemText, Paper, styled, Tab, Tabs, Typography, useTheme } from '@mui/material';
import Icon from 'components/DesignSystem/Icon';
import InputField from 'components/DesignSystem/InputField';
import Footer from 'components/featureComponent/landingPage/Footer';
import Header from 'components/featureComponent/landingPage/Header';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { fetchFaqsApi } from 'services/landingPageService';

// Custom Accordion Summary with background color and border radius
const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    margin: '8px 0',
    borderRadius: '15px',
    backgroundColor: theme.palette.grey[200], // Apply the grey[200] background color
    '&.Mui-expanded': {
        borderRadius: '15px',
    },
}));

// Custom Accordion with background color and border radius
const CustomAccordion = styled(Accordion)(({ theme }) => ({
    borderRadius: '15px !important',
    backgroundColor: theme.palette.grey[200], // Apply the grey[200] background color
    '&:before': {
        display: 'none',
    },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
    color: '#F47B20', // Unselected text color
    backgroundColor: '#FFFFFF', // Unselected background color
    borderRadius: '10px',
    border: '0.3px solid #F47B20',
    padding: '13px 30px',
    marginRight: '10px', // Space between buttons
    boxShadow: 'none',
    textDecoration: 'none', // Remove underline
    '&:hover': {
        backgroundColor: '#F7F7F7', // Slightly darker on hover
    },
    '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        color: '#FFFFFF',
        textDecoration: 'none',
        boxShadow: 'none', // Optional: you can add shadow if needed
    },
    '&.Mui-disabled': {
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.grey[500],
    },
}));

const ResourceHub = () => {
    const theme = useTheme();
    const [value, setValue] = useState(0);
    const [expandedPanel, setExpandedPanel] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [faqs, setFaqs] = useState([]); // State to hold FAQs
    const [loading, setLoading] = useState(true); // Loading state
    const [defaultCategory, setDefaultCategory] = useState('');
    const location = useLocation();
    const { reference } = location.state || {};


    useEffect(() => {
        if (reference === 'videos') {
            setValue(1); // Set to Videos tab
        } else {
            setValue(0); // Default to FAQs tab
        }
    }, [reference]);

    useEffect(() => {
        const fetchFaqs = async () => {
            try {
                const response = await fetchFaqsApi(); // Replace with your API endpoint
                setFaqs(response.data); // Assuming data is an array of FAQs
                setLoading(false); // Set loading to false after data is fetched

                // Set the default category (first category) after FAQs are fetched
                const uniqueCategories = Array.from(new Set(response.data.map(faq => faq.category)));
                if (uniqueCategories.length > 0) {
                    setDefaultCategory(uniqueCategories[0]);
                    setSelectedCategory(uniqueCategories[0]); // Set the first category as selected
                }
            } catch (err) {
                setLoading(false);
            }
        };

        fetchFaqs();
    }, [value]);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    const handleAccordionChange = (panel) => () => {
        setExpandedPanel((prevPanel) => (prevPanel === panel ? null : panel)); // Toggle the panel
    };

    // Set the default category (first category) on mount
    useEffect(() => {
        const uniqueCategories = Array.from(new Set(faqs.map(faq => faq.category)));
        if (uniqueCategories.length > 0) {
            setDefaultCategory(uniqueCategories[0]);
            setSelectedCategory(uniqueCategories[0]); // Set the first category as selected
        }
    }, []);

    // Video data as an object
    const videos = [
        {
            url: "https://www.youtube.com/embed/RhXl2fNhkjk?rel=0",
            title: "GetWillDone Introduction Video"
        },
        {
            url: "https://www.youtube.com/embed/bX-NIh3XW-A?rel=0",
            title: "Who can be a beneficiary?"
        },
        {
            url: "https://www.youtube.com/embed/s4OMayI0H_4?rel=0",
            title: "What is a Beneficiary?"
        },
        {
            url: "https://www.youtube.com/embed/_n9984AxCZ0?rel=0",
            title: "Who is an Executor of a Will?"
        },
        {
            url: "https://www.youtube.com/embed/SUE-XG-NBtg?rel=0",
            title: "Who can be a witness to your Will?"
        },
        {
            url: "https://www.youtube.com/embed/T_mauuyt5A0?rel=0",
            title: "What happens in absence of a Will?"
        },
        {
            url: "https://www.youtube.com/embed/XZlLsQsYDuA?rel=0",
            title: "What is a Will?"
        },
        {
            url: "https://www.youtube.com/embed/r2sNWTtiK84?rel=0",
            title: "Who is a Nominee?"
        },
        {
            url: "https://www.youtube.com/embed/68FYlpqN7UY?rel=0",
            title: "Who Should make a Will?"
        },
        {
            url: "https://www.youtube.com/embed/VLh1QMQLIyA?rel=0",
            title: "Difference between a nominee and Beneficiary"
        }
    ];

    // Filter FAQs based on selected category and search query
    const filteredFaqs = faqs.filter((faq) => {
        const searchText = searchQuery.toLowerCase();
        const matchesSearch = faq.question.toLowerCase().includes(searchText);
        const matchesCategory = selectedCategory ? faq.category === selectedCategory : true;
        // If there's a search query, ignore the category filter
        return searchQuery ? matchesSearch : matchesCategory && matchesSearch;
    });

    // Get unique categories
    const uniqueCategories = Array.from(new Set(faqs.map(faq => faq.category)));

    // // Get unique categories from filtered FAQs
    // const uniqueCategories = searchQuery
    //     ? Array.from(new Set(filteredFaqs.map(faq => faq.category)))
    //     : Array.from(new Set(faqs.map(faq => faq.category)));

    return (
        <>
            <Helmet>
                <title> Resource Hub | Get Will Done </title>
            </Helmet>
            <Container maxWidth='false' disableGutters sx={{ maxWidth: '120rem', padding: '0 35px', marginBottom: '50px' }}>
                <Header />
                <Box sx={{ display: 'flex', alignItems: 'center', alignContent: 'center', bgcolor: theme.palette.grey[200], borderRadius: '30px', width: '100%', marginTop: '29px', padding: '25px' }} >
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="title1" color={theme.palette.secondary.main}> Resource Hub</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ marginTop: '17px' }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="basic tabs example"
                                textColor="inherit"
                                indicatorColor="transparent"
                            >
                                <StyledTab label="FAQs" {...a11yProps(0)} sx={{ fontFamily: "DM Sans, sans-serif", textTransform: 'none' }} />
                                <StyledTab label="Videos" {...a11yProps(1)} sx={{ fontFamily: "DM Sans, sans-serif", textTransform: 'none' }} />
                            </Tabs>
                        </Grid>
                    </Grid>
                </Box>
                {/* Main Content */}
                {/* Conditional rendering based on selected tab */}
                {value === 0 ? (
                    <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '19px' }}>
                        {/* Left side - FAQ Categories */}
                        <Box sx={{ width: '25%', marginRight: '20px', minWidth: '25%' }}>
                            <Typography variant="title2" color={theme.palette.secondary.main}>Jump to Category</Typography>
                            <List component="nav">
                                {uniqueCategories.map((category, index) => (
                                    <ListItem
                                        key={index}
                                        selected={selectedCategory === category}
                                        onClick={() => {
                                            setSelectedCategory(category);
                                            setSearchQuery(''); // Reset search query on category selection
                                        }}
                                        sx={{
                                            cursor: 'pointer',
                                            backgroundColor: 'transparent !important',
                                            '&.Mui-selected': {
                                                backgroundColor: 'transparent', // Keep selected background transparent
                                                color: theme.palette.primary.main, // Change text color for selected item
                                                '& .MuiListItemText-root': {
                                                    boxShadow: '0px 1px 8px var(--sds-size-depth-200) #F47B204D', // Custom shadow
                                                },
                                                '&:hover': {
                                                    backgroundColor: 'transparent', // Ensure no background color change on hover for selected items
                                                },
                                            },
                                        }}
                                    >
                                        <ListItemText><Typography variant='bodyText4'>{category}</Typography></ListItemText>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>

                        {/* Right side - Display FAQs */}
                        <Box sx={{ width: '75%' }}>
                            <InputField
                                fullWidth
                                placeholder="Search (Enter keyword)"
                                value={searchQuery} // Bind the value to the search query state
                                onChange={(e) => {
                                    setSearchQuery(e.target.value); // Update search query
                                    if (e.target.value === '') {
                                        setSelectedCategory(defaultCategory); // Reset to default category when search is cleared
                                    } else {
                                        setSelectedCategory(''); // Unselect category while searching
                                    }
                                }}
                                sx={{
                                    '& .MuiInputBase-root': {
                                        backgroundColor: 'white',
                                        height: '2.5rem',
                                        padding: '13.5px 16px',
                                        border: `1px solid ${theme.palette.grey[100]}`
                                    },
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Icon name="gwd-icons-search.svg" height='23px' width='23px' />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Box marginTop={4}>
                                {filteredFaqs.map((faq, index) => (
                                    <CustomAccordion key={index} expanded={expandedPanel === faq.question} onChange={handleAccordionChange(faq.question)}>
                                        <CustomAccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <div style={{ position: 'relative', display: 'inline-block' }}>
                                                <Box style={{ marginBottom: expandedPanel === faq.question ? '12px' : '0' }}>
                                                    <Typography variant='heading2' color={theme.palette.grey[600]}>{faq.question}</Typography>
                                                </Box>
                                                {expandedPanel === faq.question && (
                                                    <div style={{ height: '1px', backgroundColor: '#F47B20', marginTop: '12px', width: '100%', position: 'absolute', bottom: 0, left: 0, }} />
                                                )}
                                            </div>
                                        </CustomAccordionSummary>
                                        <AccordionDetails>
                                            <Typography variant='bodyText1' color={theme.palette.grey[400]}>
                                                <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
                                            </Typography>
                                        </AccordionDetails>
                                    </CustomAccordion>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                ) : (
                    <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '19px' }}>
                        <Grid container spacing={5}>
                            {Object.values(videos).map((video, index) => (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                    <Paper
                                        sx={{
                                            borderRadius: '10px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            boxShadow: 'none',
                                        }}
                                    >
                                        {/* Video Iframe Container */}
                                        <Box
                                            sx={{
                                                width: '100%',
                                                height: '100%',
                                                borderRadius: '10px',  // Match the Figma border-radius for the video frame
                                                overflow: 'hidden',
                                            }}
                                        >
                                            <iframe
                                                width="100%"
                                                height="300px"
                                                src={video.url}
                                                title={video.title}
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                                style={{ borderRadius: '10px' }}
                                            />
                                        </Box>

                                        {/* Video Title Box */}
                                        <Box
                                            sx={{
                                                width: '100%',
                                                mt: '8px',  // Add space between video and title
                                                px: 1,  // Padding for some spacing inside the title container
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'flex-start',
                                                textAlign: 'left',
                                            }}
                                        >
                                            <Typography
                                                variant="heading1"
                                                sx={{ color: theme.palette.grey[600] }}  // Figma color styling
                                            >
                                                {video.title}
                                            </Typography>
                                        </Box>
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                )}
            </Container>
            <Footer />
        </>
    );
};

export default ResourceHub;
