import { Skeleton } from '@mui/material';
import DashboardCard from 'components/commonComponents/cards/DashboardCard';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchRepeatRateOfCustomers } from 'services/adminService';

const RepeatRateCard = () => {
  const [repeatRateData, setRepeatRateData] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRepeatRateData = async () => {
      try {
        const response = await fetchRepeatRateOfCustomers();
        const { repeatRate } = response.data;
        setRepeatRateData({ repeatRate });
      } catch (error) {
        console.error('Error fetching repeat rate data:', error);
      } finally {
        setLoading(false); // Set loading to false after API call
      }
    };

    fetchRepeatRateData();
  }, []);

  const handleClick = () => {
    navigate('/admin/repeatRate');
  };

  return (
    <>
      {
        loading ? (
          <Skeleton variant="rectangular" height={135} sx={{ borderRadius: '1.25rem' }} />
        ) : (
          <DashboardCard
            title="Repeat Rate of Customers"
            subtitle={(repeatRateData.repeatRate ?? '--') || 0}
            handleClick={handleClick}
            isClickable
          />
        )
      }
    </>
  );
};

export default RepeatRateCard;
