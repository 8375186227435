import axios from "axios";
import dayjs from 'dayjs';
import { filter } from "lodash";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

// @mui
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Card, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormHelperText, Grid, IconButton, Paper, Popover, Slider, Stack, Table, TableBody, TableContainer, Typography, useTheme } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ReactComponent as PeopleIcon } from 'assets/beneficiaries.svg';
import { ContainedButton1, TextedButton1 } from "components/DesignSystem/Button";
import CheckBox from "components/DesignSystem/CheckBox";
import CustomDatePicker from "components/DesignSystem/CustomDatePicker";
import { Dropdown } from "components/DesignSystem/DropDown";
import InputField from "components/DesignSystem/InputField";
import useSwalAlert from "components/DesignSystem/SwalAlert";
import { CustomTableCell, CustomTablePagination, CustomTableRow } from "components/DesignSystem/Table/CustomTable";
import MyPeopleCard from "components/featureComponent/myPeopleFlow/MyPeopleCard";
import Scrollbar from "oldLayouts/components/scrollbar/Scrollbar";
import { UserListHead, UserListToolbar } from "oldLayouts/components/user";
import PageContainer from "views/dashboard/PageContainer";
// mock

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    // { id: "edit-action" },
    { id: "name", label: "Name", alignRight: false },
    { id: "description", label: "Description", alignRight: false },
    { id: "type", label: "Type", alignRight: false },
    { id: "couponCodePrefix", label: "Code Prefix", alignRight: false },
    { id: "createdAt", label: "Created At", alignRight: false },
    { id: "startDate", label: "Start Date", alignRight: false },
    { id: "endDate", label: "End Date", alignRight: false },
    { id: "discount", label: "%Discount", alignRight: false },
    { id: "applicableServices", label: "Service(s) Applicable", alignRight: false },
    { id: "generatedCoupons", label: "Coupon Generated", alignRight: false, alignCenter: true },
    { id: "consumedCoupons", label: "Coupon Consumed", alignRight: false, alignCenter: true },
    { id: "balanceCoupons", label: "Coupon Balance", alignRight: false, alignCenter: true },
    { id: "status", label: "Status", alignRight: false },
    // { id: "action" }
];

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4
};

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => JSON.stringify(_user ?? {}).toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function CouponsPage() {
    const theme = useTheme();
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState("asc");
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState("name");
    const [filterName, setFilterName] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [coupons, setCoupons] = useState([]);
    const [addNew, setAddNew] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);
    const [applicableServices, setApplicableServices] = useState([]);
    const showSwalAlert = useSwalAlert();

    useEffect(() => {
        axios.get("/subscriptions").then(res => {
            setSubscriptions(res.data?.subscriptions ?? []);
        });
    }, []);

    const fetchAssets = () => {
        axios.get("/coupons").then(response => {
            const fetchedCoupons = response.data.coupons;
            // Sort the array by "status" and "endDate"
            if (fetchedCoupons.length > 0) {
                fetchedCoupons.sort((a, b) => {
                    // Sort by "status" (Active first)
                    if (a.status === "Active" && b.status !== "Active") {
                        return -1;
                    }
                    if (a.status !== "Active" && b.status === "Active") {
                        return 1;
                    }
                    // Sort by "endDate" (nearest to the current date)
                    const endDateA = new Date(a.endDate);
                    const endDateB = new Date(b.endDate);
                    const timeDiffA = Math.abs(endDateA - new Date());
                    const timeDiffB = Math.abs(endDateB - new Date());

                    return timeDiffA - timeDiffB;
                });
            }
            setCoupons(fetchedCoupons);
        }).catch(err => console.error(err));
    };

    const addNewCoupon = (closePopup = true) => {
        axios.post("/coupons", { couponName, couponDescription, couponCodePrefix, startWithNumber, noOfCoupons, startDate, endDate, discount, couponType, applicableServices }).then(response => {
            showSwalAlert({
                icon: "success",
                title: "Coupon saved successfully",
                timer: 3000
            });
            fetchAssets();
            if (closePopup) {
                setAddNew(false); // Close popup only if closePopup is true
            }
            resetForm();
        }).catch(err => {
            showSwalAlert({
                icon: "error",
                title: "Failed to add coupon",
                timer: 3000
            });
        });
    };

    useEffect(() => {
        fetchAssets();
    }, []);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = coupons.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - coupons.length) : 0;

    const filteredCoupons = applySortFilter(coupons, getComparator(order, orderBy), filterName);

    const isNotFound = !filteredCoupons.length && !!filterName;

    const [couponName, setCouponName] = useState('');
    const [couponDescription, setCouponDescription] = useState('');
    const [couponCodePrefix, setCouponCodePrefix] = useState('');
    const [startWithNumber, setStartWithNumber] = useState('');
    const [noOfCoupons, setNoOfCoupons] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [discount, setDiscount] = useState('');
    const [couponType, setCouponType] = useState('');
    const [couponCodePrefixError, setCouponCodePrefixError] = useState(null);
    const [couponNameError, setCouponNameError] = useState(null);
    const [startDateError, setStartDateError] = useState(null);
    const [endDateError, setEndDateError] = useState(null);
    // State for the consumed coupons popover
    const [anchorElConsumed, setAnchorElConsumed] = useState(null);
    const [anchorElGenerated, setAnchorElGenerated] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);

    const isValidDate = (dateString) => {
        // Check if the input is not empty
        if (!dateString) return false;

        // Attempt to create a Date object from the input
        const date = new Date(dateString);
        return !Number.isNaN(date.getTime()) && date instanceof Date;
    }

    const handleStartDateChange = (date) => {
        // setStartDate(date);
        const regex = /^\d{2}-\d{2}-(20\d{2}|21\d{2})$/;
        if (regex.test(dayjs(date).format('DD-MM-YYYY'))) {
            console.log('start date valid', date, new Date(), date <= new Date());
            if (isValidDate(date)) {
                if (date < new Date().setHours(0, 0, 0, 0)) {
                    console.log('Start date cannot be a past dates');
                    setStartDateError('Start date cannot be a past dates');
                }
                else if (endDate && date > endDate) {
                    // Validate that end date is not less than start date
                    setStartDateError('Start date cannot be greater than end date');
                } else {
                    setStartDateError(null);
                }
                setStartDate(date);

                if (date && endDate < date) {
                    // Validate that end date is not less than start date
                    setEndDateError('End date cannot be less than start date');
                } else if (endDate < new Date().setHours(23, 49, 0, 0)) {
                    // Validate that end date is not a past date
                    setEndDateError('End date cannot be a past dates');
                } else {
                    setEndDateError(null);
                }
            }
        }
        else {
            setStartDateError('Please enter a valid date (DD-MM-YYYY) with hyphen')
        }
    };

    const handleEndDateChange = (date) => {
        const regex = /^\d{2}-\d{2}-(20\d{2}|21\d{2})$/;
        if (regex.test(dayjs(date).format('DD-MM-YYYY'))) {
            if (isValidDate(date)) {
                if (date && startDate > date) {
                    console.log('start date cannot be greater than end date', date, startDate, startDate > date);
                    // Validate that end date is not less than start date
                    setStartDateError('Start date cannot be greater than end date');
                }
                else {
                    console.log('startDateError');
                    setStartDateError(null);
                }

                if (startDate && date < startDate) {
                    // Validate that end date is not less than start date
                    setEndDateError('End date cannot be less than start date');
                } else if (date < new Date().setHours(0, 0, 0, 0)) {
                    console.log('enddate', date, new Date(), date < new Date().setHours(0, 0, 0, 0));
                    // Validate that end date is not a past date
                    setEndDateError('End date cannot be a past date');
                } else {
                    setEndDateError(null);
                }
                setEndDate(date);
            }
        }
        else {
            setEndDateError('Please enter a valid date (DD-MM-YYYY) with hyphen')
        }
    };

    const handleCouponNameChange = (event) => {
        const couponNames = (coupons ?? []).map(b => b.name ?? "").filter(x => !!x);
        console.log("couponNames", couponNames);
        if (couponNames.includes(event.target.value)) {
            setCouponNameError(`${event.target.value} coupon name already exists`);
        }
        else {
            setCouponNameError(null);
        }
        setCouponName(event.target.value);
    }
    const handleCouponCodePrefixChange = (event) => {
        const couponCodePrefixs = (coupons ?? []).map(b => b.couponCodePrefix?.toUpperCase() ?? "").filter(x => !!x);
        if (couponCodePrefixs.includes(event.target.value)) {
            setCouponCodePrefixError(`${event.target.value} coupon code prefix already exists`);
        }
        else {
            setCouponCodePrefixError(null);
        }
        setCouponCodePrefix(event.target.value.toUpperCase());
    }
    const handleChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setApplicableServices([...applicableServices, +value]);
        } else {
            setApplicableServices(applicableServices.filter((id) => id !== +value));
        }
    };

    const resetForm = () => {
        setCouponName('');
        setCouponDescription('');
        setCouponCodePrefix('');
        setStartWithNumber('');
        setNoOfCoupons('');
        setStartDate(null);
        setEndDate(null);
        setDiscount('');
        setCouponType('');
        setApplicableServices([]);
        setStartDateError(null);
        setEndDateError(null);
    };
    const isAnyCheckboxSelected = applicableServices.length === 0;

    const handleClickConsumed = (event, row) => {
        setAnchorElConsumed(event.currentTarget);
        setSelectedRow(row);
    };

    const handleCloseConsumed = () => {
        setAnchorElConsumed(null);
        setSelectedRow(null);
    };

    const handleClickGenerated = (event, row) => {
        setAnchorElGenerated(event.currentTarget);
        setSelectedRow(row);
    };

    const handleCloseGenerated = () => {
        setAnchorElGenerated(null);
        setSelectedRow(null);
    };

    const couponTypeOptions = [
        {
            label: 'Auto Generation',
            value: "auto"
        },
        {
            label: 'Sequential Generation',
            value: "sequence"
        },
        {
            label: 'Date-Based Coupon',
            value: "date"
        }
    ];

    const openConsumed = Boolean(anchorElConsumed);
    const openGenerated = Boolean(anchorElGenerated);
    const idPopoverConsumed = openConsumed ? 'consumed-popover' : undefined;
    const idPopoverGenerated = openGenerated ? 'generated-popover' : undefined;
    return (
        <>
            <Helmet>
                <title> Coupons | Get Will Done </title>
            </Helmet>

            <PageContainer fullWidth style={{ maxWidth: "unset" }} showHelpIcon={false}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <Typography variant="title1" gutterBottom>
                        Coupons
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                        <MyPeopleCard icon={PeopleIcon} title="New Coupon" onClick={() => {
                            setAddNew(true)
                        }} />
                        <Box width='30%' >
                            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mb: '24px', mt: '16px' }}>
                    <Typography variant="heading2" gutterBottom>
                        All Coupons
                    </Typography>
                    <Divider sx={{ flexGrow: 1, marginLeft: '8px', borderBottomWidth: '1px' }} />
                </Box>

                <Dialog
                    open={addNew}
                    onClose={() => {
                        setAddNew(false);
                        resetForm();
                    }}
                    PaperProps={{
                        sx: {
                            borderRadius: '15px',
                            backgroundColor: '#FFFFFF',
                            position: 'absolute',
                            top: '9rem',
                            left: '25.8125rem',
                            right: '6.5625rem',
                            bottom: '3.125rem',
                            boxShadow: 24,
                            margin: '0', // Ensure no default margins
                        },
                    }}
                    maxWidth={false} // Disable default maxWidth
                >
                    <DialogTitle>
                        <Stack spacing={0.3} mt={1}>
                            <Typography variant="heading1" sx={{ color: theme.palette.secondary.main }}>
                                New Coupon
                            </Typography>
                        </Stack>
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={() => {
                            setAddNew(false);
                            resetForm();
                        }}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent>
                        <Box sx={{ width: '80%' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Dropdown
                                        name="couponType"
                                        value={couponType}
                                        handleChange={(e) => setCouponType(e.target.value)}
                                        placeholder="Coupon Type"
                                        options={couponTypeOptions}
                                    // dropdownWidth="270px"
                                    />
                                </Grid>
                                <Grid item xs={12} sx={{ marginTop: '40px' }}>
                                    <Typography variant="heading2">Applicable Services</Typography>
                                </Grid>
                                <Grid item container xs={12} >
                                    {subscriptions && subscriptions.filter(subscription => [1, 2, 5].includes(subscription.id)).map((subscription) => (
                                        <Grid item xs={12} md={4} key={subscription.id}>
                                            <Box display='flex' alignItems='center'>
                                                <CheckBox
                                                    checked={applicableServices.includes(subscription.id)}
                                                    onChange={handleChange}
                                                    value={subscription.id}
                                                    iconSize={19}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />

                                                <Typography variant="bodyText3" sx={{ ml: 1 }}>
                                                    {subscription.name}
                                                </Typography>

                                            </Box>
                                        </Grid>
                                    ))}
                                    {isAnyCheckboxSelected && (
                                        <FormHelperText sx={{ alignSelf: 'end', fontFamily: 'DM Sans, sans-serif', fontStyle: 'italic', color: theme.palette.error.main, marginTop: 1 }}>
                                            Please select at least one service.
                                        </FormHelperText>
                                    )}

                                </Grid>
                                <Grid item xs={12} sx={{ marginTop: '16px' }}>
                                    <Typography variant="heading2">Coupon Details</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <FormControl
                                        required
                                        error={couponNameError}
                                        fullWidth
                                        variant="standard"
                                    >
                                        <InputField
                                            placeholder="Coupon Name"
                                            fullWidth
                                            error={couponNameError}
                                            value={couponName}
                                            onChange={handleCouponNameChange}
                                            bottomText={couponNameError}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <FormControl
                                        required
                                        fullWidth
                                        variant="standard"
                                    >
                                        <InputField
                                            placeholder="Coupon Description"
                                            fullWidth
                                            value={couponDescription}
                                            onChange={(e) => setCouponDescription(e.target.value)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <FormControl
                                        required
                                        error={couponCodePrefixError}
                                        fullWidth
                                        variant="standard"
                                    >
                                        <InputField
                                            placeholder="Coupon Code Prefix"
                                            fullWidth
                                            id="couponCodePrefix-input"
                                            error={couponCodePrefixError}
                                            value={couponCodePrefix}
                                            onChange={handleCouponCodePrefixChange}
                                            bottomText={couponCodePrefixError}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Typography id="slider-label" variant="bodyText3">Discount: {discount}%</Typography>
                                    <Slider
                                        value={discount}
                                        onChange={(e) => setDiscount(e.target.value)}
                                        valueLabelDisplay="auto"
                                        valueLabelFormat={(value) => `${value}%`}
                                        step={0.5}
                                        min={0.5}
                                        max={100}
                                        aria-labelledby="slider-label"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <FormControl
                                        required
                                        error={startDateError}
                                        fullWidth
                                        variant="standard"
                                    >
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <CustomDatePicker
                                                name="Start Date"
                                                value={startDate}
                                                error={startDateError}
                                                fullWidth
                                                inputFormat={"dd-MM-yyyy"}
                                                minDate={new Date()}
                                                minDateMessage="Past dates are not allowed"
                                                onChange={handleStartDateChange}
                                                placeholder="Start Date"
                                                fieldError={startDateError}
                                                fieldHelperText={startDateError}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <FormControl
                                        required
                                        error={endDateError}
                                        fullWidth
                                        variant="standard"
                                    >
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <CustomDatePicker
                                                name="End Date (Expiry Date)"
                                                value={endDate}
                                                error={endDateError}
                                                inputFormat={"dd-MM-yyyy"}
                                                minDate={startDate ?? new Date()}
                                                minDateMessage="Past dates are not allowed"
                                                onChange={handleEndDateChange}
                                                placeholder="End Date (Expiry Date)"
                                                fieldError={endDateError}
                                                fieldHelperText={endDateError}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    {["auto", "sequence"].includes(couponType) && (
                                        <InputField
                                            fullWidth
                                            type="number"
                                            placeholder="No of Coupons"
                                            value={noOfCoupons}
                                            inputProps={{
                                                step: 1, // To allow floating-point numbers
                                                min: 1,    // Minimum value
                                                max: 1000,  // Maximum value
                                            }}
                                            onChange={(e) => {
                                                let value = e.target.value;
                                                if (value !== "") {
                                                    value = parseInt(value, 10);
                                                }
                                                if (value !== "" && value < 1) {
                                                    value = 1;
                                                } else if (value > 1000) {
                                                    value = 1000;
                                                }
                                                setNoOfCoupons(value)
                                            }}
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    {couponType === "sequence" && (
                                        <InputField
                                            type="number"
                                            fullWidth
                                            placeholder="Start with Number"
                                            value={startWithNumber}
                                            inputProps={{
                                                step: 1, // To allow floating-point numbers
                                                min: 1,    // Minimum value
                                                // max: 1000,  // Maximum value
                                            }}
                                            onChange={(e) => {
                                                let value = e.target.value;
                                                console.log('value', value);
                                                if (value !== "" && value < 1) {
                                                    value = 1;
                                                }
                                                setStartWithNumber(value);
                                            }
                                            }
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <TextedButton1 disabled={isAnyCheckboxSelected || couponName === "" || couponCodePrefix === "" || couponNameError !== null || couponCodePrefixError !== null || startDate === null || endDate === null || startDateError !== null || endDateError !== null || discount === "" || couponType === "" || (couponType === "auto" && noOfCoupons === "") || (couponType === "sequence" && (noOfCoupons === "" || startWithNumber === ""))}
                            onClick={() => {
                                addNewCoupon(false)
                            }} sx={{ height: '45px', ml: 1, width: '12vw', mb: '25px' }}>
                            <Typography variant='bodyText3'>
                                Save & Add more
                            </Typography></TextedButton1>
                        <ContainedButton1 id={"new-generic-button"} disabled={isAnyCheckboxSelected || couponName === "" || couponCodePrefix === "" || couponNameError !== null || couponCodePrefixError !== null || startDate === null || endDate === null || startDateError !== null || endDateError !== null || discount === "" || couponType === "" || (couponType === "auto" && noOfCoupons === "") || (couponType === "sequence" && (noOfCoupons === "" || startWithNumber === ""))}
                            onClick={() => {
                                addNewCoupon();
                            }} sx={{ height: '45px', width: '6vw', mr: '25px', mb: '25px' }}>
                            <Typography variant='bodyText3'>
                                Save
                            </Typography>
                        </ContainedButton1>
                    </DialogActions>
                </Dialog>

                <Card>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={coupons.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {filteredCoupons.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                        const { id, type, name, description, discount, applicableServices, couponCodePrefix, startWithNumber, consumedCoupons, generatedCoupons, createdAt, startDate, endDate, noOfCoupons, status } = row;
                                        const selectedUser = selected.indexOf(id) !== -1;
                                        const serviceNames = subscriptions.filter(subscription => applicableServices.includes(subscription.id)).map(sub => sub.name);
                                        return (<>
                                            <CustomTableRow
                                                key={id} tabIndex={-1}>
                                                <CustomTableCell >{name}</CustomTableCell>
                                                <CustomTableCell >{description}</CustomTableCell>
                                                <CustomTableCell >{type}</CustomTableCell>
                                                <CustomTableCell >{couponCodePrefix}</CustomTableCell>
                                                <CustomTableCell >{dayjs(createdAt).format('DD-MM-YYYY')}</CustomTableCell>
                                                <CustomTableCell >{dayjs(startDate).format('DD-MM-YYYY')}</CustomTableCell>
                                                <CustomTableCell >{dayjs(endDate).format('DD-MM-YYYY')}</CustomTableCell>
                                                <CustomTableCell >{`${discount}%` ?? ""}</CustomTableCell>
                                                <CustomTableCell >{subscriptions.filter(subscription => applicableServices.includes(subscription.id)).map(sub => <Chip size="small" sx={{ marginBottom: 1, marginRight: 1 }} key={sub.id} label={`${sub.name}`} variant="outlined" />)}</CustomTableCell>
                                                {/* <CustomTableCell >
                                                    <Button
                                                        // onClick={handleMicrosoftLogin}
                                                        fullWidth
                                                        sx={{ paddingTop: '7px', paddingBottom: '7px' }}
                                                        // variant="outlined"
                                                        // color="primary"
                                                        component="a"
                                                        // startIcon={<img src={'/assets/Sign-In-Apple.png'} height={'32px'} alt="Sign In with Apple" />}
                                                        href={`${process.env.REACT_APP_API_URL}/coupons/generate-pdf/${id}-generated`}
                                                        target="_blank"
                                                    >
                                                        <Chip sx={{ cursor: 'pointer' }} label={`${generatedCoupons.length}`} variant="outlined" />
                                                    </Button>
                                                </CustomTableCell> */}
                                                <CustomTableCell >
                                                    <Button
                                                        onClick={(e) => handleClickGenerated(e, row)}
                                                        fullWidth
                                                        sx={{ paddingTop: '7px', paddingBottom: '7px' }}
                                                    >
                                                        <Chip sx={{ cursor: 'pointer' }} label={`${generatedCoupons.length}`} variant="outlined" />
                                                    </Button>
                                                    <Popover
                                                        id={idPopoverGenerated}
                                                        open={openGenerated}
                                                        anchorEl={anchorElGenerated}
                                                        onClose={handleCloseGenerated}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'center',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'center',
                                                        }}
                                                        PaperProps={{
                                                            sx: {
                                                                boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.1)', // Light shadow
                                                                border: '1px solid', // Add a border
                                                                borderColor: 'grey.500', // Change color as needed
                                                                p: 2
                                                            },
                                                        }}
                                                    >
                                                        <Typography variant="h6" sx={{ mb: 1 }}>
                                                            Generated Coupons
                                                        </Typography>
                                                        <Divider sx={{ mb: 1 }} /> {/* Add a divider with some margin */}
                                                        <Typography>
                                                            {selectedRow && selectedRow.generatedCoupons.map((coupon, index) => (
                                                                <div key={index}>{coupon}</div>
                                                            ))}
                                                        </Typography>
                                                    </Popover>
                                                </CustomTableCell>
                                                {/* <CustomTableCell >
                                                    <Button
                                                        // onClick={handleMicrosoftLogin}
                                                        fullWidth
                                                        sx={{ paddingTop: '7px', paddingBottom: '7px' }}
                                                        // variant="outlined"
                                                        // color="primary"
                                                        component="a"
                                                        // startIcon={<img src={'/assets/Sign-In-Apple.png'} height={'32px'} alt="Sign In with Apple" />}
                                                        href={`${process.env.REACT_APP_API_URL}/coupons/generate-pdf/${id}-consumed`}
                                                        target="_blank"
                                                    >
                                                        <Chip sx={{ cursor: 'pointer' }} label={`${consumedCoupons.length}`} variant="outlined" />
                                                    </Button>
                                                </CustomTableCell> */}
                                                <CustomTableCell >
                                                    <Button
                                                        onClick={(e) => handleClickConsumed(e, row)}
                                                        fullWidth
                                                        sx={{ paddingTop: '7px', paddingBottom: '7px' }}
                                                    >
                                                        <Chip sx={{ cursor: 'pointer' }} label={`${consumedCoupons.length}`} variant="outlined" />
                                                    </Button>
                                                    <Popover
                                                        id={idPopoverConsumed}
                                                        open={openConsumed}
                                                        anchorEl={anchorElConsumed}
                                                        onClose={handleCloseConsumed}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'center',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'center',
                                                        }}
                                                        PaperProps={{
                                                            sx: {
                                                                boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.1)', // Light shadow
                                                                border: '1px solid', // Add a border
                                                                borderColor: 'grey.500', // Change color as needed
                                                                p: 2
                                                            },
                                                        }}
                                                    >
                                                        <Typography variant="h6" sx={{ mb: 1 }}>
                                                            Consumed Coupons
                                                        </Typography>
                                                        <Divider sx={{ mb: 1 }} /> {/* Add a divider with some margin */}
                                                        <Typography>
                                                            {selectedRow && selectedRow.consumedCoupons.map((coupon, index) => (
                                                                <div key={index}>{coupon}</div>
                                                            ))}
                                                        </Typography>
                                                    </Popover>
                                                </CustomTableCell>
                                                <CustomTableCell align="center">{generatedCoupons.length - consumedCoupons.length}</CustomTableCell>
                                                <CustomTableCell >{status}</CustomTableCell>


                                                {/* <CustomTableCell align="left">{information?.nominee ?? "-"}</CustomTableCell> */}

                                                {/* <CustomTableCell>
                                                    {<>
                                                        <ButtonGroup>
                                                            <Tooltip title={`Edit Asset`}>
                                                                <IconButton size="small" color="primary" onClick={async (e) => {
                                                                    handleOpenMenu(e, row);
                                                                }}>
                                                                    <Iconify icon={"fluent:edit-32-regular"} />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title={`Delete Asset`}>
                                                                <IconButton size="small" color="primary" onClick={async (e) => {
                                                                    Swal.fire({
                                                                        title: "Are you sure?",
                                                                        text: (`Do you want to delete the asset?`),
                                                                        icon: "warning",
                                                                        showCancelButton: true,
                                                                        allowEnterKey: false,
                                                                        allowOutsideClick: false,
                                                                        confirmButtonColor: "#3085d6",
                                                                        cancelButtonColor: "#d33",
                                                                        confirmButtonText: "Yes"
                                                                    }).then((result) => {
                                                                        if (result.isConfirmed) {
                                                                            deleteAsset(id);
                                                                        }
                                                                    });
                                                                }}>
                                                                    <Iconify icon={"fluent:delete-32-regular"} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </ButtonGroup>
                                                    </>}
                                                </CustomTableCell> */}
                                            </CustomTableRow>

                                        </>);
                                    })}
                                    {emptyRows > 0 && (
                                        <CustomTableRow style={{ height: 53 * emptyRows }}>
                                            <CustomTableCell colSpan={TABLE_HEAD.length} />
                                        </CustomTableRow>
                                    )}
                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        <CustomTableRow>
                                            <CustomTableCell align="center" colSpan={TABLE_HEAD.length} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    <Typography variant="heading1" paragraph>
                                                        Not found
                                                    </Typography>

                                                    <Typography variant="bodyText3">
                                                        No results found for &nbsp;
                                                        <strong>&quot;{filterName}&quot;</strong>.
                                                        <br /> Try checking for typos or using complete words.
                                                    </Typography>
                                                </Paper>
                                            </CustomTableCell>
                                        </CustomTableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <CustomTablePagination
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        component="div"
                        count={coupons.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </PageContainer>

        </>
    );
}
