import { capitalCase } from "change-case";

export const getCorrectCase = (str) => {
  // Directly handle known values that should remain unchanged or transformed
  const knownValues = {
    "cas_nsdl": "CAS NSDL",
    "cas_csdl": "CAS CSDL",
    // Add other known values as needed
  };

  // Check if the key exists in knownValues and return it if found
  if (knownValues[str.toLowerCase()]) {
    return knownValues[str.toLowerCase()];
  }

  // For general case transformation
  const val = capitalCase(str);
  return val
    .replace(/\b(epf|ppf|dob|invits|reits|llp)\b/gi, v => v.toUpperCase())
    .replace(/\b(and|or|of|the|in)\b/gi, v => v.toLowerCase());
};
