import { Box } from '@mui/material';
import './documentPreview.scss';

const DocumentPreview = ({ documentHtml }) => {

  return (
    <Box id="documentPreviewContainer">
      <Box
        id="documentPreview"
        sx={{
          transition: 'transform 0.3s ease',
        }}
      >
        {/* Render the HTML content */}
        <Box dangerouslySetInnerHTML={{ __html: documentHtml }} />
      </Box>
    </Box>
  );
};

export default DocumentPreview;
