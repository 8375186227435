import { Skeleton } from '@mui/material';
import DashboardCard from 'components/commonComponents/cards/DashboardCard';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchCompleteWills } from 'services/adminService';

const CompleteWillsCard = () => {
  const [completeWillData, setCompleteWillData] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCompleteWillsData = async () => {
      try {
        const response = await fetchCompleteWills();
        const { completeWillsCount, testatorCompleteWillsCount, partnerCompleteWillsCount } = response.data;
        setCompleteWillData({ completeWillsCount, testatorCompleteWillsCount, partnerCompleteWillsCount });
      } catch (error) {
        console.error('Error fetching complete wills data:', error);
      } finally {
        setLoading(false); // Set loading to false after API call
      }
    };

    fetchCompleteWillsData();
  }, []);

  const handleClick = () => {
    navigate('/admin/completedWills');
  };

  return (
    <>
      {
        loading ? (
          <Skeleton variant="rectangular" height={135} sx={{ borderRadius: '1.25rem' }} />
        ) : (
          <DashboardCard
            title="Total Completed Wills (Individual v/s Partner)"
            subtitle={`${completeWillData?.completeWillsCount ?? '--'} (${completeWillData?.testatorCompleteWillsCount ?? '--'
              } / ${completeWillData?.partnerCompleteWillsCount ?? '--'})`}
            handleClick={handleClick}
            isClickable
          />
        )
      }
    </>
  );
};

export default CompleteWillsCard;
