import axios from "axios";

export const fetchTotalClients = async (list = false) => {
    const response = await axios.get(`/admin/total-testators${list ? '?list=true' : ''}`);
    return response;
}

export const fetchTotalPartners = async (list = false) => {
    const response = await axios.get(`/admin/total-partners${list ? '?list=true' : ''}`);
    return response;
}

export const fetchTopPerformingPartners = async (list = false) => {
    const response = await axios.get(`/admin/top-performing-partner${list ? '?list=true' : ''}`);
    return response;
}

export const fetchConversionRatio = async (list = false) => {
    const response = await axios.get(`/admin/signup-vs-paid-ratio${list ? '?list=true' : ''}`);
    return response;
}

export const fetchAverageTimeOfWillGeneration = async () => {
    const response = await axios.get(`/admin/AvgTimeOfWillGeneration`);
    return response;
}

export const fetchRepeatRateOfCustomers = async (list = false) => {
    const response = await axios.get(`/admin/repeat-rate${list ? '?list=true' : ''}`);
    return response;
}

export const fetchTotalRevenue = async () => {
    const response = await axios.get(`/admin/total-revenue`);
    return response;
}

export const fetchTotalCommissionPaid = async () => {
    const response = await axios.get(`/admin/total-commission`);
    return response;
}

export const fetchTotalGstPaid = async () => {
    const response = await axios.get(`/admin/total-gst`);
    return response;
}

export const fetchCompleteWills = async (list = false) => {
    const response = await axios.get(`/admin/complete-wills-count${list ? '?list=true' : ''}`);
    return response;
}

export const fetchInCompleteWills = async (list = false) => {
    const response = await axios.get(`/admin/incomplete-wills-count${list ? '?list=true' : ''}`);
    return response;
}