// HelpSection.js
import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { styled, useTheme } from '@mui/material/styles';
import AlertDialog from 'components/DesignSystem/AlertDialog';
import { ContainedButton2 } from 'components/DesignSystem/Button';
import Icon from 'components/DesignSystem/Icon';
import InputField from 'components/DesignSystem/InputField';
import useSwalAlert from 'components/DesignSystem/SwalAlert/SwalAlert';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RequestForCall } from 'services/SubscriptionServicesPage';
import FaqSection from './FaqSection';
import ResourcesSection from './ResourceSection';



const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
    border: 'none',
    // padding: '8px 50px',
    width: '100%',
    borderRadius: '0',
    '&:first-of-type': {
        borderRight: `2px solid ${theme.palette.secondary.light}`,
    },
    '&.Mui-selected': {
        backgroundColor: 'transparent',
        color: theme.palette.grey[600],
    },
    '&:not(.Mui-selected)': {
        color: theme.palette.grey[400],
    },
    textTransform: 'none',
}));

const HelpSection = ({ open, handleClose, defaultFaqType }) => {
    const theme = useTheme();
    const [helpType, setHelpType] = useState('faqs');
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();
    const [confirmSub, setConfirmSub] = useState({
        open: false,
        title: 'Request a Call',
        text: 'This service is only for General Help. Please press "Request a Call" button to register the call request. Our support team will get back to you within 48 hours. Please use the "Call with Legal Expert" listed in the Services Tab for Legal Help.',
        type: 'CALL',
    });
    const [loading, setLoading] = useState(false);

    const showSwalAlert = useSwalAlert();

    const handleCloseDialoug = () => {
        setConfirmSub(prevState => ({
            ...prevState,
            open: false,
        }));
    };

    const handleFaqTypeChange = () => {
        setSearchQuery('');
    };

    const handleChange = (event, newAlignment) => {
        setHelpType(newAlignment);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleClose1 = () => {
        setConfirmSub(prevState => ({
            ...prevState,
            open: false,

        }));
    };




    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: '15px',
                    backgroundColor: theme.palette.secondary.lighter,
                    position: 'absolute',
                    top: '9rem',
                    left: '36.563rem',
                    right: '5.188rem',
                    bottom: '3.5rem',
                    boxShadow: 'none',
                    margin: '0', // Ensure no default margins
                    border: `1px solid ${theme.palette.secondary.dark}`,
                }
            }}
            BackdropProps={{
                sx: {
                    backgroundColor: 'transparent' // Set the backdrop color to transparent
                }
            }}
            maxWidth={false} // Disable default maxWidth
        >
            <DialogTitle>
                <Stack mt={1.5} alignItems='flex-start'>
                    <Typography variant="title2" sx={{ color: theme.palette.grey[600] }}>
                        Help
                    </Typography>
                </Stack>
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.secondary.darker,
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Grid container spacing={2} alignItems="center">
                    {/* Toggle Button Group */}
                    <Grid item xs={12} sm={4}>
                        <ToggleButtonGroup
                            value={helpType}
                            exclusive
                            onChange={handleChange}
                            aria-label="Help Type"
                            sx={{ width: '100%' }} // Ensures the button group takes full width
                        >
                            <CustomToggleButton value="faqs">
                                <Typography variant='bodyText3'>FAQs</Typography>
                            </CustomToggleButton>
                            <CustomToggleButton value="resources">
                                <Typography variant='bodyText3'>Videos</Typography>
                            </CustomToggleButton>
                        </ToggleButtonGroup>
                        {/* Bottom Divider */}
                        <Divider sx={{ borderBottom: '2px solid', borderColor: theme => theme.palette.secondary.light }} />
                    </Grid>

                    {/* Search Bar */}
                    <Grid item xs={12} sm={8}>
                        <InputField
                            fullWidth
                            variant="outlined"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            sx={{
                                '& .MuiInputBase-root': {
                                    backgroundColor: 'white',
                                    height: '2.5rem',
                                    padding: '13.5px 16px',
                                },
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon name="gwd-icons-search.svg" height='23px' width='23px' />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
                {helpType === 'faqs' && (
                    <FaqSection onFaqTypeChange={handleFaqTypeChange} searchQuery={searchQuery} defaultFaqType={defaultFaqType} />
                )}
                {helpType === 'resources' && (
                    <ResourcesSection searchQuery={searchQuery} />
                )}
                <Box mt={2} sx={{
                    width: '100%',
                    padding: '16px',
                    mt: '181px',
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #78BEC4',
                    borderRadius: '12px',
                    display: 'flex', justifyContent: 'space-between',
                    alignItems: 'center',
                    // position: 'sticky',
                    // bottom: 0
                }}>
                    <Stack>
                        <Typography variant="bodyText4" color='#1E1C1B'>Would you like to talk to a legal expert?</Typography>
                        <Typography variant="suggestiveText" color='#707070'>Schedule a 30-minute call.</Typography>
                    </Stack>
                    <ContainedButton2
                        variant="contained"
                        sx={{ width: '175px' }}
                        // onClick={() => setConfirmSub(prevState => ({ ...prevState, open: true }))} // Open AlertDialog
                        onClick={() => navigate('/Services')}
                    >
                        Book a Call
                    </ContainedButton2>
                </Box>

                {/* AlertDialog for confirming the call request */}
                <AlertDialog
                    open={confirmSub.open}
                    onClose={handleCloseDialoug}
                    modelTitle={confirmSub.title}
                    primaryButtonLabel="Cancel"
                    secondaryButtonLabel="Request a Call"
                    onPrimaryButtonClick={handleCloseDialoug}
                    onSecondaryButtonClick={async () => {
                        if (loading) return;
                        setLoading(true);
                        try {
                            await RequestForCall(confirmSub.type);
                            showSwalAlert({
                                title: `Call request submitted`,
                                icon: 'success',
                                timer: 15000,
                            });

                            setTimeout(() => {
                                handleClose1();
                            }, 15000);

                        } catch (error) {
                            showSwalAlert({
                                title: `Failed to request a call`,
                                icon: 'error',
                                timer: 15000,
                            });

                            setTimeout(() => {
                                handleClose();
                            }, 15000);
                        } finally {
                            setLoading(false);

                        }
                    }}
                    onSecondaryButtonDisabled={loading}
                >
                    <Box sx={{ bgcolor: 'white' }}>
                        <Typography variant="bodyText4" color="textSecondary" component="p" sx={{ mb: 2, textAlign: 'justify' }}>
                            <div dangerouslySetInnerHTML={{ __html: confirmSub.text }} />
                        </Typography>
                    </Box>
                </AlertDialog>
            </DialogContent>
        </Dialog>
    );
};

export default HelpSection;
