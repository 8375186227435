import { Box, FormGroup, Grid, Link, Stack, styled, Switch, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { ContextProvider } from 'App';
import axios from 'axios';
import AlertDialog from 'components/DesignSystem/AlertDialog';
import { ContainedButton1, TextedButton1 } from 'components/DesignSystem/Button';
import { useContext, useEffect, useState } from 'react';
import useSwalAlert from 'components/DesignSystem/SwalAlert/SwalAlert';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}



function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}


const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#00838F',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

const GDPR = () => {
    const theme = useTheme();
    const { user, fetchUser } = useContext(ContextProvider);
    const [openChooseCookies, setOpenChooseCookies] = useState(false);
    const [value, setValue] = useState(0);
    const [analyticsCookie, setAnalyticsCookie] = useState(false);
    const [targetingCookie, setTargetingCookie] = useState(false);
    const showSwalAlert = useSwalAlert();

    useEffect(() => {
        fetchUser();
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const acceptCookies = (acceptAll) => {
        axios.post(`/testator/consent`, { analyticsCookieAccepted: acceptAll ? true : analyticsCookie, targetingCookieAccepted: acceptAll ? true : targetingCookie }).then(async (response) => {
            setOpenChooseCookies(false);
            await fetchUser();
            showSwalAlert({
                icon: "success",
                title: "Cookies accepted successfully",
                timer: 3000
            });
        }).catch(err => {
            setOpenChooseCookies(false);
            showSwalAlert({
                icon: "error",
                title: "Failed to accept cookies",
                timer: 3000
            });
        });
    };
    return (
        <Grid
            container
            sx={{
                mt: 5,
                position: 'fixed',
                bottom: 0,
                width: '100%',
                padding: 1,
                backgroundColor: '#ccc',
                zIndex: 9999,
            }}
        >
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Typography variant="bodyText4">
                    {`This website uses cookies, details of which are set out in our`}
                    <Link href="https://getwilldone.com/privacy-policy" target="_blank" variant="bodyText4">
                        {' privacy policy'}
                    </Link>
                    . {`By continuing to access this website you agree to the terms of our privacy policy.`}
                    <TextedButton1 onClick={() => setOpenChooseCookies(true)} sx={{
                        padding: '8px',
                        marginLeft: 1,
                    }}>
                        <Typography variant="bodyText4">Choose Cookies
                        </Typography>
                    </TextedButton1>
                    <ContainedButton1
                        sx={{
                            padding: '8px',
                            marginLeft: 1,
                        }}
                        onClick={() => {
                            acceptCookies(true);
                        }}
                    >
                        <Typography variant="bodyText4"> Accept Cookies
                        </Typography>
                    </ContainedButton1>
                </Typography>
            </Grid>

            <AlertDialog
                open={openChooseCookies}
                onClose={() => {
                    setOpenChooseCookies(false);
                }}
                modelTitle={`Choose Cookies`}
                dialogWidth='80%'
            >
                <Box
                    sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '40vh' }}
                >
                    <Tabs
                        orientation="vertical"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        sx={{ borderRight: 1, borderColor: 'divider', minWidth: '245px' }}
                    >
                        <Tab label="Strictly Essential" {...a11yProps(0)} sx={{ fontFamily: "DM Sans, sans-serif", textTransform: 'none' }} />
                        <Tab label="Analytics" {...a11yProps(1)} sx={{ fontFamily: "DM Sans, sans-serif", textTransform: 'none' }} />
                        <Tab label="Targeting" {...a11yProps(2)} sx={{ fontFamily: "DM Sans, sans-serif", textTransform: 'none' }} />

                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <Stack alignItems="flex-start" spacing={1}>
                            <Typography variant="heading1">
                                Strictly Essential
                            </Typography>
                            <Typography variant="bodyText4" sx={{ textAlign: 'left' }}>
                                These cookies are necessary for the website to function and cannot be switched off. These cookies also are necessary for a functionality explicitly requested by you.
                            </Typography>
                            <FormGroup>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    {/* <Typography variant="bodyText4">Disable</Typography> */}
                                    <AntSwitch disabled defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                                    <Typography variant="bodyText4">Enabled</Typography>
                                </Stack>
                            </FormGroup>
                        </Stack>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Stack alignItems="flex-start" spacing={1}>
                            <Typography variant="heading1">
                                Analytics
                            </Typography>
                            <Typography variant="bodyText4" sx={{ textAlign: 'left' }}>
                                We use Analytical performance cookies, these cookies help us measure the performance of our website and features. They also help us improve our offerings and better serve our customers.
                            </Typography>
                            <FormGroup>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <AntSwitch checked={analyticsCookie} onChange={(event) => { console.log('targeting', event.target.checked); setAnalyticsCookie(event.target.checked) }} defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                                    <Typography variant="smallText">{analyticsCookie ? 'Enabled' : 'Disabled'}</Typography>
                                </Stack>
                            </FormGroup>
                        </Stack>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Stack alignItems="flex-start" spacing={1}>
                            <Typography variant="heading1">
                                Targeting
                            </Typography>
                            <Typography variant="bodyText4" sx={{ textAlign: 'left' }}>
                                These cookies help us market to you on different platforms using our advertising partners.
                            </Typography>
                            <FormGroup>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <AntSwitch checked={targetingCookie} onChange={(event) => { console.log('targeting', event.target.checked); setTargetingCookie(event.target.checked) }} defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                                    <Typography variant="smallText">{targetingCookie ? 'Enabled' : 'Disabled'}</Typography>
                                </Stack>
                            </FormGroup>
                        </Stack>
                    </TabPanel>

                </Box>
                <Stack sx={{ bgcolor: "#ccc", p: 1, borderRadius: '10px' }} direction={"row"} justifyContent={"space-between"}>
                    <TextedButton1 onClick={() => {
                        setAnalyticsCookie(true);
                        setTargetingCookie(true);
                    }} sx={{
                        padding: '8px',
                        marginLeft: 1,
                    }}>
                        <Typography variant="bodyText4">Enable All
                        </Typography>
                    </TextedButton1>
                    <ContainedButton1
                        sx={{
                            padding: '8px',
                            marginLeft: 1,
                        }}
                        onClick={() => {
                            console.log('acknowledge');
                            acceptCookies();
                        }}
                    >
                        <Typography variant="bodyText4"> Save Changes
                        </Typography>
                    </ContainedButton1>
                </Stack>
            </AlertDialog>

        </Grid >
    );
};

export default GDPR;
