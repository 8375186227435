import {
  Box,
  Chip,
  Table,
  TableBody,
  TableContainer,
  Typography
} from '@mui/material';
import axios from 'axios';
import { Dropdown } from 'components/DesignSystem/DropDown';
import { CustomTableCell, CustomTablePagination, CustomTableRow } from 'components/DesignSystem/Table/CustomTable';
import { filter } from 'lodash';
import Scrollbar from 'oldLayouts/components/scrollbar';
import { UserListHead, UserListToolbar } from 'oldLayouts/components/user';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import PageContainer from 'views/dashboard/PageContainer';

function descendingComparator(a, b, orderBy) {
  if (orderBy === 'service') {
    // Sort by the length of the service array
    const aLength = a[orderBy] ? a[orderBy].length : 0;
    const bLength = b[orderBy] ? b[orderBy].length : 0;
    return bLength - aLength;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const TABLE_HEAD = [
  {
    id: 'email',
    numeric: true,
    label: 'Email',
    alignRight: false,
  },
  {
    id: 'name',
    disablePadding: true,
    label: 'Client Name',
    alignRight: false,
  },
  {
    id: 'phone',
    numeric: true,
    label: 'Phone',
    alignRight: false,
  },
  {
    id: 'pan',
    label: 'PAN',
    alignRight: false,
  },
  {
    id: 'role',
    label: 'Role',
    alignRight: false,
  },
  {
    id: 'status',
    label: 'Status',
    alignRight: false,
  },
  {
    id: 'service',
    label: 'Service',
    alignCenter: true,
  },
];

const servicePurchaseOption = [
  {
    label: 'All',
    value: 'all'
  },
  {
    label: 'Paid',
    value: 'paid'
  },
  {
    label: 'Unpaid',
    value: 'unpaid'
  },
]

const roleSelectOption = [
  {
    label: 'All',
    value: 'all'
  },
  {
    label: 'Testator',
    value: 'testator'
  },
  {
    label: 'Partner',
    value: 'partner'
  },
]

const ConversionRatioPage = () => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('email');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [conversionPageData, setConversionRatioData] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [filterValue, setFilterValue] = useState('all');
  const [filterRole, setFilterRole] = useState('all');
  const navigate = useNavigate();

  const fetchClientsData = async () => {
    try {
      const response = await axios.get(`/admin/signup-vs-paid-ratio?list=${true}`);
      const { conversionRatioData } = response.data;
      const updatedData =
        conversionRatioData &&
        conversionRatioData?.map((client) => {
          if (client?.subscriptions?.length > 0) {
            return {
              ...client,
              status: 'Service purchased',
              service: client?.subscriptions?.map((service, index) => (
                <Box key={index} sx={{ display: 'flex', flexDirection: 'column', gap: '4px', margin: '4px 0' }}>
                  <Chip size="small" key={service} label={service} variant="outlined" />
                </Box>
              )),
            };
          }
          return {
            ...client,
            status: 'Service not purchased',
            service: 'N/A',
          };
        });
      setConversionRatioData(updatedData || []);
    } catch (error) {
      console.error('Error fetching conversion ratio data:', error);
    }
  };

  useEffect(() => {
    fetchClientsData();
  }, []);

  const filteredData = useMemo(() => {
    return conversionPageData.filter((client) => {
      const matchesServiceFilter =
        filterValue === 'all' ||
        (filterValue === 'paid' && client.subscriptions.length > 0) ||
        (filterValue === 'unpaid' && client.subscriptions.length === 0);

      const matchesRoleFilter =
        filterRole === 'all' ||
        (filterRole === 'testator' && client.role === 'testator') ||
        (filterRole === 'partner' && client.role === 'partner');

      return matchesServiceFilter && matchesRoleFilter;
    });
  }, [filterValue, filterRole, conversionPageData]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - conversionPageData.length) : 0;

  const visibleRows = useMemo(
    () =>
      stableSort(filteredData || [], getComparator(order, orderBy), filterName).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, conversionPageData, filterName, filterValue, filterRole]
  );

  const handleBackClick = () => {
    navigate('/admin/analyticaldashboard');
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleFilterChange = (event) => {
    setFilterValue(event.target.value);
  };

  const handleRoleFilterChange = (event) => {
    setFilterRole(event.target.value);
  };

  return (
    <PageContainer showBackButton showHelpIcon={false}>
      <Helmet>
        <title> Signup Users VS Paid Users | Get Will Done </title>
      </Helmet>
      <Box>
        <Typography variant="title1">Sign-up Users VS Paid Users</Typography>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '24px 0 12px 0' }}>
        <Box>
          <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} />
        </Box>
        <Box sx={{ display: 'flex', gap: '16px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px' }}>
            <Box>
              <Typography variant='bodyText4'>Service Purchase Status</Typography>
            </Box>
            <Dropdown value={filterValue} handleChange={handleFilterChange} placeholder="Service Purchase Status" options={servicePurchaseOption} dropdownWidth='150px' />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px' }}>
            <Typography variant='bodyText4'>Role</Typography>
            <Dropdown value={filterRole} handleChange={handleRoleFilterChange} placeholder="Role" options={roleSelectOption} dropdownWidth='150px' />
          </Box>
        </Box>
      </Box>
      <Box>
        <Scrollbar>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <UserListHead order={order} orderBy={orderBy} headLabel={TABLE_HEAD} onRequestSort={handleRequestSort} />
              <TableBody>
                {visibleRows.map((row, index) => (
                  <CustomTableRow hover tabIndex={-1} key={row.id}>
                    <CustomTableCell>
                      {row.email}
                    </CustomTableCell>
                    <CustomTableCell>{row.name}</CustomTableCell>
                    <CustomTableCell>{row.phone}</CustomTableCell>
                    <CustomTableCell>{row.pan}</CustomTableCell>
                    <CustomTableCell>{row.role}</CustomTableCell>
                    <CustomTableCell>{row.status}</CustomTableCell>
                    <CustomTableCell align="center">{row.service}</CustomTableCell>
                  </CustomTableRow>
                ))}
                {emptyRows > 0 && (
                  <CustomTableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <CustomTableCell colSpan={6} />
                  </CustomTableRow>
                )}
              </TableBody>
              {conversionPageData.length === 0 && (
                <TableBody>
                  <CustomTableRow>
                    <CustomTableCell align="center" colSpan={7} sx={{ py: 3 }}>
                      No Data Found
                    </CustomTableCell>
                  </CustomTableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
        <CustomTablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </PageContainer>
  );
};

export default ConversionRatioPage;

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        JSON.stringify(_user ?? {})
          .toLowerCase()
          .indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}
