import axios from "axios";

export const updateProfileApi = async (ProfileData) => {
    const response = axios.patch(`/testator`, ProfileData)
    return response;
};

// Api For fetch Address from zipCode
export const fetchDataFromZip = async ({ zip, countryCode }) => {
    return axios.get(`/testator/${countryCode}/${zip}`, {
        timeout: 8000
    }).then(response => {
        return response.data;
    }).catch((err) => {
        console.error(err);
    });
};

export const uploadWillApi = async (WillData) => {
    const response = await axios.post('/testator/will', WillData);
    return response;
}

export const deleteAccountApi = async () => {
    const response = await axios.delete(`/testator/account`);
    return response;
}

export const logoutUserApi = async () => {
    const response = await axios.post('/auth/logout');
    return response;
}
export const updateProfileSaved = async () => {
    const response = axios.patch(`/testator/profile-saved`)
    return response;
};
