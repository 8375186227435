import { Box, Typography } from "@mui/material";
import { ContextProvider } from "App";
import { TextedButton1 } from "components/DesignSystem/Button";
import Icon from "components/DesignSystem/Icon";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

const ExitDemoButton = ({ sx = {} }) => {
    const navigate = useNavigate();
    const { user } = useContext(ContextProvider);
    const isDemoActive = sessionStorage.getItem('isDemoActive') === 'true';

    const handleExitDemo = () => {
        sessionStorage.removeItem('isDemoActive');
        navigate('/partnerLandingPage');
    };

    if (user.role !== 'partner' || !isDemoActive) {
        return null;
    }

    return (
        <Box
            sx={{
                ...sx, // Spread the passed position prop
            }}
        >
            <TextedButton1
                sx={{ display: 'flex', gap: '8px', padding: '10px 16px' }}
                onClick={handleExitDemo}>
                <Icon name="exit-icon.png" height='16px' width='15px' />
                <Typography variant="bodyText3">Exit Demo</Typography>
            </TextedButton1>
        </Box>
    );
};

export default ExitDemoButton;
