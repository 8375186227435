import { Skeleton } from '@mui/material';
import DashboardCard from 'components/commonComponents/cards/DashboardCard';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchTotalCommissionPaid } from 'services/adminService';
import { fCurrencyRupee } from 'utils/formatNumber';

const TotalCommissionPaidCard = () => {
  const [commissionPaidData, setCommissionPaidData] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCommissionPaidData = async () => {
      try {
        const response = await fetchTotalCommissionPaid();
        const { totalCommission } = response.data;
        setCommissionPaidData({ totalCommission });
      } catch (error) {
        console.error('Error fetching total commission paid data:', error);
      } finally {
        setLoading(false); // Set loading to false after API call
      }
    };

    fetchCommissionPaidData();
  }, []);

  const handleClick = () => {
    navigate('/admin/commissionReports', { state: { fromAdminDashboard: true } });
  };

  return (
    <>
      {
        loading ? (
          <Skeleton variant="rectangular" height={135} sx={{ borderRadius: '1.25rem' }} />
        ) : (
          <DashboardCard
            title="Total Commission Paid"
            subtitle={fCurrencyRupee(commissionPaidData.totalCommission || 0)}
            handleClick={handleClick}
            isClickable
          />
        )
      }
    </>
  );
};

export default TotalCommissionPaidCard;
