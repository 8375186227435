import { Skeleton } from '@mui/material';
import DashboardCard from 'components/commonComponents/cards/DashboardCard';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchTotalPartners } from 'services/adminService';


const TotalNumberOfPartnersCard = () => {
  const [partnersData, setPartnersData] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTotalNumberOfPartners = async () => {
      try {
        const response = await fetchTotalPartners();
        const { totalPartners } = response.data;
        setPartnersData({ totalPartners });
      } catch (error) {
        console.error('Error fetching number of partners data:', error);
      } finally {
        setLoading(false); // Set loading to false after API call
      }
    };

    fetchTotalNumberOfPartners();
  }, []);

  const handleClick = () => {
    navigate('/admin/partnerDetails', { state: { fromAdminDashboard: true } });
  };

  return (
    <>
      {
        loading ? (
          <Skeleton variant="rectangular" height={135} sx={{ borderRadius: '1.25rem' }} />
        ) : (
          <DashboardCard
            title="Total No of Advisors/Partners"
            subtitle={(partnersData.totalPartners ?? '--') || 0}
            handleClick={handleClick}
            isClickable
          />
        )
      }
    </>
  );
};

export default TotalNumberOfPartnersCard;
