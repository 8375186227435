import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import { ContextProvider } from 'App';
import DateInput from 'components/DateInput';
import { Dropdown } from 'components/DesignSystem/DropDown';
import Icon from 'components/DesignSystem/Icon';
import InputField from 'components/DesignSystem/InputField';
import useSwalAlert from 'components/DesignSystem/SwalAlert/SwalAlert';
import PhoneField from 'components/PhoneField';
import { useFormik } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import { updateProfileApi } from 'services/profileService';
import formValidation from 'utils/helper/formValidations';
import * as Yup from 'yup';
import { ContainedButton1, TextedButton1 } from '../../../components/DesignSystem/Button';

const PersonalDetails = () => {
    const theme = useTheme();
    const [userProfile, setUserProfile] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [initialProfile, setInitialProfile] = useState(null); // Store the initial values

    const { user, fetchUser } = useContext(ContextProvider);
    const showSwalAlert = useSwalAlert();

    const handleOnEditClick = () => {
        setIsEdit((edit) => !edit);
    };

    const initialValues = {
        first_name: '',
        middle_name: '',
        last_name: '',
        dob: '',
        marital_status: '',
        phone: '',
        pan: '',
    };

    const validationSchema = Yup.object().shape({
        first_name: formValidation.firstNameValidation,
        middle_name: formValidation.middleNameValidation,
        last_name: formValidation.lastNameValidation,
        dob: formValidation.testatorDob,
        marital_status: Yup.string().required('required field'),
        phone: formValidation.phoneValidation,
        pan: formValidation.panValidation,
    });

    const getUserData = async () => {
        try {
            const { data } = await fetchUser();
            const profile = data.user?.profile;
            setUserProfile(profile);
            setInitialProfile(profile); // Store the initial values for later reset
            formik.setValues({
                first_name: profile.first_name || '',
                middle_name: profile.middle_name || '',
                last_name: profile.last_name || '',
                dob: profile.dob || '',
                marital_status: profile.marital_status || '',
                phone: profile.phone || '',
                pan: profile.pan || '',
            });
        } catch (error) {
            console.error('Error fetching user:', error);
        }
    };

    useEffect(() => {
        getUserData();
    }, []);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            console.log('in formik handle subs');

            const profileData = { ...userProfile, ...values };
            try {
                // Call the API to update the profile
                await updateProfileApi({ profile: profileData });
                setIsEdit(false);
                // Show SweetAlert2 notification and wait for it to close
                await getUserData();
                await showSwalAlert({
                    title: 'Personal Details Saved Successfully',
                    icon: 'success',
                    timer: 1500,
                });
            } catch (error) {
                // Show SweetAlert2 notification and wait for it to close
                showSwalAlert({
                    title: 'Failed to save Personal Details',
                    icon: 'error',
                    timer: 1500,
                });
                console.error('Error updating profile:', error);
            }
        },
    });

    const handleCancel = () => {
        if (initialProfile) {
            formik.setValues({
                first_name: initialProfile.first_name || '',
                middle_name: initialProfile.middle_name || '',
                last_name: initialProfile.last_name || '',
                dob: initialProfile.dob || '',
                marital_status: initialProfile.marital_status || '',
                phone: initialProfile.phone || '',
                pan: initialProfile.pan || '',
            });
        }
        setIsEdit(false);
    };

    const menuItems = [
        {
            value: 'single',
            label: 'Single',
        },
        {
            value: 'married',
            label: 'Married',
        },
        {
            value: 'divorced',
            label: 'Divorced',
        },
        {
            value: 'widowed',
            label: 'Widowed',
        },
    ];
    console.log('inormik.errors', formik.errors);
    const handleSave = () => {
        console.log('save called');

        formik.handleSubmit();
    };

    return (
        <>
            <Box
                sx={{
                    p: 2, // Padding inside the Box
                    backgroundColor: '#F5F5F5',
                    borderRadius: '15px',
                    marginTop: '96px',
                    marginRight: '94px',
                }}
            >
                <Box display="flex" alignItems="center" justifyContent="space-between" mt="8px">
                    <Typography variant="heading2" color={theme.palette.secondary.main} gutterBottom>
                        Personal Details
                    </Typography>

                    {!user?.Wills?.find(will => !will.uploaded && !will.deletedAt) && (
                        <Button
                            variant="outlined"
                            endIcon={<Icon name="gwd-icons-edit.svg" />}
                            onClick={handleOnEditClick}
                            sx={{
                                borderRadius: '15px',
                                padding: '3px 15px 5px 15px',
                                border: '0.7px solid #78BEC4',
                                backgroundColor: '#FFFFFF',
                                boxShadow: '6px 6px 8px -8px #00838F4D',
                                color: '#000000',
                                textTransform: 'none',
                                '&:hover': {
                                    borderColor: '#78BEC4',
                                },
                            }}
                        >
                            <Typography variant="suggestiveText" sx={{ color: '#00838F', pt: '4px' }}>
                                Edit
                            </Typography>
                        </Button>
                    )}
                </Box>
                <form>
                    <Grid item width='95%' mt="38px">
                        <Grid container spacing={2} md={12}>
                            <Grid item xs={12} sm={4} md={4}>
                                <InputField
                                    fullWidth
                                    name="first_name"
                                    placeholder="First Name*"
                                    value={formik.values.first_name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                                    helperText={formik.touched.first_name && formik.errors.first_name}
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            backgroundColor: (theme) => theme.palette.common.white, // Override the background color for this instance
                                            height: '2.5rem',
                                        },
                                        '& input:-webkit-autofill': {
                                            WebkitBoxShadow: '0 0 0 100px transparent inset', // Override autofill background color
                                            WebkitTextFillColor: 'black', // Change text color to match input text color
                                            transition: 'background-color 5000s ease-in-out 0s', // Prevent autofill color flash
                                        },
                                    }}
                                    disabled={!isEdit}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <InputField
                                    fullWidth
                                    name="middle_name"
                                    placeholder="Middle Name"
                                    variant="outlined"
                                    value={formik.values.middle_name}
                                    onChange={formik.handleChange}
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            backgroundColor: (theme) => theme.palette.common.white, // Override the background color for this instance
                                            height: '2.5rem',
                                        },
                                        '& input:-webkit-autofill': {
                                            WebkitBoxShadow: '0 0 0 100px transparent inset', // Override autofill background color
                                            WebkitTextFillColor: 'black', // Change text color to match input text color
                                            transition: 'background-color 5000s ease-in-out 0s', // Prevent autofill color flash
                                        },
                                    }}
                                    disabled={!isEdit}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <InputField
                                    fullWidth
                                    name="last_name"
                                    placeholder="Last Name*"
                                    variant="outlined"
                                    value={formik.values.last_name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                                    helperText={formik.touched.last_name && formik.errors.last_name}
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            backgroundColor: (theme) => theme.palette.common.white, // Override the background color for this instance
                                            height: '2.5rem',
                                        },
                                        '& input:-webkit-autofill': {
                                            WebkitBoxShadow: '0 0 0 100px transparent inset', // Override autofill background color
                                            WebkitTextFillColor: 'black', // Change text color to match input text color
                                            transition: 'background-color 5000s ease-in-out 0s', // Prevent autofill color flash
                                        },
                                    }}
                                    disabled={!isEdit}
                                />
                            </Grid>

                            <Grid item xs={12} md={7} sm={12}>
                                <DateInput
                                    name="dob"
                                    value={formik.values.dob}
                                    label="Date of Birth"
                                    onChange={(date) => formik.setFieldValue('dob', date)}
                                    onBlur={() => formik.setFieldTouched('dob', true)}
                                    error={formik.touched.dob && Boolean(formik.errors.dob)}
                                    helperText={formik.touched.dob && formik.errors.dob}
                                    help
                                    sx={{
                                        backgroundColor: (theme) => theme.palette.common.white,
                                        height: '2.5rem',
                                    }}
                                    disabled={!isEdit}
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <Grid container md={12} lg={12} spacing={1}>
                                    <Grid item xs={12} md={7}>
                                        <Dropdown
                                            name="marital_status"
                                            value={formik.values.marital_status}
                                            options={menuItems}
                                            placeholder="Marital Status*"
                                            handleChange={formik.handleChange}
                                            dropdownColor={theme.palette.common.white}
                                            dropdownHeight="40px"
                                            disabled={!isEdit}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item md={7}>
                                <PhoneField
                                    name="phone"
                                    value={formik.values.phone}
                                    handleChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    touched={formik.touched.phone}
                                    error={formik.touched.phone && formik.errors.phone}
                                    helperText={formik.touched.phone && formik.errors.phone}
                                    placeholder="Mobile Number*"
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            backgroundColor: (theme) => `${theme.palette.common.white} !important`, // Override the background color for this instance
                                            height: '2.5rem',
                                        },
                                        '& input:-webkit-autofill': {
                                            WebkitBoxShadow: '0 0 0 100px transparent inset', // Override autofill background color
                                            WebkitTextFillColor: 'black', // Change text color to match input text color
                                            transition: 'background-color 5000s ease-in-out 0s', // Prevent autofill color flash
                                        },
                                    }}
                                    disabled={!isEdit}
                                />
                            </Grid>

                            <Grid item md={5}>
                                <InputField
                                    fullWidth
                                    name="pan"
                                    placeholder="PAN*"
                                    variant="outlined"
                                    value={formik.values.pan}
                                    // onChange={formik.handleChange}
                                    onChange={(e) => {
                                        // Convert PAN value to uppercase
                                        formik.setFieldValue('pan', e.target.value.toUpperCase());
                                    }}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.pan && Boolean(formik.errors.pan)}
                                    helperText={formik.touched.pan && formik.errors.pan}
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            backgroundColor: (theme) => theme.palette.common.white, // Override the background color for this instance
                                            height: '2.5rem',
                                        },
                                        '& input:-webkit-autofill': {
                                            WebkitBoxShadow: '0 0 0 100px transparent inset', // Override autofill background color
                                            WebkitTextFillColor: 'black', // Change text color to match input text color
                                            transition: 'background-color 5000s ease-in-out 0s', // Prevent autofill color flash
                                        },
                                    }}
                                    disabled={!isEdit}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '10px',
                    mt: '16px',
                    mr: '94px',
                }}
            >
                <TextedButton1 sx={{ height: '40px', width: '109px' }} onClick={handleCancel} disabled={!isEdit}>
                    <Typography variant="bodyText3">Cancel</Typography>
                </TextedButton1>

                <ContainedButton1 onClick={handleSave} sx={{ height: '40px', width: '109px' }} disabled={!isEdit}>
                    <Typography variant="bodyText3">Save</Typography>
                </ContainedButton1>
            </Box>
        </>
    );
};

export default PersonalDetails;
