import { Stack, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from "react-router-dom";
import AlertDialog from "./DesignSystem/AlertDialog";
import { ContainedButton1 } from "./DesignSystem/Button";
import useSwalAlert from "./DesignSystem/SwalAlert";

const timeout = 900_000
const promptBeforeIdle = 10_000

const IdleTimeout = () => {

    const [remaining, setRemaining] = useState(timeout);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const showSwalAlert = useSwalAlert();

    const onIdle = async () => {
        axios.post('/auth/logout').then(() => {
            showSwalAlert({
                icon: "warning",
                title: "You have been logged out due to inactivity! Please login again to use the application.",
                timer: 3000
            });
            // Clear session storage
            sessionStorage.clear(); // This clears all items, which may be what you want
        })
            .finally(() => navigate('/login', { replace: true }));
        setOpen(false)
    }

    const onActive = () => {
        setOpen(false)
    }

    const onPrompt = () => {
        setOpen(true)
    }

    const { getRemainingTime, activate } = useIdleTimer({
        onIdle,
        onActive,
        onPrompt,
        timeout,
        promptBeforeIdle,
        throttle: 500
    })

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000))
        }, 500)

        return () => {
            clearInterval(interval)
        }
    })

    const handleStillHere = () => {
        activate()
    }

    return (
        <>
            <AlertDialog
                open={open}
                onClose={() => {
                    handleStillHere();
                }}
                title={`You will be logged out in ${remaining} seconds.`}
            >
                <Stack sx={{ m: -1, mb: 0, p: 1 }} direction={"row"} justifyContent={"space-between"}>
                    <Typography variant="bodyText3" gutterBottom>
                        &nbsp;
                    </Typography>
                    <ContainedButton1 style={{ marginTop: "16px" }} sx={{ padding: '8px 28px' }} onClick={() => {
                        handleStillHere();
                    }}>
                        <Typography variant="bodyText3" gutterBottom>
                            I am still here
                        </Typography>
                    </ContainedButton1>
                </Stack>
            </AlertDialog>
        </>
    )
}
export default IdleTimeout;