import React, { useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Divider,
  useTheme,
} from '@mui/material';
import SortRoundedIcon from '@mui/icons-material/SortRounded';
import { noop } from 'lodash';

const FilterComponent = ({
  title = 'All',
  categoryHeadings = [],
  onFilterChange = noop,
  typographyProps = {},
  containerSx = {},
  hideFilter = false,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const open = Boolean(anchorEl);
  const isDisabled = categoryHeadings.length === 0; // Check if categories are available

  const handleClick = (event) => {
    if (isDisabled) return; // Do nothing if disabled
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCategoryChange = (event, category) => {
    const newSelectedCategories = event.target.checked
      ? [...selectedCategories, category]
      : selectedCategories.filter((c) => c !== category);

    setSelectedCategories(newSelectedCategories);
    onFilterChange(newSelectedCategories);
  };

  const handleViewAllClick = () => {
    setSelectedCategories([]);
    onFilterChange([]);
    handleClose(); // Close the menu
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        ...containerSx,
      }}
    >
      <Typography sx={{ flexShrink: 0, textAlign: 'left' }} variant="heading2" {...typographyProps}>
        {title}
      </Typography>

      {/* Underscore line */}
      <Box
        sx={{
          mt: 2,
          flexGrow: 1,
          borderBottom: `0.5px solid ${theme.palette.grey[100]}`,
          mx: 2, // Space between the line and the text boxes
        }}
      />

      {/* "View All" button with grey background */}
      {!hideFilter && (
        <Box
          sx={{
            bgcolor: theme.palette.grey[200],
            display: 'flex',
            alignItems: 'center',
            width: '106px',
            height: '26px',
            borderRadius: '4px',
            flexShrink: 0,
          }}
        >
          {/* More options (three lines icon) */}
          <IconButton
            onClick={handleClick}
            sx={{ color: theme.palette.primary.main, cursor: isDisabled ? 'not-allowed' : 'pointer' }}
            disabled={isDisabled} // Disable button if no categories
          >
            <SortRoundedIcon />
          </IconButton>
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            {categoryHeadings.map((category, index) => (
              <Box key={category.value}>
                <MenuItem sx={{ padding: '4px 8px' }}>
                  <FormControlLabel
                    sx={{ margin: 0, padding: 0 }}
                    control={
                      <Checkbox
                        checked={selectedCategories.includes(category.value)}
                        onChange={(event) => handleCategoryChange(event, category.value)}
                        sx={{
                          height: '19px',
                          width: '19px',
                          marginRight: '4px',
                          color: 'secondary.main', // Default color
                          '&.Mui-checked': {
                            color: 'secondary.main', // Color when checked
                          },
                        }}
                      />
                    }
                    label={<Typography variant="smallText">{category.heading}</Typography>}
                  />
                </MenuItem>
                {index < categoryHeadings.length - 1 && <Divider sx={{ borderStyle: 'dashed' }} />}
              </Box>
            ))}
          </Menu>
          <Typography
            sx={{ flexShrink: 0, textAlign: 'left', cursor: 'pointer' }}
            variant="smallText"
            ml="auto"
            mr={1}
            onClick={handleViewAllClick}
          >
            View All
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default FilterComponent;
