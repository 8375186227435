import { Box, Grid, IconButton, Typography } from '@mui/material';
import { ContextProvider } from 'App';
import { ReactComponent as HelpIcon } from 'assets/help-button.svg';
import ActionButtons from 'components/actionsButtons';
import Lottie from 'components/DesignSystem/Lottie';
import HelpSection from 'components/featureComponent/helpResourceSection/HelpResourceSection';
import { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { updateProfileSaved } from 'services/profileService';
import Logo from '../../components/logo';


const ProfileCompletionPage = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const { user, fetchUser } = useContext(ContextProvider);

    const handleClose = () => setOpen(false);

    const handleUpdateProfile = async () => {
        try {
            const response = await updateProfileSaved();
            navigate('/myPeople');
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    };

    return (
        <>
            <Helmet>
                <title> Profile Complete | Get Will Done </title>
            </Helmet>
            <Box sx={{ position: 'absolute', top: "2.8rem", left: "2rem", width: '16rem', height: '8rem' }}>
                <Logo />
            </Box>
            <Grid
                container
                sx={{
                    height: '100%',
                    margin: 'auto',
                    padding: 10,
                    paddingTop: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                {/* Image */}
                <Box
                    sx={{
                        width: '6.977rem', height: '6.976rem', mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'center',
                    }}
                >
                    <Lottie name={'Loader'} size={111} />
                </Box>
                <Typography
                    variant="title2"
                    gutterBottom
                    align="center" // Center align text
                >
                    You are all set, {user.profile?.first_name}.
                </Typography>
                <Typography
                    variant="bodyText3"
                    gutterBottom
                    align="center" // Center align text
                    mt={2.5}
                >
                    Now that you have finished making your profile, you can go ahead and create your Will.
                </Typography>
                <Grid item>
                    <Box sx={{ marginTop: '5rem', cursor: 'pointer' }} onClick={handleUpdateProfile}
                    >
                        <Lottie name='GwdButton' />
                    </Box>
                </Grid>
            </Grid>
            <ActionButtons />
            <IconButton
                sx={{
                    position: 'fixed',
                    bottom: '3.125rem',
                    right: '3.125rem',
                    bgcolor: '#FFF3E0',
                    color: '#FF8C42',
                    '&:hover': {
                        bgcolor: '#FFE0B2',
                    },
                }}
                onClick={() => setOpen(true)}
            >
                <HelpIcon />
            </IconButton>
            <HelpSection
                open={open}
                handleClose={handleClose}
            />
        </>
    );
};

export default ProfileCompletionPage;
