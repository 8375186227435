import axios from "axios";

export const fetchFaqsApi = async () => {
    const response = axios.get("/dashboard/faqs");
    return response;
}

export const preChecksApi = async () => {
    const response = axios.get("/dashboard/preChecks");
    return response;
}