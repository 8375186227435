import axios from "axios";

export const fetchGenerics = async (type) => {
    const response = await axios.get(`/generics/${type}`);
    return response;
}

export const addGenerics = async (type, genericData) => {
    const response = await axios.post(`/generics/${type}`, genericData);
    return response;
}

export const updateGenerics = async (type, id, genericData) => {
    const response = await axios.post(`/generics/${type}/${id}`, genericData);
    return response;
}

export const deleteGenerics = async (type, id) => {
    const response = await axios.delete(`/generics/${type}/${id}`);
    return response;
}

export const fetchWitnessCount = async () => {
    const response = await axios.get('/generics/witness');
    return response.data?.generics.length ?? 0;
};

export const fetchExecutorCount = async () => {
    const response = await axios.get('/generics/executor');
    return response.data?.generics.length ?? 0;
};