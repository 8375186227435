import { Box, Typography, useTheme } from "@mui/material";
import AlertDialog from "components/DesignSystem/AlertDialog";
import CheckBox from "components/DesignSystem/CheckBox";
import useSwalAlert from "components/DesignSystem/SwalAlert/SwalAlert";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteAccountApi } from "services/profileService";

const DeleteAccountSection = () => {
    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState();
    const [checkboxes, setCheckboxes] = useState({
        check1: false,
        check2: false,
        check3: false,
    });

    const theme = useTheme();
    const showSwalAlert = useSwalAlert();
    const navigate = useNavigate();

    const handleDelete = () => {
        setIsDeleteAlertOpen(true);
    };

    const handleDeleteAlertClose = () => {
        setIsDeleteAlertOpen(false);
    };

    const deleteAccount = async () => {
        if (allChecked) {
            try {
                await deleteAccountApi();
                handleDeleteAlertClose();
                showSwalAlert({
                    position: "top-center",
                    icon: "success",
                    color: '#fff',
                    background: '#00838F',
                    toast: true,
                    title: "Account deleted successfully",
                    showConfirmButton: false,
                    timer: 3000
                });
                navigate('/login', { replace: true });
                setCheckboxes({
                    check1: false,
                    check2: false,
                    check3: false,
                })
            } catch (err) {
                showSwalAlert({
                    position: "top-center",
                    icon: "error",
                    color: '#fff',
                    background: '#00838F',
                    toast: true,
                    title: "Failed to delete account",
                    showConfirmButton: false,
                    timer: 3000
                });
            };
        }
    }

    const handleCheckboxChange = (event) => {
        setCheckboxes((prev) => ({
            ...prev,
            [event.target.name]: event.target.checked,
        }));
    };

    const allChecked = Object.values(checkboxes).every(Boolean);
    return (
        <>
            <Typography
                variant="suggestiveText"
                onClick={handleDelete}
                sx={{
                    color: theme.palette.grey[300],
                    textDecoration: 'none',
                    cursor: 'default', // Initially, no cursor change
                    '&:hover': {
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        color: theme.palette.grey[400],
                    },
                }}
            >
                Delete Account
            </Typography>
            <AlertDialog
                open={isDeleteAlertOpen}
                onClose={handleDeleteAlertClose}
                modelTitle="Confirm Delete Account"
                title="If you proceed"
                dialogWidth="650px"
                titleSx={{ textAlign: 'left' }}
                primaryButtonLabel="Cancel"
                onSecondaryButtonClick={deleteAccount}
                onPrimaryButtonClick={handleDeleteAlertClose}
                onSecondaryButtonDisabled={!allChecked}
            >
                <Box
                    color={theme.palette.grey[400]}
                    sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '16px' }}
                    textAlign="start"
                >
                    <Box sx={{ display: 'flex', gap: '8px', alignItems: 'flex-start' }}>
                        <CheckBox
                            sx={{ width: '42px', marginTop: '4px' }}
                            checked={checkboxes.check1}
                            onChange={handleCheckboxChange}
                            name="check1"
                        />
                        <Typography variant="bodyText4">
                            All your user data will be permanently deleted, including login credentials and any other personal
                            information associated with your account.
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', gap: '8px', alignItems: 'flex-start' }}>
                        <CheckBox
                            sx={{ width: '42px', marginTop: '4px' }}
                            checked={checkboxes.check2}
                            onChange={handleCheckboxChange}
                            name="check2"
                        />
                        <Typography variant="bodyText4">
                            All your subscriptions will be terminated. If you wish to use our service again in the future, you will
                            need to sign up and register as a new user
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', gap: '8px', alignItems: 'flex-start' }}>
                        <CheckBox
                            sx={{ width: '62px', marginTop: '4px' }}
                            checked={checkboxes.check3}
                            onChange={handleCheckboxChange}
                            name="check3"
                        />
                        <Typography variant="bodyText4">
                            If you have created any wills using our service, they will be deleted from our system as part of the
                            account deletion process. To ensure you have a copy for your records, we recommend saving a digital and
                            printed copy if you have not already done
                        </Typography>
                    </Box>
                </Box>
            </AlertDialog>
        </>
    )
};

export default DeleteAccountSection;