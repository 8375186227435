import { ReactComponent as MyPeopleIcon } from 'assets/panel-my-people.svg';

const reviewer = {
  id: 'reviewerDashboard',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/reviewer/reviewerDashboard',
      icon: MyPeopleIcon,
      breadcrumbs: false
    },
  ]
};

export default reviewer;
