import { Skeleton } from '@mui/material';
import DashboardCard from 'components/commonComponents/cards/DashboardCard';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchTotalGstPaid } from 'services/adminService';
import { fCurrencyRupee } from 'utils/formatNumber';

const TotalGstPaidCard = () => {
  const [totalGstPaidData, setTotalGstPaidData] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGstPaidData = async () => {
      try {
        const response = await fetchTotalGstPaid();
        const { totalGSTAmount } = response.data;
        setTotalGstPaidData({ totalGSTAmount });
      } catch (error) {
        console.error('Error fetching total gst paid data:', error);
      } finally {
        setLoading(false); // Set loading to false after API call
      }
    };

    fetchGstPaidData();
  }, []);

  const handleClick = () => {
    navigate('/admin/gstReports', { state: { fromAdminDashboard: true } });
  };

  return (
    <>
      {
        loading ? (
          <Skeleton variant="rectangular" height={135} sx={{ borderRadius: '1.25rem' }} />
        ) : (
          <DashboardCard
            title="Total GST Paid"
            subtitle={fCurrencyRupee(totalGstPaidData.totalGSTAmount || 0)}
            handleClick={handleClick}
            isClickable
          />
        )
      }
    </>
  );
};

export default TotalGstPaidCard;
