import { Box, Card as MuiCard, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledCard = styled(MuiCard)(({ theme, checked, disabled, bgColor }) => ({
    padding: '7px 17px',
    maxWidth: '300px',
    borderRadius: '10px',
    backgroundColor: bgColor ?? '#fff', // Set background based on bgColor prop
    border: checked ? '1px solid #104E5B' : '1px solid #00838F',
    cursor: disabled ? 'not-allowed' : 'pointer',
    textTransform: 'capitalize',
    transition: 'background-color 0.3s, border 0.3s',
    boxShadow: 'none',
    overflow: 'inherit',
    '&:hover': {
        backgroundColor: bgColor ?? theme.palette.common.white,
    },
}));

const ButtonCheckBox = ({ checked, onChange, disabled, bgColor, RequestForQuote, partnerNotApproved }) => {
    const theme = useTheme();
    // const buttonContent = checked ? (disabled ? 'Request Sent' : 'Added') : (disabled ? 'Get a Quote' : '+Add');
    let buttonContent;

    if (RequestForQuote) {
        buttonContent = partnerNotApproved ? 'Get a Quote' : disabled ? 'Request Sent' : 'Get a Quote';
    } else {
        buttonContent = checked ? 'Added' : '+Add';
    }

    const handleClick = () => {
        if (!disabled) {
            onChange(!checked);
        }
    };
    return (
        <StyledCard checked={checked} onClick={handleClick} disabled={disabled} bgColor={bgColor}>
            <Typography variant='bodyText3' color={theme.palette.secondary.main}>
                {buttonContent}
            </Typography>
        </StyledCard>
    );
};

export default ButtonCheckBox;