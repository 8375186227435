import { Skeleton } from '@mui/material';
import DashboardCard from 'components/commonComponents/cards/DashboardCard';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchTotalClients } from 'services/adminService';


const TotalNumberOfClientsCard = () => {
  const [clientsData, setClientsData] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTotalNumberOfClients = async () => {
      try {
        const response = await fetchTotalClients();
        const { totalTestators } = response.data;
        setClientsData({ totalTestators });
      } catch (error) {
        console.error('Error fetching number of clients data:', error);
      } finally {
        setLoading(false); // Set loading to false after API call
      }
    };

    fetchTotalNumberOfClients();
  }, []);

  const handleClick = () => {
    navigate('/admin/noOfClients');
  };

  return (
    <>
      {
        loading ? (
          <Skeleton variant="rectangular" height={135} sx={{ borderRadius: '1.25rem' }} />
        ) : (
          <DashboardCard
            title="No of Clients"
            subtitle={(clientsData.totalTestators ?? '--') || 0
            }
            handleClick={handleClick}
            isClickable
          />
        )
      }
    </>
  );
};

export default TotalNumberOfClientsCard;
