// material-ui
import { Box, Typography, useTheme } from '@mui/material';

// project imports
import { ContextProvider } from 'App';
import { ContainedButton1, ContainedButton2 } from 'components/DesignSystem/Button';
import menuItem from 'menu-items';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MENU_OPEN } from 'store/actions';
import NavGroup from './NavGroup';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = ({ isPartnerDashboard }) => {
  const { user } = useContext(ContextProvider);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const handleServices = () => {
    navigate('/services');
  };

  const goToMyWill = () => {
    dispatch({ type: MENU_OPEN, id: null });
    navigate('/myWill');
  };

  const navItems = menuItem.items.map((item) => {
    switch (item.type) {
      case 'group':
        if (item.id === 'dashboard' && ['testator', 'partner'].includes(user.role) && !isPartnerDashboard)
          return (
            <>
              <NavGroup key={item.id} item={item} />;
              <ContainedButton1 fullWidth onClick={goToMyWill}>
                <Typography variant="bodyText2">My Will</Typography>
              </ContainedButton1>
              {/* <Box sx={{ position: 'absolute', bottom: 50 }}> */}
              <Box sx={{ marginTop: '316px' }}>
                <ContainedButton2
                  sx={{
                    background: theme.palette.secondary.panelColor,
                    border: '1px solid #EFF9F915',
                    // minWidth: '328px',
                    // opacity: '80%',
                  }}
                  fullWidth
                  onClick={handleServices}
                >
                  <Typography variant="bodyText2">Our Services</Typography>
                </ContainedButton2>
              </Box>
            </>
          );
        if (item.id === 'administration' && ['admin'].includes(user.role))
          return <NavGroup key={item.id} item={item} />;
        if (item.id === 'reviewerDashboard' && ['reviewer'].includes(user.role))
          return <NavGroup key={item.id} item={item} />;
        if (item.id === 'partnerdashboard' && isPartnerDashboard) 
          return <NavGroup key={item.id} item={item} />;
        return <></>;
      // return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
