const partnerdashboard = {
    id: 'partnerdashboard',
    type: 'group',
    children: [
        {
            id: 'numberOfClients',
            title: 'Number of clients',
            type: 'item',
            url: '/partner/numberOfClients',
            breadcrumbs: false
        },
        {
            id: 'signupVsPaidUsers',
            title: 'Sign-ups vs Paid Users',
            type: 'item',
            url: '/partner/signupVsPaidUsers',
            breadcrumbs: false
        },
        {
            id: 'completedWills',
            title: 'Completed Wills',
            type: 'item',
            url: '/partner/completedWills',
            breadcrumbs: false
        },
        {
            id: 'incompleteWills',
            title: 'Incomplete Wills',
            type: 'item',
            url: '/partner/incompleteWills',
            breadcrumbs: false
        },
        {
            id: 'earnings',
            title: 'Earnings',
            type: 'item',
            url: '/partner/earnings',
            breadcrumbs: false
        }
    ]
};

export default partnerdashboard;