import PropTypes from 'prop-types';
// @mui
import { Box, TableCell, TableHead, TableRow, TableSortLabel, Typography, useTheme } from '@mui/material';

// ----------------------------------------------------------------------

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

UserListHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  headLabel: PropTypes.array,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  disableSort: PropTypes.bool
};

export default function UserListHead({
  order,
  orderBy,
  rowCount,
  headLabel,
  numSelected,
  onRequestSort,
  onSelectAllClick,
  secondRow,
  disableSort
}) {

  const theme = useTheme();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow >
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignCenter ? 'center' : headCell.alignRight ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id && !disableSort ? order : false}
            rowSpan={headCell.rowSpan}
            colSpan={headCell.colSpan}
            sx={{
              background: theme.palette.primary.lighter,
              // color: '#FF9E57',
              fontWeight: 'bold',
              border: 'none',
              // Add border radius dynamically to first and last cells
              '&:first-of-type': { borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' },
              '&:last-of-type': { borderTopRightRadius: '16px', borderBottomRightRadius: '10px' },
            }}
          >
            <TableSortLabel
              hideSortIcon
              active={!disableSort ? orderBy === headCell.id : false}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{
                '& .MuiTableSortLabel-icon': {
                  color: `${theme.palette.primary.main} !important`
                },
              }}
            >
              {headCell.id === "actions" ? "Actions" : <Typography variant='bodyText4' color={theme.palette.primary.main}>{headCell.label}</Typography>}
              {orderBy === headCell.id ? (
                <Box sx={{ ...visuallyHidden }}>{order === "desc" ? "sorted descending" : "sorted ascending"}</Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
      {secondRow && <TableRow>
        {secondRow.map((headCell) => (
          <TableCell
            style={{ fontSize: '18px' }}
            key={headCell.id}
            align={headCell.alignCenter ? 'center' : headCell.alignRight ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            rowSpan={headCell.rowSpan}
            colSpan={headCell.colSpan}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>}
    </TableHead>
  );
}
