import { Box, Grid, Skeleton, Typography } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { fCurrencyRupee } from 'utils/formatNumber';
import DashboardCard from 'components/commonComponents/cards/DashboardCard';

const ServiceWiseRevenue = () => {
  const [loading, setLoading] = useState(true);
  const [serviceRevenueData, setServiceRevenueData] = useState({});

  useEffect(() => {
    const fetchRevenueData = async () => {
      try {
        const { data } = await axios.get('/admin/service-wise-revenue');
        setServiceRevenueData(data);
      } catch (error) {
        console.error('Error fetching revenue data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRevenueData();
  }, []);

  const services = [
    { title: "Make your own Will", key: "Make_your_own_Will" },
    { title: "Call with Legal Expert", key: "Call_with_Legal_Expert" },
    { title: "Review by Lawyer", key: "Review_by_Lawyer" },
  ];

  return (
    <Box id="service-wise-revenue-container" sx={{ marginTop: '35px' }} >
      <Typography variant="title2">Service Wise Revenue</Typography>
      <Box display='flex' sx={{ marginTop: '20px' }}>
        {loading ? (
          <Skeleton variant="rectangular" height={135} sx={{ borderRadius: '1.25rem' }} />
        ) : (
          <Grid container spacing={2}>
            {services.map(({ title, key }) => (
              <Grid item md={4} key={key}>
                <DashboardCard
                  title={title}
                  subtitle={fCurrencyRupee(serviceRevenueData[key] || 0)}
                  isClickable
                  sx={{
                    backgroundColor: 'primary.lighter',
                    cursor: 'default',
                    '&:hover': {
                      backgroundColor: 'primary.lighter',
                      border: '1px solid transparent',
                      transform: 'none',
                      boxShadow: 'none',
                    },
                  }}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default ServiceWiseRevenue;
