import * as Yup from 'yup';
import regex from 'regex';

const today = new Date();
const futureLimitDate = new Date(today);
futureLimitDate.setFullYear(today.getFullYear() + 1000);
// Custom function to check if a date is valid
const isValidDate = (day, month, year) => {
    if (!day || !month || !year || year.length !== 4) return false;

    const dateStr = `${year}-${month}-${day}`;
    const date = new Date(dateStr);

    return (
        date.getFullYear() === parseInt(year, 10) &&
        date.getMonth() + 1 === parseInt(month, 10) &&
        date.getDate() === parseInt(day, 10)
    );
};

// Custom function to check if the user is older than 18
const isOlderThan18 = (year, month, day) => {
    if (!isValidDate(day, month, year)) return false;

    const birthDate = new Date(`${year}-${month}-${day}`);

    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        return age - 1 >= 18;  // Adjust for the case where the birthday hasn't happened yet this year
    }

    return age >= 18;
};

// Custom function to check if a date is in the past (not a future date)
const isPastDate = (day, month, year) => {
    if (!isValidDate(day, month, year)) return false;

    const inputDate = new Date(`${year}-${month}-${day}`);

    return inputDate <= today;
};

// Common date validation function
const createDobValidation = (errorMessages) => {
    return Yup.string()
        .required(errorMessages.required)
        .test('is-valid-date', errorMessages.validDate, value => {
            const [year, month, day] = value ? value.split('-') : [];
            return day && month && year && isValidDate(day, month, year);
        })
        .test('is-year-in-range', 'Please enter a valid date', value => {
            const [year] = value ? value.split('-') : [];
            return year && (parseInt(year, 10) >= 1900 && parseInt(year, 10) <= new Date().getFullYear());
        })
        .test('is-not-future-date', errorMessages.futureDate, value => {
            const [year, month, day] = value ? value.split('-') : [];
            return day && month && year && isPastDate(day, month, year);
        })
        .test('is-older-than-18', errorMessages.ageRequirement, value => {
            const [year, month, day] = value ? value.split('-') : [];
            return isOlderThan18(year, month, day);
        });
};

const formValidation = {
    inputTextValidation: Yup.string()
        .matches(regex.addressInputRegex, "Only alphabets, numbers, comma(,), dash(-) are allowed"),
    firstNameValidation: Yup.string()
        .required("Required field")
        .matches(regex.nameRegex, "Only alphabets are allowed"),

    middleNameValidation: Yup.string().nullable()
        .matches(regex.nameRegex, "Only alphabets are allowed"),

    lastNameValidation: Yup.string()
        .required("Required field")
        .matches(regex.nameRegex, "Only alphabets are allowed"),

    emailValidation: Yup.string()
        .matches(regex.emailRegex, "Invalid email format"),

    phoneValidation: Yup.string()
        .required("Required field")
        .test('phone-number', 'Invalid phone number', value => {
            if (!value) return false;
            const [code, dial, phone] = value.split(':');
            if (['in', 'us'].includes(code)) {
                return phone.length === 10;
            } if (code === 'gb') {
                return phone.length === 11;
            }
            return phone.length >= 7 && phone.length <= 12;
        }),

    panValidation: Yup.string()
        .required("Required field")
        .matches(regex.panRegex, "Please enter a valid PAN"),

    testatorDob: createDobValidation({
        required: 'Required field',
        validDate: 'Please enter a valid date',
        futureDate: 'Date cannot be in the future',
        ageRequirement: 'You must be older than 18',
    }),

    guardianDob: createDobValidation({
        required: 'Required field',
        validDate: 'Please enter a valid date',
        futureDate: 'Date cannot be in the future',
        ageRequirement: 'Guardian should be older than 18',
    }),

    witnessDob: createDobValidation({
        required: 'Required field',
        validDate: 'Please enter a valid date',
        futureDate: 'Date cannot be in the future',
        ageRequirement: 'Witness should be older than 18',
    }),

    executorDob: createDobValidation({
        required: 'Required field',
        validDate: 'Please enter a valid date',
        futureDate: 'Date cannot be in the future',
        ageRequirement: 'Executor should be older than 18',
    }),



    dateValidation: Yup.string()
        .nullable()
        .test('is-valid-date', 'Please enter a valid date', value => {
            // Skip validation if the value is null or empty
            if (!value) return true;
            const [year, month, day] = value ? value.split('-') : [];
            return day && month && year && isValidDate(day, month, year);
        }).test('is-not-future-date', 'Date cannot be in the future', value => {
            // Skip validation if the value is null or empty
            if (!value) return true;
            const [year, month, day] = value ? value.split('-') : [];
            return isPastDate(day, month, year);
        })
        .test('is-year-in-range', 'Please enter a valid date', value => {
            if (!value) return true;
            const [year] = value ? value.split('-') : [];
            return year && (parseInt(year, 10) >= 1900 && parseInt(year, 10) <= new Date().getFullYear());
        }),

    maturityDateValidation: Yup.string()
        .nullable()
        .test('is-valid-date', 'Please enter a valid date', value => {
            // Skip validation if the value is null or empty
            if (!value) return true;
            const [year, month, day] = value ? value.split('-') : [];
            return day && month && year && isValidDate(day, month, year);
        })
        .test('is-year-in-range', 'Please enter a valid date', value => {
            if (!value) return true;
            const [year] = value ? value.split('-') : [];
            return year && (parseInt(year, 10) >= 1900);
        })
        .test('is-future-date', 'Maturity date must be within 1000 years from now', value => {
            // Skip validation if the value is null or empty
            if (!value) return true;
            const [year, month, day] = value ? value.split('-') : [];
            const inputDate = new Date(`${year}-${month}-${day}`);
            return inputDate <= futureLimitDate;
        }),


    // Individual field validation schemas for address
    houseNoValidation: Yup.string()
        .matches(regex.addressInputRegex, "Only alphabets, numbers, comma(,), dash(-) are allowed"),
    houseNameValidation: Yup.string().
        required('Required field')
        .matches(regex.addressInputRegex, "Only alphabets, numbers, comma(,), dash(-) are allowed"),
    areaValidation: Yup.string().
        required('Required field')
        .matches(regex.addressInputRegex, "Only alphabets, numbers, comma(,), dash(-) are allowed"),
    countryValidation: Yup.string().
        required('Required field')
        .matches(regex.addressInputRegex, "Only alphabets, numbers, comma(,), dash(-) are allowed"),
    stateValidation: Yup.string().
        required('Required field')
        .matches(regex.addressInputRegex, "Only alphabets, numbers, comma(,), dash(-) are allowed"),
    cityValidation: Yup.string().
        required('Required field')
        .matches(regex.addressInputRegex, "Only alphabets, numbers, comma(,), dash(-) are allowed"),
    zipValidation: Yup.string().
        required('Required field')
        .matches(regex.zipRegex, "Please enter a valid PIN code"),

    same_as_currentValidation: Yup.boolean()
        .required('Required field'),

    // Existing Will Upload Validation
    registrarPlaceValidation: Yup.string().
        required('Required field')
        .matches(regex.addressInputRegex, "Only alphabets, numbers, comma(,), dash(-) are allowed"),

    fileValidation: Yup.mixed()
        .required('A file is required')
        .test('fileSize', 'File Size is too large', value => value && value.size <= 10485760) // 10MB
        .test('fileType', 'Unsupported File Format', value => value && ['application/pdf'].includes(value.type)),

    // Integer validation
    numberValidation: Yup.number()
        .nullable()
        .typeError('Must be a number') // Error message for non-numeric input
        .moreThan(0, 'Must be greater than 0'),

    folioNumberValidation: Yup.string()
        .nullable()
        .matches(regex.alphaNumericRegex, 'Please enter a valid Folio Number'),// Ensure only alphanumeric characters are present

    bankAccountNoValidation: Yup.string()
        .matches(regex.alphaNumericRegex, 'Please enter a valid Account Number'),// Ensure only alphanumeric characters are present

    fdNumberValidation: Yup.string()
        .required('Required field')
        .matches(regex.alphaNumericRegex, 'Please enter a valid Account Number/FD Number'),// Ensure only alphanumeric characters are present
    dpIdValidation: Yup.string()
        .required('Required field')
        .matches(regex.alphaNumericRegex, 'Please enter a valid DP ID'),// Ensure only alphanumeric characters are present

    domainValidation: Yup.string()
        .matches(regex.domainRegex, "Please enter a valid Domain Name"),

    copyrightRegistrationNoValidation: Yup.string()
        .nullable()
        .matches(regex.alphaNumericRegex, 'Please enter a valid Copyright Registration Number/Application Number'),// Ensure only alphanumeric characters are present

    trademarkRegistrationNoValidation: Yup.string()
        .nullable()
        .matches(regex.alphaNumericRegex, 'Please enter a valid Trademark Registration Number/Application Number'),// Ensure only alphanumeric characters are present

    patentRegistrationNoValidation: Yup.string()
        .nullable()
        .matches(regex.alphaNumericRegex, 'Please enter a valid Patent Registration Number/ Application Number'),// Ensure only alphanumeric characters are present

    policyNoValidation: Yup.string()
        .nullable()
        .matches(regex.alphaNumericRegex, 'Please enter a valid Policy Number'),// Ensure only alphanumeric characters are present

    insuranceAccountNoValidation: Yup.string()
        .nullable()
        .matches(regex.alphaNumericRegex, 'Please enter a valid Insurance Account Number'),// Ensure only alphanumeric characters are present

    prnNoValidation: Yup.string()
        .nullable()
        .matches(regex.alphaNumericRegex, 'Please enter a valid PRN Number'),// Ensure only alphanumeric characters are present

    uanNoValidation: Yup.string()
        .nullable()
        .matches(regex.alphaNumericRegex, 'Please enter a valid UAN Number'),// Ensure only alphanumeric characters are present

    certificateNoValidation: Yup.string()
        .nullable()
        .matches(regex.alphaNumericRegex, 'Please enter a valid Certificate Number'),// Ensure only alphanumeric characters are present

    shareCertificateNoValidation: Yup.string()
        .nullable()
        .matches(regex.alphaNumericRegex, 'Please enter a valid Share Certificate Number'),// Ensure only alphanumeric characters are present

    uinNoValidation: Yup.string()
        .nullable()
        .matches(regex.alphaNumericRegex, 'Please enter a valid Unique Identification Number'),// Ensure only alphanumeric characters are present

    accountNoValidation: Yup.string()
        .nullable()
        .matches(regex.alphaNumericRegex, 'Please enter a valid Account Number or Client ID'),// Ensure only alphanumeric characters are present

    clientIdValidation: Yup.string()
        .nullable()
        .matches(regex.alphaNumericRegex, 'Please enter a valid Client ID'),// Ensure only alphanumeric characters are present

    bankLockerValidation: Yup.string()
        .nullable()
        .matches(regex.alphaNumericRegex, 'Please enter a valid Bank Locker Number'),// 


    registerNumValidation: Yup.string()
        .nullable()
        .matches(regex.alphaNumericRegex, 'Please enter a valid Registration Number'),// Ensure only alphanumeric characters are present

    ifscCodeValidation: Yup.string()
        .matches(regex.ifscCodeRegex, 'Please enter a valid IFSC Code'),// Ensure only alphanumeric characters are present

    gstNoValidation: Yup.string()
        .matches(regex.gstinRegex, 'Please enter a valid GST Number'),// Ensure only alphanumeric characters are present

};

export default formValidation;
