import { useTheme } from '@emotion/react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { noop } from 'lodash';

const DashboardCard = ({ title = '', subtitle = '', sx = {}, handleClick = noop, isClickable = false }) => {
  const theme = useTheme();

  return (
    <Card
      onClick={handleClick}
      sx={{
        backgroundColor: 'grey.200',
        border: '1px solid transparent',
        cursor: isClickable ? 'pointer' : 'not-allowed',
        borderRadius: '1.25rem',
        boxShadow: 'none',
        transition: '0.3s ease',
        '&:hover': {
          backgroundColor: isClickable ? 'primary.lighter' : 'grey.200',
          border: isClickable ? `1px solid ${theme.palette.primary.dark}` : '1px solid transparent',
          transform: isClickable ? 'scale(-5px)' : 'none',
          boxShadow: isClickable ? '0px 10px 10px -8px #F47B204D' : 'none',
        },
        ...sx,
      }}
    >
      <CardContent sx={{ padding: '22px 24px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '135px', }}>
        <Typography variant="bodyText3" sx={{ color: isClickable ? theme.palette.primary.main : theme.palette.grey[100] }}>
          {title}
        </Typography>
        <Box >
          <Typography variant="heading1" sx={{ color: isClickable ? theme.palette.grey[600] : theme.palette.grey[100] }}>
            {subtitle}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

export default DashboardCard;