import {
  Box,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  Tooltip,
  Typography
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AWS from 'aws-sdk';
import axios from 'axios';
import { ContainedButton1 } from 'components/DesignSystem/Button';
import CustomDatePicker from 'components/DesignSystem/CustomDatePicker';
import { Dropdown } from 'components/DesignSystem/DropDown';
import useSwalAlert from 'components/DesignSystem/SwalAlert';
import { CustomTableCell, CustomTablePagination, CustomTableRow } from 'components/DesignSystem/Table/CustomTable';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { filter } from 'lodash';
import Scrollbar from 'oldLayouts/components/scrollbar';
import { UserListHead, UserListToolbar } from 'oldLayouts/components/user';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import PageContainer from 'views/dashboard/PageContainer';
import * as Yup from 'yup';

const COMMISSION_TABLE_HEAD = [
  // { id: "edit-action" },
  { id: 'partnerName', label: 'Partner Name', alignRight: false },
  { id: 'partnerCode', label: 'Partner Code', alignRight: false },
  { id: 'fromDate', label: 'From Date', alignRight: false },
  { id: 'toDate', label: 'To Date', alignRight: false },
  { id: 'totalDiscount', label: 'Total Discount', alignRight: false },
  { id: 'totalAmount', label: 'Total Amount', alignRight: false },
  { id: 'totalCommission', label: 'Total Commission', alignRight: false },
  { id: 'action', label: 'Action', alignCenter: true },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        JSON.stringify(_user ?? {})
          .toLowerCase()
          .indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const reportTypeOptions = [
  {
    label: 'Commission (Registered)',
    value: 'commissionRegistered',
  },
  {
    label: 'Commission (Unregistered)',
    value: 'commissionUnregistered',
  },
];

export default function CommissionReportsPage() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [commissionReports, setCommissionReports] = useState([]);
  const [reportFetch, setReportFetch] = useState(false);

  const location = useLocation();
  const isFromAdminDashboard = location.state?.fromAdminDashboard;
  const navigate = useNavigate();
  const showSwalAlert = useSwalAlert();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - commissionReports.length) : 0;
  const filteredCommissionReports = applySortFilter(commissionReports, getComparator(order, orderBy), filterName);
  const isNotFound = !filteredCommissionReports.length && !!filterName;

  const [partners, setPartners] = useState([
    { value: '1', label: 'Sameer Awate', partner_code: 'P100000' },
    { value: '2', label: 'Jitendra Jadhav', partner_code: 'P100001' },
  ]);
  const formik = useFormik({
    initialValues: {
      reportType: 'commissionRegistered',
      fromDate: dayjs().subtract(1, 'month').startOf('month'),
      toDate: dayjs().subtract(1, 'month').endOf('month'),
    },
    validationSchema: Yup.object().shape({
      reportType: Yup.string().required('Select the Report Type to view'),
      fromDate: Yup.date()
        .required('From Date is required')
        .typeError('Please enter valid date dd-mm-yyyy format')
        .min(dayjs('01-01-1900'), 'Date must be greater than 01-01-1900')
        .max(dayjs().endOf('day'), 'Date must be equal or before today'),
      toDate: Yup.date()
        .required('To Date is required')
        .typeError('Please enter valid date dd-mm-yyyy format')
        .min(dayjs('01-01-1900'), 'Date must be greater than 01-01-1900')
        .max(dayjs().endOf('day'), 'Date must be equal or before today')
        .test({
          name: 'notBeforeAsFromDate',
          message: 'To Date must be equal or greater than from date',
          test: (toDate, values) => {
            const fromDate = values.parent.fromDate;
            return toDate !== null ? dayjs(fromDate).isSameOrBefore(dayjs(toDate), 'day') : false;
          },
        }),
    }),
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      setReportFetch(false);
      axios
        .post('/reports', values)
        .then((response) => {
          if (response.status === 200) {
            setReportFetch(true);
            if (values.reportType === 'commissionUnregistered' || values.reportType === 'commissionRegistered')
              setCommissionReports(response.data.data);
          }
          let icon = 'success';
          if (
            response.data.data.length === 0 &&
            (values.reportType === 'commissionUnregistered' || values.reportType === 'commissionRegistered')
          ) {
            icon = 'error';
          }
          showSwalAlert({
            icon: `${icon}`,
            title: `${response.data.message}`,
            timer: 3000,
          });
        })
        .catch((err) => {
          console.error(err);
          showSwalAlert({
            icon: 'error',
            title: 'Failed to fetch report',
            timer: 3000,
          });
        });
    },
  });

  const handleDateChange = (value, name) => {
    const event = {
      target: {
        name,
        value: value !== null ? dayjs(value) : null,
      },
    };
    formik.handleChange(event);
  };

  const generateCommissionReport = (fromDate, toDate, reportType, partnerCode = null) => {
    axios
      .post('/reports/generate-commission-report', { partnerCode, fromDate, toDate, reportType })
      .then((response) => {
        if (response.status === 200) {
          const fileKey = response.data.fileKey;
          const s3 = new AWS.S3({
            region: process.env.REACT_APP_AWS_REGION ?? 'ap-south-1',
            accessKeyId: process.env.REACT_APP_AWS_KEY,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET,
          });
          // Set the parameters for the getObject request
          const params = {
            Bucket: process.env.REACT_APP_AWS_PDF_REPORTS_BUCKET_NAME ?? 'gwd-testing/gwd-pdf-reports', // Replace with your bucket name
            Key: `${fileKey}`, // Replace with the path to your file
          };

          // Make the getObject request
          s3.getObject(params, (err, data) => {
            if (err) {
              console.error('Error downloading file:', err);
            } else {
              // Create a Blob from the data and generate a download link
              const blob = new Blob([data.Body], { type: 'application/octet-stream' });
              const url = URL.createObjectURL(blob);

              // Create a download link and trigger click to start download
              const link = document.createElement('a');
              link.href = url;
              link.download = `GWD-Detail-Commission-Report-${dayjs(fromDate).format('DD-MM-YYYY')} to ${dayjs(
                toDate
              ).format('DD-MM-YYYY')}.pdf`;
              link.click();
              link.remove();

              // Clean up the object URL
              URL.revokeObjectURL(url);
            }
          });
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const handleBackClick = () => {
    navigate('/admin/analyticaldashboard');
  };

  return (
    <>
      <PageContainer showBackButton showHelpIcon={false}>
        <Helmet>
          <title> Commission Reports | Get Will Done </title>
        </Helmet>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          {/* {isFromAdminDashboard && (
            <IconButton color="primary" onClick={handleBackClick} sx={{ marginRight: '8px', marginBottom: '6px' }}>
              <ArrowBackIcon />
            </IconButton>
          )} */}
          <Typography variant="title1" gutterBottom>
            Commission Reports
          </Typography>
          <Box style={{ flexGrow: 1, marginLeft: '16px' }}>
            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
          </Box>
        </Stack>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid container spacing={1} columnGap={4} sx={{ marginBottom: 1 }}>
            <Grid item xs={12} md={3}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <Typography variant="bodyText4">Type</Typography>
                <Dropdown
                  name="reportType"
                  value={formik.values.reportType}
                  onBlur={formik.handleBlur}
                  handleChange={(event) => {
                    setReportFetch(false);
                    setCommissionReports([]);
                    formik.handleChange(event);
                  }}
                  placeholder="Type"
                  options={reportTypeOptions}
                  // dropdownWidth="270px"
                  error={formik.touched.reportType && formik.errors.reportType}
                  helperText={formik.errors.reportType}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <Typography width={'30%'} variant="bodyText4">From Date</Typography>
                <FormControl required error={formik.errors.fromDate} fullWidth variant="standard">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <CustomDatePicker
                      value={formik.values.fromDate}
                      name="fromDate"
                      error={formik.errors.fromDate}
                      fullWidth
                      inputFormat={'dd-MM-yyyy'}
                      maxDate={dayjs()}
                      maxDateMessage="Past dates are not allowed"
                      onChange={(value) => handleDateChange(value, 'fromDate')}
                      onBlur={formik.handleBlur}
                      placeholder="From Date(DD-MM-YYYY)"
                    />
                    {true ||
                      (formik.touched.fromDate && formik.errors.fromDate && (
                        <FormHelperText error id={`standard-weight-helper-text-fromDate`}>
                          <Typography variant="suggestiveText">{formik.errors.fromDate || 'error'}</Typography>
                        </FormHelperText>
                      ))}
                  </LocalizationProvider>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography width={'25%'} variant="bodyText4">To Date</Typography>
                <FormControl required error={formik.errors.toDate} fullWidth variant="standard">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <CustomDatePicker
                      value={formik.values.toDate}
                      name="toDate"
                      error={formik.errors.toDate}
                      fullWidth
                      inputFormat={'dd-MM-yyyy'}
                      maxDate={dayjs()}
                      onChange={(value) => handleDateChange(value, 'toDate')}
                      onBlur={formik.handleBlur}
                      placeholder="To Date(DD-MM-YYYY)"
                    />
                    {formik.touched.toDate && formik.errors.toDate && (
                      <FormHelperText error id={`standard-weight-helper-text-toDate`}>
                        <Typography variant="suggestiveText">{formik.errors.toDate}</Typography>
                        {formik.errors.toDate}
                      </FormHelperText>
                    )}
                  </LocalizationProvider>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              <Tooltip title={`Apply`}>
                <ContainedButton1 id={'new-generic'} type="submit">
                  <Typography variant="bodyText3">Apply</Typography>
                </ContainedButton1>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              {formik.errors.submit && (
                <Box sx={{ mt: 3 }}>
                  <FormHelperText error>{formik.errors.submit}</FormHelperText>
                </Box>
              )}
            </Grid>
          </Grid>
        </form>
        <Box>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {['commissionRegistered', 'commissionUnregistered'].includes(formik.values.reportType) && (
                <>
                  {commissionReports.length > 0 && (
                    <Box sx={{ textAlign: 'right' }}>
                      <Tooltip title="Download Detail Report for All Partners">
                        <Button
                          sx={{ paddingTop: '7px', paddingBottom: '7px' }}
                          onClick={() =>
                            generateCommissionReport(
                              formik.values.fromDate,
                              formik.values.toDate,
                              formik.values.reportType
                            )
                          }
                        >
                          <Chip
                            sx={{ cursor: 'pointer' }}
                            label={`Download Detail Report for All Partners`}
                            variant="outlined"
                          />
                        </Button>
                      </Tooltip>
                    </Box>
                  )}
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={COMMISSION_TABLE_HEAD}
                      rowCount={commissionReports.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      {filteredCommissionReports
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          const {
                            partnerName,
                            partnerCode,
                            registeredPartner,
                            fromDate,
                            toDate,
                            totalOrderValue,
                            totalPartnerCommission,
                            totalPartnerDiscount,
                          } = row;
                          return (
                            <>
                              <CustomTableRow sx={{ bgcolor: (theme) => 'initial' }} key={index + 1} tabIndex={-1}>
                                <CustomTableCell>{partnerName}</CustomTableCell>
                                <CustomTableCell>{partnerCode}</CustomTableCell>
                                <CustomTableCell>{dayjs(fromDate).format('DD-MM-YYYY')}</CustomTableCell>
                                <CustomTableCell>{dayjs(toDate).format('DD-MM-YYYY')}</CustomTableCell>
                                <CustomTableCell>₹{totalPartnerDiscount ?? 0}</CustomTableCell>
                                <CustomTableCell>₹{totalOrderValue ?? 0}</CustomTableCell>
                                <CustomTableCell>₹{totalPartnerCommission ?? 0}</CustomTableCell>
                                <CustomTableCell>
                                  <Tooltip title="Download Detail Report">
                                    <Button
                                      fullWidth
                                      sx={{ paddingTop: '7px', paddingBottom: '7px' }}
                                      onClick={() =>
                                        generateCommissionReport(
                                          fromDate,
                                          toDate,
                                          registeredPartner ? 'commissionRegistered' : 'commissionUnregistered',
                                          partnerCode
                                        )
                                      }
                                    >
                                      <Chip
                                        sx={{ cursor: 'pointer' }}
                                        label={`Download Detail Report`}
                                        variant="outlined"
                                      />
                                    </Button>
                                  </Tooltip>
                                </CustomTableCell>
                              </CustomTableRow>
                            </>
                          );
                        })}
                      {emptyRows > 0 && (
                        <CustomTableRow style={{ height: 53 * emptyRows }}>
                          <CustomTableCell colSpan={8} />
                        </CustomTableRow>
                      )}
                    </TableBody>

                    {reportFetch && commissionReports.length === 0 && (
                      <TableBody>
                        <CustomTableRow>
                          <CustomTableCell align="center" colSpan={8} sx={{ py: 3 }}>
                            No Partner Commission Found
                          </CustomTableCell>
                        </CustomTableRow>
                      </TableBody>
                    )}
                    {isNotFound && (
                      <TableBody>
                        <CustomTableRow>
                          <CustomTableCell align="center" colSpan={8} sx={{ py: 3 }}>
                            <Paper
                              sx={{
                                textAlign: 'center',
                              }}
                            >
                              <Typography variant="bodyText4" paragraph>
                                Not found
                              </Typography>

                              <Typography variant="smallText">
                                No results found for &nbsp;
                                <strong>&quot;{filterName}&quot;</strong>.
                                <br /> Try checking for typos or using complete words.
                              </Typography>
                            </Paper>
                          </CustomTableCell>
                        </CustomTableRow>
                      </TableBody>
                    )}
                  </Table>
                  <CustomTablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    component="div"
                    count={commissionReports.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              )}
            </TableContainer>
          </Scrollbar>
        </Box>
      </PageContainer>
    </>
  );
}
