import {
    Box,
    Button,
    Chip,
    FormControl,
    FormHelperText,
    Grid,
    Stack,
    Table,
    TableBody,
    TableContainer,
    Tooltip,
    Typography
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ContextProvider } from 'App';
import AWS from 'aws-sdk';
import axios from 'axios';
import { ContainedButton1 } from 'components/DesignSystem/Button';
import CustomDatePicker from 'components/DesignSystem/CustomDatePicker';
import useSwalAlert from 'components/DesignSystem/SwalAlert';
import { CustomTableCell, CustomTablePagination, CustomTableRow } from 'components/DesignSystem/Table/CustomTable';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { filter } from 'lodash';
import Scrollbar from 'oldLayouts/components/scrollbar';
import { UserListHead } from 'oldLayouts/components/user';
import { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import PageContainer from 'views/dashboard/PageContainer';
import * as Yup from 'yup';

const COMMISSION_TABLE_HEAD = [
    // { id: "edit-action" },
    { id: 'fromDate', label: 'From Date', alignRight: false },
    { id: 'toDate', label: 'To Date', alignRight: false },
    { id: 'totalDiscount', label: 'Total Discount', alignRight: false },
    { id: 'totalAmount', label: 'Total Amount', alignRight: false },
    { id: 'totalCommission', label: 'Total Commission', alignRight: false },
    { id: 'action', label: 'Action', alignCenter: true },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(
            array,
            (_user) =>
                JSON.stringify(_user ?? {})
                    .toLowerCase()
                    .indexOf(query.toLowerCase()) !== -1
        );
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function CommissionPage() {
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [commissionReports, setCommissionReports] = useState([]);
    const showSwalAlert = useSwalAlert();

    const location = useLocation();
    // const isFromPartnerDashboard = location.state?.fromPartnerDashboard || false;
    const isFromPartnerDashboard = ['/partner/earnings'].some((path) => location.pathname.includes(path));

    const { user } = useContext(ContextProvider);
    const [reportFetch, setReportFetch] = useState(false);
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = commissionReports.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - commissionReports.length) : 0;

    const filteredReports = applySortFilter(commissionReports, getComparator(order, orderBy), filterName);

    const isNotFound = !filteredReports.length && !!filterName;

    const formik = useFormik({
        initialValues: {
            fromDate: dayjs().subtract(1, 'month').startOf('month'),
            toDate: dayjs().subtract(1, 'month').endOf('month'),
        },
        validationSchema: Yup.object().shape({
            fromDate: Yup.date()
                .required('From Date is required')
                .typeError('Please enter valid date dd-mm-yyyy format')
                .min(dayjs('01-01-1900'), 'Date must be greater than 01-01-1900')
                .max(dayjs().endOf('day'), 'Date must be equal or before today'),
            toDate: Yup.date()
                .required('To Date is required')
                .typeError('Please enter valid date dd-mm-yyyy format')
                .min(dayjs('01-01-1900'), 'Date must be greater than 01-01-1900')
                .max(dayjs().endOf('day'), 'Date must be equal or before today')
                .test({
                    name: 'notBeforeAsFromDate',
                    message: 'To Date must be equal or greater than from date',
                    test: (toDate, values) => {
                        const fromDate = values.parent.fromDate;
                        console.log('value', toDate, fromDate, dayjs(fromDate).isSameOrBefore(dayjs(toDate), 'day'));
                        return toDate !== null ? dayjs(fromDate).isSameOrBefore(dayjs(toDate), 'day') : false;
                    },
                }),
        }),
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            setReportFetch(false);
            axios
                .post('/reports', {
                    ...values,
                    reportType: user.registeredPartner ? 'commissionRegistered' : 'commissionUnregistered',
                })
                .then((response) => {
                    console.log('response', response);
                    if (response.status === 200) {
                        setReportFetch(true);
                        setCommissionReports(response.data.data);
                    }
                    let icon = 'success';
                    if (response.data.data.length === 0) {
                        icon = 'error';
                    }
                    showSwalAlert({
                        icon: `${icon}`,
                        title: `${response.data.message}`,
                        timer: 3000,
                    });
                })
                .catch((err) => {
                    console.error(err);
                    showSwalAlert({
                        icon: 'error',
                        title: 'Failed to fetch report',
                        timer: 3000,
                    });
                });
        },
    });

    const handleDateChange = (value, name) => {
        const event = {
            target: {
                name,
                value: value !== null ? dayjs(value) : null,
            },
        };
        formik.handleChange(event);
    };

    const generateCommissionReport = (fromDate, toDate, reportType) => {
        axios
            .post('/reports/generate-commission-report', { fromDate, toDate, reportType })
            .then((response) => {
                console.log('response', response.data);
                if (response.status === 200) {
                    const fileKey = response.data.fileKey;
                    const s3 = new AWS.S3({
                        region: process.env.REACT_APP_AWS_REGION ?? 'ap-south-1',
                        accessKeyId: process.env.REACT_APP_AWS_KEY,
                        secretAccessKey: process.env.REACT_APP_AWS_SECRET,
                    });
                    // Set the parameters for the getObject request
                    const params = {
                        Bucket: process.env.REACT_APP_AWS_PDF_REPORTS_BUCKET_NAME ?? 'gwd-testing/gwd-pdf-reports', // Replace with your bucket name
                        Key: `${fileKey}`, // Replace with the path to your file
                    };

                    // Make the getObject request
                    s3.getObject(params, (err, data) => {
                        if (err) {
                            console.error('Error downloading file:', err);
                        } else {
                            // Create a Blob from the data and generate a download link
                            const blob = new Blob([data.Body], { type: 'application/octet-stream' });
                            const url = URL.createObjectURL(blob);

                            // Create a download link and trigger click to start download
                            const link = document.createElement('a');
                            link.href = url;
                            link.download = `GWD-Detail-Commission-Report-${dayjs(fromDate).format('DD-MM-YYYY')} to ${dayjs(
                                toDate
                            ).format('DD-MM-YYYY')}.pdf`;
                            link.click();
                            link.remove();

                            // Clean up the object URL
                            URL.revokeObjectURL(url);
                        }
                    });
                }
            })
            .catch((err) => {
                console.log('err', err);
            });
    };

    return (
        <>
            <Helmet>
                <title>{`Commission ${isFromPartnerDashboard ? 'Reports' : ''} | Get Will Done`}</title>
            </Helmet>

            <PageContainer>
                <Stack direction="row" alignItems="center" justifyContent="flex-start" mb={2}>
                    <Typography variant="title1" gutterBottom>
                        Earnings
                    </Typography>
                </Stack>
                <form noValidate onSubmit={formik.handleSubmit}>
                    <Grid container spacing={1} sx={{ marginBottom: 1 }}>
                        <Grid item xs={12} md={4}>
                            <Box>
                                <Typography variant='bodyText4'>From Date</Typography>
                                <FormControl required error={formik.errors.fromDate} fullWidth variant="standard">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <CustomDatePicker
                                            // label={`From Date`}
                                            value={formik.values.fromDate}
                                            name="fromDate"
                                            error={formik.errors.fromDate}
                                            fullWidth
                                            inputFormat={'dd-MM-yyyy'}
                                            maxDate={dayjs()}
                                            maxDateMessage="Past dates are not allowed"
                                            onChange={(value) => handleDateChange(value, 'fromDate')}
                                            onBlur={formik.handleBlur}
                                            placeholder='From Date(DD-MM-YYYY)'
                                        />
                                        {formik.touched.fromDate && formik.errors.fromDate && (
                                            <FormHelperText error id={`standard-weight-helper-text-fromDate`}>
                                                <Typography variant="suggestiveText">{formik.errors.fromDate}</Typography>
                                            </FormHelperText>
                                        )}
                                    </LocalizationProvider>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box>
                                <Typography variant='bodyText4'>To Date</Typography>
                                <FormControl required error={formik.errors.toDate} fullWidth variant="standard">
                                    <LocalizationProvider dateAdapter={AdapterDateFns} >
                                        <CustomDatePicker
                                            // label="To Date"
                                            value={formik.values.toDate}
                                            name="toDate"
                                            error={formik.errors.toDate}
                                            fullWidth
                                            inputFormat={'dd-MM-yyyy'}
                                            maxDate={dayjs()}
                                            onChange={(value) => handleDateChange(value, 'toDate')}
                                            onBlur={formik.handleBlur}
                                            placeholder='To Date(DD-MM-YYYY)'
                                        />
                                        {formik.touched.toDate && formik.errors.toDate && (
                                            <FormHelperText error id={`standard-weight-helper-text-toDate`}>
                                                <Typography variant="suggestiveText">{formik.errors.toDate}</Typography>
                                            </FormHelperText>
                                        )}
                                    </LocalizationProvider>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={2} sx={{ alignSelf: 'end', marginBottom: '5px' }}>
                            <Tooltip title={`Apply`}>
                                <ContainedButton1 id={'new-generic'} type="submit" sx={{ paddingX: '32px', height: '40px', }}>
                                    <Typography variant="bodyText3">Apply</Typography>
                                </ContainedButton1>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12}>
                            {formik.errors.submit && (
                                <Box sx={{ mt: 3 }}>
                                    <FormHelperText error>{formik.errors.submit}</FormHelperText>
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                </form>
                <Box>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={COMMISSION_TABLE_HEAD}
                                    rowCount={commissionReports.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {commissionReports.map((row, index) => {
                                        const {
                                            partnerName,
                                            partnerCode,
                                            fromDate,
                                            toDate,
                                            registeredPartner,
                                            totalOrderValue,
                                            totalPartnerCommission,
                                            totalPartnerDiscount,
                                        } = row;
                                        return (
                                            <>
                                                <CustomTableRow sx={{ bgcolor: (theme) => 'initial' }} key={index + 1} tabIndex={-1}>
                                                    <CustomTableCell>{dayjs(fromDate).format('DD-MM-YYYY')}</CustomTableCell>
                                                    <CustomTableCell>{dayjs(toDate).format('DD-MM-YYYY')}</CustomTableCell>
                                                    <CustomTableCell>₹{totalPartnerDiscount ?? 0}</CustomTableCell>
                                                    <CustomTableCell>₹{totalOrderValue ?? 0}</CustomTableCell>
                                                    <CustomTableCell>₹{totalPartnerCommission ?? 0}</CustomTableCell>
                                                    <CustomTableCell>
                                                        <Tooltip title="Download Detail Report">
                                                            <Button
                                                                fullWidth
                                                                sx={{ paddingTop: '7px', paddingBottom: '7px' }}
                                                                onClick={() =>
                                                                    generateCommissionReport(
                                                                        fromDate,
                                                                        toDate,
                                                                        registeredPartner ? 'commissionRegistered' : 'commissionUnregistered'
                                                                    )
                                                                }
                                                            >
                                                                <Chip sx={{ cursor: 'pointer' }} label={`Download Detail Report`} variant="outlined" />
                                                            </Button>
                                                        </Tooltip>
                                                    </CustomTableCell>
                                                </CustomTableRow>
                                            </>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <CustomTableRow style={{ height: 53 * emptyRows }}>
                                            <CustomTableCell colSpan={6} />
                                        </CustomTableRow>
                                    )}
                                </TableBody>

                                {reportFetch && commissionReports.length === 0 && (
                                    <TableBody>
                                        <CustomTableRow>
                                            <CustomTableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                No Partner Commission Found
                                            </CustomTableCell>
                                        </CustomTableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                    <CustomTablePagination
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        component="div"
                        count={commissionReports.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            </PageContainer>
        </>
    );
}
