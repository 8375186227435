import { Skeleton } from '@mui/material';
import DashboardCard from 'components/commonComponents/cards/DashboardCard';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchConversionRatio } from 'services/adminService';

const ConversionRatioCard = () => {
  const [conversionRatioData, setConversionRatioData] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchConversionRatioData = async () => {
      try {
        const response = await fetchConversionRatio();
        const { signupVsPaidRatio } = response.data;
        setConversionRatioData({ signupVsPaidRatio });
      } catch (error) {
        console.error('Error fetching conversion ratio data:', error);
      } finally {
        setLoading(false); // Set loading to false after API call
      }
    };

    fetchConversionRatioData();
  }, []);

  const handleClick = () => {
    navigate('/admin/conversionRatio');
  };

  return (
    <>
      {
        loading ? (
          <Skeleton variant="rectangular" height={135} sx={{ borderRadius: '1.25rem' }} />
        ) : (
          <DashboardCard
            title="Sign-Ups vs Paid Users"
            subtitle={conversionRatioData.signupVsPaidRatio || '-/-'}
            handleClick={handleClick}
            isClickable
          />
        )
      }
    </>
  );
};

export default ConversionRatioCard;
