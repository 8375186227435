function pxToRem(value) {
  return `${value / 16}rem`;
}

function responsiveFontSizes({ sm, md, lg }) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
    },
  };
}

const FONT_PRIMARY = 'Gentium Plus, serif'; // Assuming Gentium Basic is a web-safe font or has been imported.
const FONT_SECONDARY = 'DM Sans, sans-serif'; // Assuming DM Sans is a web-safe font or has been imported.

const typography = {
  fontFamily: FONT_PRIMARY,
  largeTitle: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 400,
    fontSize: pxToRem(60),
    ...responsiveFontSizes({ sm: 60, md: 60, lg: 60 }),
  },
  title1: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 400,
    fontSize: pxToRem(50),
    lineHeight: '100%',
    ...responsiveFontSizes({ sm: 50, md: 50, lg: 50 }),
  },
  title2: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 400,
    fontSize: pxToRem(35),
    lineHeight: '100%',
    ...responsiveFontSizes({ sm: 35, md: 35, lg: 35 }),
  },
  heading1: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 400,
    fontSize: pxToRem(25),
    lineHeight: '135%',
    ...responsiveFontSizes({ sm: 25, md: 25, lg: 25 }),
  },
  heading2: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 400,
    fontSize: pxToRem(22),
    lineHeight: '135%',
    ...responsiveFontSizes({ sm: 22, md: 22, lg: 22 }),
  },
  bodyText1: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 400,
    fontSize: pxToRem(20),
    lineHeight: '135%',
    ...responsiveFontSizes({ sm: 20, md: 20, lg: 20 }),
  },
  bodyText2: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 500,
    fontSize: pxToRem(20),
    lineHeight: '135%',
    ...responsiveFontSizes({ sm: 20, md: 20, lg: 20 }),
  },
  bodyText3: {
    fontFamily: FONT_SECONDARY,
    fontSize: pxToRem(18),
    fontWeight: 400,
    lineHeight: '135%',
    ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
  },
  bodyText4: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 400,
    fontSize: pxToRem(16),
    lineHeight: '135%',
    ...responsiveFontSizes({ sm: 16, md: 16, lg: 16 }),
  },
  smallText: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 400,
    fontSize: pxToRem(14),
    lineHeight: '135%',
    ...responsiveFontSizes({ sm: 14, md: 14, lg: 14 }),
  },
  smallText2: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 400,
    fontSize: pxToRem(13),
    lineHeight: '135%',
    ...responsiveFontSizes({ sm: 14, md: 14, lg: 14 }),
  },
  suggestiveText: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 400,
    fontSize: pxToRem(12),
    lineHeight: '135%',
    ...responsiveFontSizes({ sm: 12, md: 12, lg: 12 }),
  },
  errorText: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 400,
    color: '#F51D1D',
    fontStyle: 'italic',
    fontSize: pxToRem(12),
    lineHeight: '135%',
    ...responsiveFontSizes({ sm: 12, md: 12, lg: 12 }),
  },
  mainContent: {
    backgroundColor: '#FFFFFF',
    width: '100%',
    minHeight: 'calc(100vh - 88px)',
    flexGrow: 1,
    padding: '20px',
    marginTop: '24px',
    marginRight: '24px',
    marginBottom: '24px',
    borderRadius: '20px',
    marginLeft: '360px !important',
  },
};

export default typography;
