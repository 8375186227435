import axios from "axios";

export const fetchTotalNumberOfPartnerClients = async (list = false) => {
    const response = await axios.get(`/partners/dashboard/totalTestators${list ? '?list=true' : ''}`);
    return response;
}

export const fetchConversionRatio = async (list = false) => {
    const response = await axios.get(`/partners/dashboard/signupVsPaidRatio${list ? '?list=true' : ''}`);
    return response;
}

export const fetchIncompleteWills = async (list = false) => {
    const response = await axios.get(`/partners/dashboard/incompleteWills${list ? '?list=true' : ''}`);
    return response;
}

export const fetchCompleteWills = async (list = false) => {
    const response = await axios.get(`/partners/dashboard/completeWills${list ? '?list=true' : ''}`);
    return response;
}

export const fetchCommissionData = async (list = false) => {
    const response = await axios.get(`/partners/dashboard/totalCommission${list ? '?list=true' : ''}`);
    return response;
}

export const enrollAcknowledgeApi = async () => {
    const response = await axios.put(`/testator/enroll-acknowledge`);
    return response;
}

export const enrollAsPartnerApi = async () => {
    const response = axios.get('/partners/enroll/partner');
    return response;
}

