import { lazy } from 'react';

// project imports
import ProtectedRoutes from 'helpers/ProtectedRoutes';
import MainLayout from 'layout/MainLayout';
import DashboardLayout from 'oldLayouts/dashboard';
// import ReviewerDashboardAppPage from 'oldLayouts/pages/ReviewerDashboardAppPage';
import WillPage from 'oldLayouts/pages/WillPage';
import AdminLandingPage from 'pages/AdminPages/AdminLandingPage';
import ReportsPage from 'pages/AdminPages/ReportsPage';
import LandingPage from 'pages/LandingPage/LandingPage';
import ResourceHub from 'pages/LandingPage/ResourceHub';
import JourneyPage from 'pages/OnboardingFlow/JourneyPage';
import WelcomePage from 'pages/OnboardingFlow/WelcomePage';
import PartnerLandingPage from 'pages/PartnerLandingPage/PartnerLandingPage';
import AddressDetailsPage from 'pages/ProfileProcessPages/AddressDetailsPage';
import ExistingWillDetailsPage from 'pages/ProfileProcessPages/ExistingWillDetailsPage';
import PersonalDetailsPage from 'pages/ProfileProcessPages/PersonalDetailsPage';
import ProfileCompletionPage from 'pages/ProfileProcessPages/ProfileCompletionPage';
import ServicesPage from 'pages/ServicePage/ServicesPage';
import { Outlet } from 'react-router-dom';
import Loadable from 'ui-component/Loadable';
import CommissionReportsPage from 'views/adminDashboard/CommissionReportsPage';
import CompletedWillsPage from 'views/adminDashboard/CompletedWillsPage';
import ConversionRatioPage from 'views/adminDashboard/ConversionRatioPage';
import GstReportsPage from 'views/adminDashboard/GstPaidDetailsPage';
import IncompleteWillsPage from 'views/adminDashboard/IncompleteWillsPage';
import NumberOfClientsPage from 'views/adminDashboard/NumberOfClientsPage';
import PartnersPage from 'views/adminDashboard/PartnersPage';
import RepeatRatePage from 'views/adminDashboard/RepeatRatePage';
import TopPerformerPage from 'views/adminDashboard/TopPerformerPage';
import AssetPage from 'views/AssetPage';
import DistributionPage from 'views/distribution';
import MyWill from 'views/myWill';
import WillGenerationSuccess from 'views/myWill/WillGenerationSuccess';
import CommissionPage from 'views/partnerDashboard/CommissionPage';
import PartnerCompletedWillsPage from 'views/partnerDashboard/PartnerCompletedWillsPage';
import PartnerIncompleteWillsPage from 'views/partnerDashboard/PartnerIncompleteWillsPage';
import PartnerNumberOfClientsPage from 'views/partnerDashboard/PartnerNumberOfClientsPage';
import SignupVsPaidPartnerUsers from 'views/partnerDashboard/SignupVsPaidPartnerUser';
import ProfilePage from 'views/profile';
import ReviewerDashboardLandingPage from 'pages/ReviewerDashboardLandingPage/ReviewerDashboardLandingPage';
import CouponsPage from 'pages/AdminPages/CouponsPage';
// import AdminAnalyticalDashboard from 'oldLayouts/pages/AdminAnalyticalDashboard';
// import CouponsPage from 'oldLayouts/pages/CouponsPage';
// import PartnersPage from 'oldLayouts/pages/PartnersPage';
// import ReportsPage from 'oldLayouts/pages/Reports';
// import CommissionDetailsPage from 'oldLayouts/pages/AdminDashboardPages/CommissionDetailsPage';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <ProtectedRoutes />,
  children: [
    {
      path: '/',
      element: <Outlet />,
      children: [
        {
          path: '/personalDetails',
          element: <PersonalDetailsPage />,
        },
        {
          path: '/addressDetails',
          element: <AddressDetailsPage />,
        },
        {
          path: '/existingDetails',
          element: <ExistingWillDetailsPage />,
        },
        {
          path: '/profileCompletion',
          element: <ProfileCompletionPage />,
        },
        {
          path: '/welcome',
          element: <WelcomePage />,
        },
        {
          path: '/journey',
          element: <JourneyPage />,
        },
        {
          path: '/Services',
          element: <ServicesPage />,
        },
        {
          path: '/landingPage',
          element: <LandingPage />,
        },
        {
          path: '/resourceHub',
          element: <ResourceHub />,
        },
        {
          path: '/willGenerationSuccess',
          element: <WillGenerationSuccess />,
        },
        {
          path: '/partnerLandingPage',
          element: <PartnerLandingPage />,
        },
        {
          path: '/',
          element: <MainLayout />,
          children: [
            {
              path: '/myPeople',
              element: <DashboardDefault />,
            },
            {
              path: '/assets',
              element: <AssetPage />,
            },
            {
              path: '/distribution',
              element: <DistributionPage />,
            },
            {
              path: '/profilePage',
              element: <ProfilePage />,
            },
            {
              path: '/myWill',
              element: <MyWill />,
            },
            {
              path: '/partner',
              children: [
                {
                  path: 'numberOfClients',
                  element: <PartnerNumberOfClientsPage />,
                },
                {
                  path: 'signupVsPaidUsers',
                  element: <SignupVsPaidPartnerUsers />,
                },
                {
                  path: 'completedWills',
                  element: <PartnerCompletedWillsPage />,
                },
                {
                  path: 'incompleteWills',
                  element: <PartnerIncompleteWillsPage />,
                },
                {
                  path: 'earnings',
                  element: <CommissionPage />,
                },
              ],
            },
            {
              path: '/admin',
              children: [
                {
                  path: 'analyticaldashboard',
                  element: <AdminLandingPage />,
                },
                {
                  path: 'noOfClients',
                  element: <NumberOfClientsPage />,
                },
                {
                  path: 'partnerDetails',
                  element: <PartnersPage />,
                },
                {
                  path: 'topPerformer',
                  element: <TopPerformerPage />,
                },
                {
                  path: 'conversionRatio',
                  element: <ConversionRatioPage />,
                },
                {
                  path: 'repeatRate',
                  element: <RepeatRatePage />,
                },
                {
                  path: 'commissionReports',
                  element: <CommissionReportsPage />,
                },
                {
                  path: 'gstReports',
                  element: <GstReportsPage />,
                },
                {
                  path: 'completedWills',
                  element: <CompletedWillsPage />,
                },
                {
                  path: 'incompleteWills',
                  element: <IncompleteWillsPage />,
                },
                {
                  path: 'coupons',
                  element: <CouponsPage />,
                },
                {
                  path: 'partners',
                  element: <PartnersPage />,
                },
                {
                  path: 'reports',
                  element: <ReportsPage />,
                },
              ],
            },
            {
              path: '/reviewer',
              children: [
                {
                  path: 'reviewerDashboard',
                  element: <ReviewerDashboardLandingPage />,
                  // element: <div>Reviewer</div>,
                },
                {
                  path: ':review/distribution',
                  element: <DistributionPage />,
                },
              ],
            },
          ],
        },
        // {
        //   path: '/reviewer',
        //   element: <DashboardLayout />,
        //   children: [
        //     {
        //       path: '/reviewer/reviewerDashboard',
        //       element: <ReviewerDashboardAppPage />,
        //     },
        //     {
        //       path: '/reviewer/:review/distribution',
        //       element: <WillPage />,
        //     },
        //   ],
        // },
        // {
        //   path: '/admin',
        //   element: <DashboardLayout />,
        //   children: [
        //     {
        //       path: '/admin/analyticaldashboard',
        //       element: <AdminAnalyticalDashboard />,
        //     },
        //     {
        //       path: '/admin/coupons',
        //       element: <CouponsPage />,
        //     },
        //     {
        //       path: '/admin/partners',
        //       element: <PartnersPage />,
        //     },
        //     {
        //       path: '/admin/reports',
        //       element: <ReportsPage />, //commission reports, <CommissionReportsPage/>
        //     },
        //     {
        //       path: '/admin/no-of-clients',
        //       element: <NumberOfClientsPage />,
        //     },
        //     {
        //       path: '/admin/top-performer-page',
        //       element: <TopPerformerPage />,
        //     },
        //     {
        //       path: '/admin/conversion-ratio-page',
        //       element: <ConversionRatioPage />,
        //     },
        //     {
        //       path: '/admin/repeat-rate-page',
        //       element: <RepeatRatePage />,
        //     },
        //     {
        //       path: '/admin/commission-details-page',
        //       element: <CommissionDetailsPage />,
        //     },
        //     { path: '/admin/partner-details-page', element: <PartnersPage /> },
        //     { path: '/admin/gst-details-page', element: <GstPaidDetailsPage /> },
        //     { path: '/admin/complete-wills-page', element: <CompletedWillsPage /> },
        //     { path: '/admin/incomplete-wills-page', element: <IncompleteWillsPage /> },
        //   ],
        // },
      ],
    },
  ],
};

export default MainRoutes;
