import {
  Box,
  Card,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  useTheme
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from 'axios';
import { ContainedButton1 } from 'components/DesignSystem/Button';
import CustomDatePicker from 'components/DesignSystem/CustomDatePicker';
import { UserListHead, UserListToolbar } from 'oldLayouts/components/user';
import { CustomTableCell, CustomTablePagination, CustomTableRow } from 'components/DesignSystem/Table/CustomTable';
import dayjs from 'dayjs';
import { isNaN } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { fDate } from 'utils/formatTime';
// import { UserListHead } from '../user';

function timeToSeconds(timeString) {
  return timeString.split(' ').reduce((totalSeconds, part) => {
    const unit = part.slice(-1); // Last character represents the unit
    const value = parseInt(part.slice(0, -1), 10);
    if (unit === 's') {
      return totalSeconds + value;
    }
    if (unit === 'm') {
      return totalSeconds + value * 60;
    }
    if (unit === 'h') {
      return totalSeconds + value * 3600;
    }
    return totalSeconds;
  }, 0);
}

function descendingComparator(a, b, orderBy) {
  if (orderBy === 'avgUserEngagementDuration') {
    // If sorting by time duration, convert the time strings to seconds for comparison
    const timeA = timeToSeconds(a[orderBy]);
    const timeB = timeToSeconds(b[orderBy]);
    return timeB - timeA; // Descending order
  }
  if (!isNaN(parseFloat(a[orderBy])) && !isNaN(parseFloat(b[orderBy]))) {
    // If the values are numeric, compare them as numbers
    return parseFloat(b[orderBy]) - parseFloat(a[orderBy]);
  }
  // Otherwise, compare them as strings
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const TABLE_HEAD = [
  { id: 'pageTitle', label: 'Page Title', alignRight: false, numeric: false },
  { id: 'views', label: 'Page Views', alignRight: false, numeric: false },
  { id: 'visitedUsers', label: 'Users Count', alignRight: false, numeric: true },
  { id: 'avgPageViewsPerUser', label: 'Average Views Per User', alignRight: false, numeric: true },
  { id: 'avgUserEngagementDuration', label: 'Average Engagement Time', alignRight: false, numeric: true },
];

const UserJourney = () => {
  const theme = useTheme();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('pageTitle');
  const [userJourneyData, setUserJourneyData] = useState({});
  const [startDateError, setStartDateError] = useState(null);
  const [endDateError, setEndDateError] = useState(null);
  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month').toDate());
  const [endDate, setEndDate] = useState(new Date());
  const isButtonDisabled = startDateError || endDateError || !startDate || !endDate;

  const fetchUserJourneyData = async () => {
    const formattedStartDate = fDate(startDate, 'yyyy-MM-dd');
    const formattedEndDate = fDate(endDate, 'yyyy-MM-dd');
    try {
      const response = await axios.get(
        `/analytics/user-journey?startDate=${formattedStartDate}&endDate=${formattedEndDate}`
      );
      const { detailedData, summary } = response.data;
      const filteredDetailedData = detailedData.filter((item) => item.pageTitle !== 'Get Will Done'); // We don't show Get Will Done page details
      setUserJourneyData({ detailedData: filteredDetailedData, summary });
    } catch (error) {
      console.error('Error fetching user journey data:', error);
    }
  };

  useEffect(() => {
    fetchUserJourneyData();
  }, []);

  const handleFilterClick = () => {
    fetchUserJourneyData();
  };

  const isValidDate = (dateString) => {
    // Check if the input is not empty
    if (!dateString) return false;
    // Attempt to create a Date object from the input
    const date = new Date(dateString);
    // Check if the created date is a valid date
    return !Number.isNaN(date.getTime()) && date instanceof Date;
  };

  const handleStartDateChange = (date) => {
    const regex = /^\d{2}-\d{2}-(20\d{2}|21\d{2})$/;
    if (regex.test(dayjs(date).format('DD-MM-YYYY'))) {
      if (isValidDate(date)) {
        setStartDate(date);
        if (endDate && date > endDate) {
          setEndDate(null); // Reset end date if start date becomes greater
        }
        setStartDateError(null);
      }
    } else {
      setStartDateError('Please enter a valid date (DD-MM-YYYY) with hyphen');
    }
  };

  const handleEndDateChange = (date) => {
    const regex = /^\d{2}-\d{2}-(20\d{2}|21\d{2})$/;
    if (regex.test(dayjs(date).format('DD-MM-YYYY'))) {
      if (isValidDate(date)) {
        if (date < startDate) {
          setEndDateError('End date cannot be less than start date');
        } else if (date > new Date()) {
          setEndDateError('End date cannot be a future date');
        } else {
          setEndDate(date);
          setEndDateError(null);
        }
      }
    } else {
      setEndDateError('Please enter a valid date (DD-MM-YYYY) with hyphen');
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows = useMemo(
    () => stableSort(userJourneyData?.detailedData || [], getComparator(order, orderBy)),
    [order, orderBy, userJourneyData]
  );


  return (
    <Card sx={{ marginTop: '35px' }}>
      <Typography variant="title2">User Journey Details</Typography>
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px', margin: '16px 0', width: '75%' }}>
          <FormControl error={startDateError} fullWidth variant="standard">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CustomDatePicker
                // label="From Date"
                value={startDate}
                name="From Date"
                error={startDateError}
                fullWidth
                inputFormat={'dd-MM-yyyy'}
                maxDate={dayjs().subtract(1, 'day')}
                minDateMessage="Past dates are not allowed"
                onChange={handleStartDateChange}
                placeholder='From Date(DD-MM-YYYY)'
              />
              {startDateError &&
                <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                  <Typography variant="errorText" mr={3} mt={1}>
                    {startDateError}
                  </Typography>
                </Box>}
            </LocalizationProvider>
          </FormControl>
          <FormControl required error={endDateError} fullWidth variant="standard">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CustomDatePicker
                // label="End Date (Expiry Date)"
                value={endDate}
                error={endDateError}
                inputFormat={'dd-MM-yyyy'}
                maxDate={dayjs()}
                minDateMessage="Past dates are not allowed"
                onChange={handleEndDateChange}
                placeholder='To Date(DD-MM-YYYY)'
              />
              {endDateError &&
                <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                  <Typography variant="errorText" mr={3} mt={1}>
                    {endDateError}
                  </Typography>
                </Box>}
            </LocalizationProvider>
          </FormControl>
          <ContainedButton1 sx={{ height: '40px', width: '10vw' }} onClick={handleFilterClick} disabled={isButtonDisabled}>
            <Typography variant='bodyText3' color={theme.palette.common.white}>
              Apply
            </Typography>
          </ContainedButton1>
        </Box>

        <Box sx={{ display: 'flex', gap: '10%' }}>
          <Box sx={{ padding: '22px 24px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '115px', }}>
            <Typography variant='bodyText3' sx={{ color: theme.palette.primary.main }}>Users Count</Typography>
            <Box >
              <Typography variant="heading1" sx={{ color: theme.palette.grey[600] }}>{userJourneyData?.summary?.[0]?.visitedUsers || '--'}</Typography>
            </Box>
          </Box>

          <Box sx={{ padding: '22px 24px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '115px', }}>
            <Typography variant='bodyText3' sx={{ color: theme.palette.primary.main }}>Avg. Engagement Time</Typography>
            <Box >
              <Typography variant="heading1" sx={{ color: theme.palette.grey[600] }}>{userJourneyData?.summary?.[0]?.avgUserEngagementDuration || '--'}</Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ margin: '16px 0', overflow: 'auto' }}>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead order={order} orderBy={orderBy} headLabel={TABLE_HEAD} onRequestSort={handleRequestSort} />
              <TableBody>
                {visibleRows.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <CustomTableRow hover role="checkbox" tabIndex={-1} key={row.pageTitle}>
                      <CustomTableCell>
                        {row.pageTitle}
                      </CustomTableCell>
                      <CustomTableCell>{row.views}</CustomTableCell>
                      <CustomTableCell>{row.visitedUsers}</CustomTableCell>
                      <CustomTableCell>{parseFloat(row.avgPageViewsPerUser).toFixed(2)}</CustomTableCell>
                      <CustomTableCell>{row.avgUserEngagementDuration}</CustomTableCell>
                    </CustomTableRow>
                  );
                })}
              </TableBody>
              {visibleRows.length === 0 && (
                <TableBody>
                  <CustomTableRow>
                    <CustomTableCell align="center" colSpan={TABLE_HEAD.length} sx={{ py: 3 }}>
                      No Data Found
                    </CustomTableCell>
                  </CustomTableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Card>
  );
};

export default UserJourney;
