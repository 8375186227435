import { Skeleton } from '@mui/material';
import DashboardCard from 'components/commonComponents/cards/DashboardCard';
import { useEffect, useState } from 'react';
import { fetchAverageTimeOfWillGeneration } from 'services/adminService';

const AverageTimeCard = () => {
  const [averageTimeData, setAverageTimeData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAverageTimeData = async () => {
      try {
        const response = await fetchAverageTimeOfWillGeneration();
        const { averageTimeOfWillGeneration } = response.data;
        setAverageTimeData({ averageTimeOfWillGeneration });
      } catch (error) {
        console.error('Error fetching average time data:', error);
      } finally {
        setLoading(false); // Set loading to false after API call
      }
    };

    fetchAverageTimeData();
  }, []);

  return (
    <>
      {
        loading ? (
          <Skeleton variant="rectangular" height={135} sx={{ borderRadius: '1.25rem' }} />
        ) : (
          <DashboardCard
            title="Average Time To Complete Will"
            subtitle={(averageTimeData.averageTimeOfWillGeneration ?? '--') || 0}
            isClickable
            sx={{
              cursor: 'default',
              '&:hover': {
                backgroundColor: 'grey.200',
                border: '1px solid transparent',
                transform: 'none',
                boxShadow: 'none',
              },
            }}
          />
        )
      }
    </>
  );
};

export default AverageTimeCard;
