// src/hooks/useSwalAlert.js
import { useCallback } from 'react';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const useSwalAlert = () => {
    const showSwalAlert = useCallback(({ title, icon = 'success', text = '', timer = 3000, ...props }) => {
        // Create a <style> element with custom CSS for SweetAlert2
        const style = document.createElement('style');
        style.innerHTML = `
            .swal2-popup {
                font-family: 'DM Sans', sans-serif !important;
                font-size: 20px !important; /* Adjust font size if needed */
                border-radius: 20px !important; /* Rounded corners */
            }
            .swal2-title {
                font-family: 'DM Sans', sans-serif !important;
                font-size: 20px !important; /* Adjust font size if needed */
            }
            .swal2-html-container {
                font-family: 'DM Sans', sans-serif !important;
                font-size: 18px !important; /* Adjust font size if needed */
            }
            .swal2-confirm {
                font-family: 'DM Sans', sans-serif !important;
            }
        `;
        document.head.appendChild(style);

        // Show SweetAlert2 alert
        return Swal.fire({
            position: 'center',
            icon,
            color: '#fff',
            background: '#00838F',
            toast: true,
            title,
            text,
            showConfirmButton: false,
            timer,
            ...props,
            willClose: () => {
                // Remove the style element when the alert closes
                document.head.removeChild(style);
            }
        });
    }, []);

    return showSwalAlert;
};

export default useSwalAlert;
// Old Swal Code


// // src/hooks/useSwalAlert.js
// import { useCallback } from 'react';
// import Swal from 'sweetalert2';
// import 'sweetalert2/dist/sweetalert2.min.css';

// const useSwalAlert = () => {
//     const showSwalAlert = useCallback(
//         ({
//             title,
//             icon = 'success',
//             text = '',
//             showConfirmButton = false,
//             ...props
//         }) => {
//             // Create a <style> element with custom CSS for SweetAlert2
//             const style = document.createElement('style');
//             style.innerHTML = `
//             .swal2-popup {
//                 font-family: 'DM Sans', sans-serif !important;
//                 font-size: 20px !important; /* Adjust font size if needed */
//                 border-radius: 20px !important; /* Rounded corners */
//             }
//             .swal2-title {
//                 font-family: 'DM Sans', sans-serif !important;
//                 font-size: 20px !important; /* Adjust font size if needed */
//             }
//             .swal2-html-container {
//                 font-family: 'DM Sans', sans-serif !important;
//                 font-size: 18px !important; /* Adjust font size if needed */
//             }
//             .swal2-confirm {
//                 font-family: 'DM Sans', sans-serif !important;
//             }
//         `;
//             document.head.appendChild(style);

//             // Show SweetAlert2 alert
//             return Swal.fire({
//                 position: 'center',
//                 icon,
//                 color: '#fff',
//                 background: '#00838F',
//                 title,
//                 text,
//                 ...props,
//                 willClose: () => {
//                     // Remove the style element when the alert closes
//                     document.head.removeChild(style);
//                 },
//             });
//         },
//         []
//     );

//     return showSwalAlert;
// };

// export default useSwalAlert;


// // Swal.fire({
// //     title: 'Pet/Vehicle assets not distributed properly',
// //     text: 'Distribution of pet/vehicle assets is limited to a single beneficiary, with entire allocation (100%).',
// //     icon: 'warning',
// //     showConfirmButton: false,
// //     showCancelButton: true,
// //     allowEnterKey: false,
// //     allowOutsideClick: false,
// //     // confirmButtonColor: "#3085d6",
// //     cancelButtonColor: '#d33',
// //     cancelButtonText: 'OK',
// // }).then((result) => {
// //     if (result.isConfirmed) {
// //         // updateMappingsData();
// //     }
// // });


// // Swal.fire({
// //     title: 'Are you sure?',
// //     text: 'Some assets are not distributed completely. Do you wish to continue?',
// //     icon: 'warning',
// //     showCancelButton: true,
// //     allowEnterKey: false,
// //     allowOutsideClick: false,
// //     confirmButtonColor: '#3085d6',
// //     cancelButtonColor: '#d33',
// //     confirmButtonText: 'Yes, update distribution',
// // }).then((result) => {
// //     if (result.isConfirmed) {
// //         updateMappingsData();
// //         fetchWillsData();
// //     }
// // });