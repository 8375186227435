import { ContextProvider } from "App";
import { useContext } from "react";
import ExitDemoButton from "./ExitDemoButton";
import SkipToWillEditorButton from "./SkipToEditorButton";


const ActionButtons = () => {
  const { user } = useContext(ContextProvider);
  const isDemoActive = sessionStorage.getItem('isDemoActive') === 'true';

  if (user.role !== 'partner' || !isDemoActive) {
    return null;
  }

  return (
    <>
      <ExitDemoButton sx={{ position: 'fixed', top: '3.25rem', right: '6.75rem', zIndex: 1000, }} />
      <SkipToWillEditorButton sx={{ position: 'fixed', bottom: '9.938rem', right: '6.75rem', zIndex: 1000, }} />
    </>
  );
};

export default ActionButtons;
