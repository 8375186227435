import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import { Box, Card, Typography, useTheme } from '@mui/material';
import axios from 'axios';

const AgeDemographicChart = () => {
  const [ageDemographicsData, setAgeDemographicsData] = useState({});
  const chartContainer = useRef(null);
  const theme = useTheme();

  const labels = Object.keys(ageDemographicsData);
  const counts = Object.values(ageDemographicsData);

  useEffect(() => {
    const fetchAgeDemographicsData = async () => {
      try {
        const response = await axios.get('/admin/age-demographics');
        const { ageDemographics } = response.data;
        setAgeDemographicsData(ageDemographics);
      } catch (error) {
        console.error('Error fetching age demographic data:', error);
      }
    };

    fetchAgeDemographicsData();
  }, []);

  useEffect(() => {
    let myChart = null; // Declare a variable to hold the Chart instance

    if (chartContainer && chartContainer.current) {
      const ctx = chartContainer.current.getContext('2d');

      // Destroy the existing Chart instance if it exists
      if (myChart) {
        myChart.destroy();
      }

      // Create a new Chart instance
      myChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels,
          datasets: [
            {
              label: 'Age Demographics',
              data: counts,
              backgroundColor: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              // max: 5,  // Max value 
              beginAtZero: true,
              min: 0,
              ticks: {
                stepSize: 1,
              },
            },
          },
          plugins: {
            legend: {
              fullSize: false,
              position: 'top',
              align: 'end',
            },
            noDataMessage: {
              text: 'No data available',
              color: 'black',
              fontSize: 18,
            },
          },
        },
      });
    }
    // Cleanup function to destroy the Chart instance when the component unmounts
    return () => {
      if (myChart) {
        myChart.destroy();
      }
    };
  }, [ageDemographicsData]);

  return (
    <Card sx={{ position: 'relative', padding: '24px', width: '100%', backgroundColor: theme.palette.grey[200], borderRadius: '15px', boxShadow: 'none' }}>
      <Typography variant="heading1">Age Demographics</Typography>
      {Object.keys(ageDemographicsData).length === 0 ? (
        <Box sx={{ height: '300px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant="heading1">No Data To Display</Typography>
        </Box>
      ) : (
        <Box sx={{ height: '300px', width: '100%' }}>
          <canvas ref={chartContainer} id="chart" />
        </Box>
      )}
    </Card>
  );
};

export default AgeDemographicChart;
