import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import React from 'react';
import Icon from '../Icon';

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
    padding: 0,
}));

const CheckBox = ({iconSize = 16, ...props}) => (
    <CustomCheckbox
        icon={<Icon name='checkbox-unchecked.svg' size={iconSize} />} // Set custom icon for unchecked state
        checkedIcon={<Icon name='checked.svg' size={iconSize}/>}
        {...props}
    />
);

export default CheckBox;
