import React from 'react';
import { Card, CardContent, Typography, Box, Skeleton } from '@mui/material';

const StatsCard = ({ title, value, loading }) => {
    return (
        <Box
            sx={{
                // width: '470px',
                height: '130px',
                borderRadius: '20px',
                display: 'flex', // Flexbox for centering content
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Card
                sx={{
                    width: '100%',
                    height: '100%',
                    borderRadius: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    boxShadow: 0,
                }}
            >
                <CardContent>
                    <Box sx={{ textAlign: 'start' }}>
                        {loading ? (
                            // Skeleton Placeholder for value
                            <Skeleton variant="text" width='100%' height='100%' sx={{ marginBottom: 1 }} />
                        ) : (
                            <Typography
                                variant="h5"
                                sx={{
                                    fontFamily: 'DM Sans, sans-serif',
                                    fontSize: '3.438rem',
                                    fontWeight: 400,
                                    color: '#1C1C1B',
                                }}
                            >
                                {value}
                            </Typography>
                        )}
                        <Typography variant="bodyText4">{title}</Typography>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};

export default StatsCard;
