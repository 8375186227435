import { Box, useTheme } from '@mui/material';
import Lottie from '../Lottie';

const FullScreenLoader = () => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: `${theme.palette.common.white}`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999,
            }}
        >
            <Box sx={{ borderRadius: '50%' }}>
                <Lottie name={'FullscreenLoader'} size={200} />
            </Box>
        </Box>
    );
};

export default FullScreenLoader;
