import { ContextProvider } from 'App';
import FullScreenLoader from 'components/DesignSystem/FullScreenLoader';
import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const AuthRoutes = () => {
    const { fetchUser, setLoggedIn, setUser } = useContext(ContextProvider);
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const checkUser = async () => {
            try {
                const response = await fetchUser();
                const { user } = response.data;
                setUser(user); // Save user details in the context
                setLoggedIn(true);

                if (location.pathname === '/login' || location.pathname === '/') {
                    // Redirect based on role
                    if (user.role === 'admin') {
                        navigate('/admin/analyticaldashboard', { replace: true });
                        // } else if (user.role === 'testator' || user.role === 'partner') {
                    } else if (user.role === 'testator') {
                        if (user.profile_saved) {
                            navigate('/landingPage', { replace: true });
                        } else {
                            navigate('/welcome', { replace: true });
                        }
                    } else if (user.role === 'partner') {
                        // if (user.profile_saved) {
                        navigate('/partnerLandingPage', { replace: true });
                        // } else {
                        //     navigate('/welcome', { replace: true });
                        // }
                    } else if (user.role === 'reviewer') {
                        navigate('/reviewer/reviewerDashboard', { replace: true });
                    }
                }
            } catch (err) {
                setLoggedIn(false);
                console.error("Failed to fetch user:", err);
                if (location.pathname !== '/login') {
                    navigate('/login', { replace: true });
                }
            } finally {
                setIsLoading(false);
            }
        };

        if (location.pathname === '/login' || location.pathname === '/') {
            setIsLoading(true);
            checkUser();
        }
    }, [location, navigate, fetchUser, setLoggedIn, setUser]);

    if (isLoading) {
        return <FullScreenLoader />;
    }

    return <Outlet />;
};

export default AuthRoutes;