import axios from "axios";

export const fetchServices = async () => {
    const response = axios.get("/subscriptions");
    return response;
}

export const fetchActiveCoupons = async () => {
    const response = axios.get("/coupons/active");
    return response;
}

export const RequestForQuote = async (type) => {
    const response = axios.patch("/testator/request/call", { comment: `Request for a quote`, type });
    return response;
}

export const RequestForCall = async (type) => {
    const response = axios.patch("/testator/request/call", { comment: `Request for a call`, type });
    return response;
}


export const validateCoupon = async (couponCode) => {
    const response = axios.post(`/coupons/validate/${couponCode}`);
    return response;
}

export const subscribeApi = async ({ couponCode, offer, subscriptionIds }) => {
    const response = axios.post(`/testator/subscribe`, { couponCode, offer, subscriptionIds });
    return response;
}

export const checkPaymentStatusApi = async (userSubscription) => {
    const response = axios.post(`/testator/check/payment/${userSubscription}`);
    return response;
}

export const checkValidatePartnerApi = async (referralCode) => {
    const response = axios.post(`/partners/validate/${referralCode}`);
    return response;
}





