import React from 'react';
import { Box, Typography, Grid, Paper, useTheme } from '@mui/material';

const YouTubeVideoSection = () => {
    const theme = useTheme();

    // Video data as an object
    const videos = {
        "app": {
            url: "https://www.youtube.com/embed/RhXl2fNhkjk?rel=0",
            title: "GetWillDone Introduction Video"
        },
        "assets": {
            url: "https://www.youtube.com/embed/bX-NIh3XW-A?rel=0",
            title: "Who can be a beneficiary?"
        },
        "beneficiaries": {
            url: "https://www.youtube.com/embed/s4OMayI0H_4?rel=0",
            title: "What is a Beneficiary?"
        },
        "executors": {
            url: "https://www.youtube.com/embed/_n9984AxCZ0?rel=0",
            title: "Who is an Executor of a Will?"
        },
        "witnesses": {
            url: "https://www.youtube.com/embed/SUE-XG-NBtg?rel=0",
            title: "Who can be a witness to your Will?"
        },
        "distribution": {
            url: "https://www.youtube.com/embed/T_mauuyt5A0?rel=0",
            title: "What happens in absence of a Will?"
        },
        "preview": {
            url: "https://www.youtube.com/embed/XZlLsQsYDuA?rel=0",
            title: "What is a Will?"
        }
    };

    return (
        <Grid container spacing={3} sx={{ display: 'flex', flexDirection: 'row', minWidth: 'max-content', borderRadius: '15px', overflowX: 'auto' }}>
            {Object.values(videos).map((video, index) => (
                <Grid item key={index}>
                    <Paper
                        sx={{
                            height: '13.563rem',
                            width: '19.875rem',
                            p: '11px',
                            borderRadius: '15px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            boxShadow: 'none',
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                height: '100%',
                                borderRadius: '15px',
                                // overflow: 'hidden'
                            }}
                        >
                            <iframe
                                width="100%"
                                height="100%"
                                src={video.url}
                                title={video.title}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                style={{ borderRadius: '15px' }} // Apply border radius to iframe
                            />
                        </Box>
                        <Typography mt='8px' variant="bodyText4" sx={{ textAlign: 'left', width: '100%' }} color={theme.palette.grey[600]}>
                            {video.title}
                        </Typography>
                    </Paper>
                </Grid>
            ))}
        </Grid>
    );
};

export default YouTubeVideoSection;
