import { Box, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Helmet } from "react-helmet-async";
import PartnerSignUpForm from "sections/auth/login/PartnerSignUpForm";
import LogoContainer from "../components/commonComponents/LogoContainer";
import useResponsive from "../hooks/useResponsive";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex"
  }
}));

export default function PartnerSignUpPage() {
  const mdUp = useResponsive("up", "md");

  return (
    <>
      <Helmet>
        <title> Sign In | Get Will Done </title>
      </Helmet>
      <StyledRoot>
        <LogoContainer>
          <Grid
            item
            // xs={12}
            md={5}
            style={{
              display: 'flex',
              // flexDirection: 'column',
              justifyContent: 'center',
              // alignItems: 'center',
              paddingTop: '135px',
              backgroundColor: '#fff',
            }}
          >
            <Box style={{ width: '100%', maxWidth: '402px', paddingLeft:0, paddingRight: 0  }}>
              <PartnerSignUpForm />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#f9f9f9',
            }}
          >
            <Box
              component="img"
              src={'/assets/gwd-login-img.svg'}
              alt="Decorative"
              style={{ width: '100%', height: '100vh', objectFit: 'cover' }}
            />
          </Grid>
        </LogoContainer>
      </StyledRoot>
    </>
  );
}
