import { Box, Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { fDate } from 'utils/formatTime';
import { TextedButton1 } from '../../../components/DesignSystem/Button';

const PurchaseHistory = () => {
  const theme = useTheme();
  const [purchaseHistoryData, setPurchaseHistoryData] = useState([]);

  const getUserData = async () => {
    try {
      const response = await axios.get(`/testator/purchase-history`);
      console.log('response.data ', response.data);
      setPurchaseHistoryData(response?.data);
    } catch (error) {
      console.error('Error fetching user:', error);
    }
  };

  const fetchInvoicePdf = async (invoiceNumber) => {
    try {
      // Call the API endpoint to fetch the PDF
      const response = await fetch(`/testator/purchase-history/invoice/${invoiceNumber}`, {
        method: 'GET',
      });

      if (!response.ok) {
        throw new Error('Failed to fetch the invoice PDF');
      }

      // Convert the response to a Blob (binary data)
      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);
      window.open(url, 'InvoiceTab');

      // Optional: Clean up the URL object when no longer needed
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 1000 * 60 * 5); // Revoke the URL after 5 minutes to free memory
    } catch (error) {
      console.error('Error fetching invoice PDF:', error);
      throw error; // Re-throw the error to handle it elsewhere in your component
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <Box
      sx={{
        p: 2, // Padding inside the Box
        backgroundColor: '#F5F5F5',
        borderRadius: '15px',
        marginTop: '96px',
        marginRight: '94px',
      }}
    >
      <Box
        sx={{
          mb: 0, // Margin bottom
        }}
      >
        <Typography variant="heading2" color={theme.palette.secondary.main} gutterBottom>
          Purchase History
        </Typography>
      </Box>
      <Table sx={{ width: '100%', borderCollapse: 'collapse', borderSpacing: 0 }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="bodyText3">Order ID</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="bodyText3">Service</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="bodyText3">Date</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="bodyText3">Invoice</Typography>
            </TableCell>
          </TableRow>
          {/* Divider Row */}
          <TableRow>
            <TableCell colSpan={4}>
              <Divider
                sx={{
                  my: 0, // Remove vertical margins
                  borderColor: 'white', // Color of the divider
                  borderWidth: '1px', // Thickness of the divider
                  borderStyle: 'solid', // Style of the border
                  margin: 0, // Ensure there's no margin applied
                  padding: 0, // Ensure there's no padding applied
                  height: '1px', // Ensure the height of the divider is just a line
                }}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {purchaseHistoryData?.map((purchase, index) => (
            <React.Fragment key={index}>
              <TableRow>
                <TableCell>
                  <Typography variant="bodyText3">{purchase.orderId}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="bodyText3">{purchase.name}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="bodyText3">{fDate(purchase.orderDate, 'dd-MMM-yyyy')}</Typography>
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      border: (theme) => `1px solid ${theme.palette.primary.light}`, // Border for the box
                      borderRadius: '10px', // Optional: add border radius to the box
                      display: 'inline-block', // Optional: ensure the box fits around the button
                    }}
                  >
                    <TextedButton1
                      variant="contained"
                      sx={{
                        width: '71px', // Set your desired width here
                        height: '29px',
                        border: 'none', // Remove any default border if needed
                      }}
                      onClick={() => fetchInvoicePdf(purchase.invoiceNumber)}
                    >
                      <Typography variant="bodyText3">View</Typography>
                    </TextedButton1>
                  </Box>
                </TableCell>
              </TableRow>
              {index < purchaseHistoryData?.length - 1 && (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Divider
                      sx={{
                        my: 0, // Remove vertical margins
                        borderColor: 'white', // Color of the divider
                        borderWidth: '1px', // Thickness of the divider
                        borderStyle: 'solid', // Style of the border
                        margin: 0, // Ensure there's no margin applied
                        padding: 0, // Ensure there's no padding applied
                        height: '1px', // Ensure the height of the divider is just a line
                      }}
                    />
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default PurchaseHistory;
