import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import { ContextProvider } from 'App';
import { CustomAutocomplete } from 'components/DesignSystem/AutoComplete/CustomAutocomplete';
import Icon from 'components/DesignSystem/Icon';
import InputField from 'components/DesignSystem/InputField';
import useSwalAlert from 'components/DesignSystem/SwalAlert/SwalAlert';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { updateProfileApi } from 'services/profileService';
import { BankList } from 'utils/helper/BankList';
import formValidation from 'utils/helper/formValidations';
import * as Yup from 'yup';
import { ContainedButton1, TextedButton1 } from '../../../components/DesignSystem/Button';

const BankDetails = () => {
    const theme = useTheme();
    const [userProfile, setUserProfile] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [initialProfile, setInitialProfile] = useState(null); // Store the initial values

    const { user, fetchUser } = useContext(ContextProvider);
    const showSwalAlert = useSwalAlert();

    const handleOnEditClick = () => {
        setIsEdit((edit) => !edit);
    };

    const initialValues = {
        accountBankName: '',
        accountName: '',
        accountNumber: '',
        accountIFSC: '',
        accountBranch: '',
        accountCity: '',
        gstin: '',
    };

    const validationSchema = Yup.object().shape({
        accountName: formValidation.inputTextValidation,
        accountNumber: formValidation.bankAccountNoValidation,
        accountIFSC: formValidation.ifscCodeValidation,
        accountBranch: formValidation.inputTextValidation,
        accountCity: formValidation.inputTextValidation,
        gstin: formValidation.gstNoValidation,
    });

    const getUserData = async () => {
        try {
            const { data } = await fetchUser();
            const profile = data.user?.profile;
            setUserProfile(profile);
            setInitialProfile(profile); // Store the initial values for later reset
            formik.setValues({
                accountBankName: profile.accountBankName || '',
                accountName: profile.accountName || '',
                accountNumber: profile.accountNumber || '',
                accountIFSC: profile.accountIFSC || '',
                accountBranch: profile.accountBranch || '',
                accountCity: profile.accountCity || '',
                gstin: profile.gstin || '',
            });
        } catch (error) {
            console.error('Error fetching user:', error);
        }
    };

    useEffect(() => {
        getUserData();
    }, []);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            const profileData = { ...userProfile, ...values };
            try {
                // Call the API to update the profile
                await updateProfileApi({ profile: profileData });
                setIsEdit(false);
                // Show SweetAlert2 notification and wait for it to close
                await getUserData();
                await showSwalAlert({
                    title: 'Bank Details Saved Successfully',
                    icon: 'success',
                    timer: 1500,
                });
            } catch (error) {
                // Show SweetAlert2 notification and wait for it to close
                showSwalAlert({
                    title: 'Failed to save Bank Details',
                    icon: 'error',
                    timer: 1500,
                });
            }
        },
    });

    const handleCancel = () => {
        if (initialProfile) {
            formik.setValues({
                accountBankName: initialProfile.accountBankName || '',
                accountName: initialProfile.accountName || '',
                accountNumber: initialProfile.accountNumber || '',
                accountIFSC: initialProfile.accountIFSC || '',
                accountBranch: initialProfile.accountBranch || '',
                accountCity: initialProfile.accountCity || '',
                gstin: initialProfile.gstin || '',
            });
        }
        setIsEdit(false);
    };

    const handleSave = () => {
        formik.handleSubmit();
    };

    return (
        <>
            <Box
                sx={{
                    p: 2, // Padding inside the Box
                    backgroundColor: '#F5F5F5',
                    borderRadius: '15px',
                    marginTop: '96px',
                    marginRight: '94px',
                }}
            >
                <Box display="flex" alignItems="center" justifyContent="space-between" mt="8px">
                    <Typography variant="heading2" color={theme.palette.secondary.main} gutterBottom>
                        Bank Details
                    </Typography>

                    <Button
                        variant="outlined"
                        endIcon={<Icon name="gwd-icons-edit.svg" />}
                        onClick={handleOnEditClick}
                        sx={{
                            borderRadius: '15px',
                            padding: '3px 15px 5px 15px',
                            border: '0.7px solid #78BEC4',
                            backgroundColor: '#FFFFFF',
                            boxShadow: '6px 6px 8px -8px #00838F4D',
                            color: '#000000',
                            textTransform: 'none',
                            '&:hover': {
                                borderColor: '#78BEC4',
                            },
                        }}
                    >
                        <Typography variant="suggestiveText" sx={{ color: '#00838F', pt: '4px' }}>
                            Edit
                        </Typography>
                    </Button>
                </Box>
                <form>
                    <Grid container spacing={2} md={12} width='95%' mt="24px">
                        <Grid item xs={12} sm={6} md={6}>
                            <CustomAutocomplete
                                name="accountBankName"
                                placeholder="Bank Name"
                                value={formik.values.accountBankName}
                                handleChange={formik.handleChange}
                                options={BankList}
                                onBlur={formik.handleBlur}
                                error={formik.touched.accountBankName && Boolean(formik.errors.accountBankName)}
                                helperText={formik.touched.accountBankName && formik.errors.accountBankName}
                                backgroundColor={theme.palette.common.white}
                                height={'40px'}
                                disabled={!isEdit
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <InputField
                                fullWidth
                                name="accountName"
                                placeholder="Account Holder Name"
                                variant="outlined"
                                value={formik.values.accountName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.accountName && Boolean(formik.errors.accountName)}
                                helperText={formik.touched.accountName && formik.errors.accountName}
                                sx={{
                                    '& .MuiInputBase-root': {
                                        backgroundColor: (theme) => theme.palette.common.white, // Override the background color for this instance
                                        height: '2.5rem',
                                    },
                                }}
                                disabled={!isEdit}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <InputField
                                fullWidth
                                name="accountNumber"
                                placeholder="Account Number"
                                variant="outlined"
                                value={formik.values.accountNumber}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.accountNumber && Boolean(formik.errors.accountNumber)}
                                helperText={formik.touched.accountNumber && formik.errors.accountNumber}
                                sx={{
                                    '& .MuiInputBase-root': {
                                        backgroundColor: (theme) => theme.palette.common.white, // Override the background color for this instance
                                        height: '2.5rem',
                                    },
                                }}
                                disabled={!isEdit}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                            <InputField
                                fullWidth
                                name="accountIFSC"
                                placeholder="IFSC Code"
                                variant="outlined"
                                value={formik.values.accountIFSC}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.accountIFSC && Boolean(formik.errors.accountIFSC)}
                                helperText={formik.touched.accountIFSC && formik.errors.accountIFSC}
                                sx={{
                                    '& .MuiInputBase-root': {
                                        backgroundColor: (theme) => theme.palette.common.white, // Override the background color for this instance
                                        height: '2.5rem',
                                    },
                                }}
                                disabled={!isEdit}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                            <InputField
                                fullWidth
                                name="accountBranch"
                                placeholder="Branch Name"
                                variant="outlined"
                                value={formik.values.accountBranch}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.accountBranch && Boolean(formik.errors.accountBranch)}
                                helperText={formik.touched.accountBranch && formik.errors.accountBranch}
                                sx={{
                                    '& .MuiInputBase-root': {
                                        backgroundColor: (theme) => theme.palette.common.white, // Override the background color for this instance
                                        height: '2.5rem',
                                    },
                                }}
                                disabled={!isEdit}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                            <InputField
                                fullWidth
                                name="accountCity"
                                placeholder="City"
                                variant="outlined"
                                value={formik.values.accountCity}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.accountCity && Boolean(formik.errors.accountCity)}
                                helperText={formik.touched.accountCity && formik.errors.accountCity}
                                sx={{
                                    '& .MuiInputBase-root': {
                                        backgroundColor: (theme) => theme.palette.common.white, // Override the background color for this instance
                                        height: '2.5rem',
                                    },
                                }}
                                disabled={!isEdit}
                            />
                        </Grid>
                        <Grid md={12} mt={4} ml='20px'>
                            <Typography variant="heading2" color={theme.palette.secondary.main} gutterBottom>
                                GST Details
                            </Typography>
                        </Grid>
                        <Grid item md={12}>
                            <InputField
                                fullWidth
                                name="gstin"
                                placeholder="GST Identification Number"
                                variant="outlined"
                                value={formik.values.gstin}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.gstin && Boolean(formik.errors.gstin)}
                                helperText={formik.touched.gstin && formik.errors.gstin}
                                sx={{
                                    '& .MuiInputBase-root': {
                                        backgroundColor: (theme) => theme.palette.common.white, // Override the background color for this instance
                                        height: '2.5rem',
                                    },
                                }}
                                disabled={!isEdit}
                            />
                        </Grid>
                    </Grid>
                </form>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '10px',
                    mt: '16px',
                    mr: '94px',
                }}
            >
                <TextedButton1 sx={{ height: '40px', width: '109px' }} onClick={handleCancel} disabled={!isEdit}>
                    <Typography variant="bodyText3">Cancel</Typography>
                </TextedButton1>

                <ContainedButton1 onClick={handleSave} sx={{ height: '40px', width: '109px' }} disabled={!isEdit}>
                    <Typography variant="bodyText3">Save</Typography>
                </ContainedButton1>
            </Box>
        </>
    );
};

export default BankDetails;
