import { Box, Drawer } from "@mui/material";
import { alpha, styled } from '@mui/material/styles';
import axios from "axios";
import Logo from "oldLayouts/components/logo";
import NavSection from "oldLayouts/components/nav-section";
import PropTypes from 'prop-types';
import { useContext, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import toastr from "toastr";
import useResponsive from '../../../hooks/useResponsive';
import Scrollbar from '../../components/scrollbar';
import { UserContext } from "../DashboardLayout";
import navConfig from './config';

// ----------------------------------------------------------------------

export const NAV_WIDTH = 360;

const StyledAccount = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
    openNav: PropTypes.bool,
    onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
    const { pathname } = useLocation();

    const isDesktop = useResponsive('up', 'md');

    // useEffect(() => {
    //   if (openNav) {
    //     onCloseNav();
    //   }
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [pathname]);

    const { user, fetchUser } = useContext(UserContext);
    const subscribe = () => {
        axios.post('/testator/subscribe').then(res => toastr.success('Product purchase successful')).catch(e => toastr.error('Unable to purchase product')).finally(() => {
            navigate('/dashboard/app', { replace: true });
            fetchUser();
        });
    }

    const subscribed = useMemo(() => {
        // let consumed = user?.Subscriptions ?.length === 0;
        let consumed = true;
        user?.Subscriptions?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)).forEach(sub => {
            if (sub) {
                const userSub = sub.UserSubscription;
                if (userSub && userSub.SubscriptionId === 1) {
                    // const today = new Date();
                    // const subDay = new Date(userSub.createdAt);
                    // const subDays = (today - subDay) / 86400 / 1000;
                    // const allowed = sub.days;
                    // if (subDays > allowed)
                    //     consumed = true;
                    // else
                    //     consumed = false;
                    if (userSub.active === true) consumed = false;
                    else consumed = true;
                }
            }
        });
        return !consumed;
    }, [user]);

    const navigate = useNavigate();

    const renderContent = (
        <Scrollbar
            sx={{
                height: 1,
                '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
            }}
        >
            <Box sx={{ px: 2.5, py: 3, position: 'absolute' }}>
                <Logo minimized={!openNav} />
            </Box>
            <NavSection data={navConfig} disabled={['partner', 'testator'].includes(user.role) ? !user.profile_saved : false} isGrayedOut={user.role === 'partner' ? !subscribed : Boolean(false)} />
            <Box sx={{ flexGrow: 1 }} />
        </Scrollbar>
    );

    return (
        <Box
            component="nav"
            className={openNav ? 'open' : 'minimized'}
            sx={{
                "&.open .MuiListItemText-root": {
                    textAlign: 'left',
                    fontSize: 'large'
                }
                // flexShrink: { lg: 1 },
                // width: { lg: NAV_WIDTH },
            }}
        >
            {isDesktop ? (
                <Drawer
                    variant="permanent"
                    PaperProps={{
                        sx: {
                            width: openNav ? NAV_WIDTH : (48 + 24),
                            bgcolor: 'background.default',
                            borderRightStyle: 'dashed',
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            ) : (
                <Drawer
                    open={openNav}
                    onClose={onCloseNav}
                    variant="temporary"
                    ModalProps={{
                        keepMounted: true,
                    }}
                    PaperProps={{
                        sx: { width: NAV_WIDTH },
                    }}
                >
                    {renderContent}
                </Drawer>
            )}
        </Box>
    );
}
