import { Box, Divider, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { ContextProvider } from 'App';
import { useContext } from 'react';
import DeleteAccountSection from './DeleteAccountSection';
import LogoutSection from './LogoutSection';

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const Sidebar = ({ value, handleTabChange }) => {
  const theme = useTheme();
  const { user } = useContext(ContextProvider);
  const isPartnerUser = user?.role === 'partner';
  const isDemoActive = sessionStorage.getItem('isDemoActive') === 'true';

  const handlePrivacyPolicyClick = () => {
    window.open('https://getwilldone.com/privacy-policy/', 'Get Will Done');
  };

  const tabStyle = (index) => ({
    width: value === index ? '182px' : 'auto',
    height: value === index ? '40px' : 'auto',
    borderRadius: value === index ? '30px' : '0px',
    border: value === index ? `1px solid ${theme.palette.primary.light}` : 'none',
    backgroundColor: value === index ? theme.palette.primary.lighter : 'transparent',
    opacity: value === index ? 1 : 'inherit',
    mb: 1,
    mt: index === 0 ? '2.310rem' : 'none',
  });

  const renderTab = (label, index) => (
    <Tab
      label={<Typography variant="bodyText3">{label}</Typography>}
      {...a11yProps(index)}
      sx={tabStyle(index)}
    />
  );

  return (
    <>
      <Box
        sx={{
          width: '250px',
          height: '80vh',
          paddingLeft: '5px',
          marginTop: '9px',
          display: 'flex',
          flexDirection: 'row', // Change to row to place tabs and divider side by side
          justifyContent: 'flex-start', // Align items to the start
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between', // Ensures footer stays at the bottom
            flexGrow: 1, // Allow this Box to take up the remaining space
          }}
        >
          <Box>
            <Typography variant="title1">Profile</Typography>
            <Tabs
              orientation="vertical"
              value={value}
              onChange={handleTabChange}
              aria-label="Vertical tabs example"
              sx={{
                // borderRight: 1,
                // borderColor: theme.palette.grey[100],
                height: '100%', // Adjust height to account for footer
                '& .MuiTabs-indicator': {
                  display: 'none', // Hide the indicator for the selected tab
                },
                '& .MuiTab-root': {
                  textTransform: 'none', // Remove default text transformation
                  alignItems: 'flex-start',
                },
              }}
            >
              {renderTab('Personal Details', 0)}
              {renderTab('Address', 1)}
              {isPartnerUser && !isDemoActive && renderTab('Bank Details', 2)}
              {renderTab('Existing Will', isPartnerUser ? (isDemoActive ? 2 : 3) : 2)}
              {renderTab('Purchase History', isPartnerUser ? (isDemoActive ? 3 : 4) : 3)}
              {renderTab('Version History', isPartnerUser ? (isDemoActive ? 4 : 5) : 4)}
            </Tabs>
          </Box>

          {/* Footer Links */}
          <Box
            sx={{
              pt: 0.5, // Slight top padding to maintain some spacing while raising the links
              pb: 0, // No bottom padding
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <LogoutSection />
            <Typography
              variant="suggestiveText"
              onClick={handlePrivacyPolicyClick}
              sx={{
                color: theme.palette.grey[300],
                textDecoration: 'none',
                mb: 1,
                cursor: 'default',
                '&:hover': {
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  color: theme.palette.grey[400],
                },
              }}
            >
              Privacy Policy
            </Typography>
            {!isPartnerUser && <DeleteAccountSection />}
          </Box>
        </Box>
        <Divider
          orientation="vertical"
          sx={{ height: '93%', width: '1px', mx: '20px', color: '#C5C5C5', mt: 8 }} // Ensure it spans the full height of the container
        />
      </Box>
    </>
  );
};

export default Sidebar;
