import {
    styled,
    TableCell,
    TablePagination,
    TableRow,
    Typography,
    useTheme
} from '@mui/material';

const CustomTableRow = styled(TableRow)(({ theme }) => ({
    '& td': {
        borderBottom: '5px solid white',
        borderTop: '5px solid white',
        background: theme.palette.grey[200],
    },
    '& td:first-of-type': {
        borderTopLeftRadius: '12px', // Top-left radius for the first cell
        borderBottomLeftRadius: '12px', // Bottom-left radius for the first cell
    },
    '& td:last-of-type': {
        borderTopRightRadius: '12px', // Top-right radius for the last cell
        borderBottomRightRadius: '12px', // Bottom-right radius for the last cell
    },
}));

const CustomTableCell = ({ children, typographyColor = '', ...props }) => {
    const theme = useTheme();
    return (
        <TableCell {...props}>
            <Typography variant="smallText" color={typographyColor || theme.palette.grey[600]}>
                {children}
            </Typography>
        </TableCell>
    );
};

const CustomTablePagination = ({
    rowsPerPageOptions = [5, 10, 25, 50, 100],
    count,
    rowsPerPage,
    page,
    onPageChange,
    onRowsPerPageChange,
}) => {
    const theme = useTheme();

    return (
        <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            sx={{
                '& .MuiTablePagination-selectLabel': {
                    color: theme.palette.grey[600],
                    fontFamily: 'DM Sans, sans-serif !important',
                    fontWeight: 400,
                    fontSize: '14px',
                    marginTop: '10px',
                },
                '& .MuiTablePagination-displayedRows': {
                    color: theme.palette.grey[600],
                    fontFamily: 'DM Sans, sans-serif !important',
                    fontWeight: 400,
                    fontSize: '14px',
                },
                '& .MuiTablePagination-select': {
                    color: theme.palette.grey[600],
                    fontFamily: 'DM Sans, sans-serif !important',
                    fontWeight: 400,
                    fontSize: '14px',
                },
            }}
            backIconButtonProps={{
                sx: {
                    background: theme.palette.primary.lighter,
                    color: theme.palette.primary.main,
                    '&:hover': {
                        background: `${theme.palette.primary.light}90`,
                        color: `${theme.palette.primary.main}110`,
                    },
                    padding: '2px',
                    borderRadius: '50px',
                    marginRight: '4px',
                    border: `1px solid ${theme.palette.primary.light}`
                },
            }}
            nextIconButtonProps={{
                sx: {
                    background: theme.palette.primary.lighter,
                    color: theme.palette.primary.main,
                    '&:hover': {
                        background: `${theme.palette.primary.light}90`,
                        color: `${theme.palette.primary.main}110`,
                    },
                    padding: '2px',
                    borderRadius: '50px',
                    border: `1px solid ${theme.palette.primary.light}`
                },
            }}
        />
    );
};

export { CustomTableCell, CustomTablePagination, CustomTableRow };
