import { Box, Container, Link, Stack, Typography, useTheme } from '@mui/material';
import Lottie from 'components/DesignSystem/Lottie';
import { useNavigate } from 'react-router-dom';
import { CgArrowLongLeft } from 'react-icons/cg';
import { useContext } from 'react';
import { ContextProvider } from 'App';

const WillGenerationSuccess = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { user } = useContext(ContextProvider);

    const handleRedirectToEmail = () => {
        const userEmail = user?.email;
        const emailDomain = userEmail.split('@')[1];
        let emailProviderUrl = '';

        switch (emailDomain) {
            case 'gmail.com':
                emailProviderUrl = 'https://mail.google.com';
                break;
            case 'yahoo.com':
                emailProviderUrl = 'https://mail.yahoo.com';
                break;
            case 'outlook.com':
            case 'hotmail.com':
                emailProviderUrl = 'https://outlook.live.com';
                break;
            case 'icloud.com':
                emailProviderUrl = 'https://www.icloud.com/mail';
                break;
            case 'aol.com':
                emailProviderUrl = 'https://mail.aol.com';
                break;
            case 'zoho.com':
                emailProviderUrl = 'https://mail.zoho.com';
                break;
            case 'protonmail.com':
                emailProviderUrl = 'https://mail.protonmail.com';
                break;
            case 'yandex.com':
            case 'yandex.ru':
                emailProviderUrl = 'https://mail.yandex.com';
                break;
            case 'gmx.com':
                emailProviderUrl = 'https://www.gmx.com';
                break;
            case 'mail.com':
                emailProviderUrl = 'https://www.mail.com';
                break;
            default:
                emailProviderUrl = 'https://mail.google.com'; // Default to Gmail if the provider is unknown
                break;
        }

        window.location.href = emailProviderUrl;
    };

    return (
        <Container
            maxWidth="sm"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                minHeight: '100vh',
                textAlign: 'center',
            }}
        >
            <Box sx={{ paddingTop: '100px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {/* <Icon name='profile-completion-icon.png' size={111} style={{ textAlign: 'center' }} /> */}
                <Lottie name={'Loader'} size={111} style={{ textAlign: 'center' }} />
                <Typography variant="title2" sx={{ mb: 4, mt: '25px', width: 'max-content' }}>
                    You have successfully generated your Will!
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="bodyText3" color={theme.palette.grey[400]} sx={{ color: '#707070', mb: '12px' }}>
                    We have sent a copy of your Will on your mail.
                </Typography>
                <Typography variant="bodyText3" sx={{ textDecoration: 'underline' }}>
                    <Link onClick={handleRedirectToEmail} color={theme.palette.secondary.main} href="#">
                        Check mail
                    </Link>
                </Typography>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} gap={'4px'}>
                    <Box marginTop={'20px'}>
                        <CgArrowLongLeft color={theme.palette.secondary.main} />
                    </Box>
                    <Typography
                        onClick={() => navigate('/myPeople')}
                        variant="bodyText4"
                        sx={{
                            marginTop: '56px',
                            color: (theme) => theme.palette.secondary.main,
                            mb: 5,
                            '&:hover': {
                                textDecoration: 'underline',
                                cursor: 'pointer',
                            },
                        }}
                    >
                        Go to Dashboard
                    </Typography>
                </Stack>
            </Box>
        </Container>
    );
};

export default WillGenerationSuccess;
