import {
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { ContextProvider } from 'App';
import AWS from 'aws-sdk';
import { sentenceCase } from 'change-case';
import { format, startOfDay } from 'date-fns';
import React, { useContext, useEffect, useMemo } from 'react';
import { fDate } from 'utils/formatTime';
import { TextedButton1 } from '../../../components/DesignSystem/Button';

const VersionHistory = () => {
  const theme = useTheme();
  const { user, fetchUser } = useContext(ContextProvider);

  const getUserData = async () => {
    try {
      const response = await fetchUser();
    } catch (error) {
      console.error('Error fetching user:', error);
    }
  };

  useEffect(() => {
    getUserData();
  }, [])

  const activeReview = useMemo(() => {
    return user?.Reviews?.find(rev => rev.status !== "CLOSED" && rev.type === 'REVIEW');
  }, [user]);

  const versions = useMemo(() => {
    const currentDate = new Date();

    // Convert it to the format "2023-09-02T00:00:00.000Z"
    const formattedDate = format(startOfDay(currentDate), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");

    const versions = [{
      id: user?.id,
      version: user?.will_version,
      startedOn: user?.Mappings?.find(will => will.version === user?.will_version)?.createdAt,
      signed_at: formattedDate,

    }];
    const comments = [];
    user?.Reviews?.filter(rev => rev.status === "CLOSED")?.forEach(review => comments.push(review.review?.comment ?? ""));
    versions[0].comments = comments;
    user?.Wills?.forEach(will => {
      const generatedOn = will.createdAt;
      const link = `/wills/download/${will.id}`;
      if (user?.will_version !== will.version)
        versions.push({
          id: will.id, version: will.version, downloadFile: will.file, signed_at: will.uploaded ? will.signed_at : will.createdAt, generatedOn, link, uploaded: will.uploaded, registered_with: will.uploaded ? will.registered_with : null, registered_office_number: will.uploaded ? will.registered_office_number : null, serial_number: will.uploaded ? will.serial_number : null
        });
    });

    const sortedVersions = versions
      .filter(version => version.generatedOn !== undefined) // Filter by the condition
      .sort((a, b) => new Date(b.signed_at) - new Date(a.signed_at)); // Sort based on signed_at

    // Merge the sorted versions with the original array
    return versions.map(version =>
      version.generatedOn !== undefined ? sortedVersions.shift() : version
    );
  }, [user]);

  const versionsList = useMemo(() => {
    return versions.map((version, index) => {
      let status = "In Progress";
      if (!!activeReview) status = `Review ${sentenceCase(activeReview.status ?? "")}`;
      if (!!version.generatedOn) status = "Generated";
      console.log("linkkkkk is ", version.link)
      return {
        id: version.id,
        version: version.version,
        isGenerated: version.generatedOn ? Boolean(true) : Boolean(false),
        link: version.link,
        uploaded: version.uploaded,
        downloadFile: version.downloadFile,
        title: version.generatedOn ? `Will ${version.uploaded ? "uploaded" : "generated"}, Version: ${versions.length - index}` : `Active Will, Version: ${versions.length - index}, ${status}`,
        type: `order${version.generatedOn ? "2" : "3"}`,
        time: version.signed_at,
        registeredWith: version.registered_with,
        serialNumber: version.serial_number,
        registeredOfficeNumber: version.registered_office_number
      };
    })
  }, [versions]);

  const viewWillPdf = (downloadFile, type) => {
    console.log("downloadFile ", downloadFile)
    console.log("type ", type)
    if (type !== "order2") return;

    const s3 = new AWS.S3({
      region: process.env.REACT_APP_AWS_REGION ?? 'ap-south-1',
      accessKeyId: process.env.REACT_APP_AWS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET,
    });
    // Set the parameters for the getObject request
    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME ?? "gwd-testing/gwd-wills-upload",   // Replace with your bucket name
      Key: `${downloadFile}.pdf`, // Replace with the path to your file
    };

    // Make the getObject request
    s3.getObject(params, (err, data) => {
      if (err) {
        console.error('Error downloading file:', err);
      } else {
        // Create a Blob from the data and generate a download link
        const blob = new Blob([data.Body], { type: 'application/octet-stream' });
        const url = URL.createObjectURL(blob);

        window.open(url, 'InvoiceTab');

        // // Create a download link and trigger click to start download
        // const link = document.createElement('a');
        // link.href = url;
        // link.download = `will-${uploaded ? 'uploaded' : 'generated'}-${user.full_name ? user.full_name : user.email}.pdf`;
        // link.click();
        // link.remove();

        // Clean up the object URL
        // URL.revokeObjectURL(url);
      }
    });
  }

  console.log("versions , ", versions);
  console.log("versionsList , ", versionsList);

  return (
    <Box
      sx={{
        p: 2, // Padding inside the Box
        backgroundColor: '#F5F5F5',
        borderRadius: '15px',
        marginTop: '96px',
        marginRight: '94px',
        height: '70vh',
        overflow: 'auto'
      }}
    >
      <Box
        sx={{
          mb: 0, // Margin bottom
        }}
      >
        <Typography variant="heading2" color={theme.palette.secondary.main} gutterBottom>
          Version History
        </Typography>
      </Box>
      <Table sx={{ width: '100%', borderCollapse: 'collapse', borderSpacing: 0 }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="bodyText3">Version</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="bodyText3">Date</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="bodyText3">Status</Typography>
            </TableCell>
            <TableCell>
              {/* <Typography variant="bodyText3">Invoice</Typography> */}
            </TableCell>
          </TableRow>
          {/* Divider Row */}
          <TableRow>
            <TableCell colSpan={4}>
              <Divider
                sx={{
                  my: 0, // Remove vertical margins
                  borderColor: 'white', // Color of the divider
                  borderWidth: '1px', // Thickness of the divider
                  borderStyle: 'solid', // Style of the border
                  margin: 0, // Ensure there's no margin applied
                  padding: 0, // Ensure there's no padding applied
                  height: '1px', // Ensure the height of the divider is just a line
                }}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {versionsList?.map((version, index) => (
            <React.Fragment key={index}>
              <TableRow>
                <TableCell>
                  <Typography variant="bodyText3">{version.title}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="bodyText3">{fDate(version.time, 'dd-MMM-yyyy')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="bodyText3">{version.isGenerated ? 'Closed' : 'Active'}</Typography>
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      border: (theme) =>
                        index === 0
                          ? 'none'
                          : `1px solid ${theme.palette.primary.light}`, // Conditionally apply border
                      borderRadius: '10px',
                      display: 'inline-block',
                    }}
                  >
                    <TextedButton1
                      variant="contained"
                      // disabled
                      disabled={!version.isGenerated} // Disable the first button
                      border='none'
                      sx={{
                        width: '71px',
                        height: '29px',
                        border: 'none',
                        '&.Mui-disabled': {
                          backgroundColor: theme.palette.grey[100], // Disabled background color
                          color: theme.palette.grey[0], // Disabled text color
                        },
                      }}
                      onClick={() => viewWillPdf(version.downloadFile, version.type)}
                    >
                      <Typography variant="bodyText3">View</Typography>
                    </TextedButton1>

                  </Box>
                </TableCell>
              </TableRow>
              {index < versionsList.length - 1 && (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Divider
                      sx={{
                        my: 0, // Remove vertical margins
                        borderColor: 'white', // Color of the divider
                        borderWidth: '1px', // Thickness of the divider
                        borderStyle: 'solid', // Style of the border
                        margin: 0, // Ensure there's no margin applied
                        padding: 0, // Ensure there's no padding applied
                        height: '1px', // Ensure the height of the divider is just a line
                      }}
                    />
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default VersionHistory;
