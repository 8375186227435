import { Card, Stack, Typography } from "@mui/material";
import PropTypes from 'prop-types';

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.string.isRequired,
  sx: PropTypes.object,
};

export default function AppWidgetSummary({ title, subtitle, unit, total, secondaryContent, icon, color = 'primary', sx, ...other }) {
  return (
    <Card
      sx={{
        height: '100%',
        p: 2,
        boxShadow: 0,
        textAlign: 'center',
        color: (theme) => theme.palette[color][900],
        bgcolor: (theme) => theme.palette[color][500],
        borderTop: '8px solid',
        borderTopColor: (theme) => total <= 5 ? theme.palette.error.main : theme.palette.success.main,
        ...sx,
      }}
      {...other}
    >
      <Stack sx={{placeContent: 'center', height: '100%'}}>
        <Typography variant="h5" align="left">{title}</Typography>
        <Typography variant="h3" align="left" sx={{"& span": { fontSize: '0.4em', color: (theme) => theme.palette.text.secondary }}}>
          {total} <span>{unit}</span>
        </Typography>
        {secondaryContent}
      </Stack>
    </Card>
  );
}
