import { Box, Skeleton, Stack } from '@mui/material';

const DistributionSkeleton = ({ isPrimary }) => {
    return (
        <Box>
            {isPrimary ? (
                <Stack alignItems={'flex-end'} marginBottom={'8px'}>
                    <Skeleton width={'160px'} height={'40px'} sx={{ borderRadius: '18px' }} />
                </Stack>
            ) : (
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <Stack direction={'row'} spacing={'12px'}>
                        <Skeleton width={'80px'} height={'50px'} sx={{ borderRadius: '22px' }}/>
                        <Skeleton width={'100px'} height={'50px'} sx={{ borderRadius: '22px' }}/>
                    </Stack>
                    <Stack>
                        <Skeleton height={40} width={30} sx={{ borderRadius: '10px' }}/>
                    </Stack>
                </Stack>
            )}
            <Stack direction={'column'} spacing={'24px'}>
                <Skeleton height={'140px'} width={'100%'} sx={style.commonStyles} />
                <Skeleton height={'140px'} width={'100%'} sx={style.commonStyles} />
                <Skeleton height={'140px'} width={'100%'} sx={style.commonStyles} />
            </Stack>
        </Box>
    );
};

export default DistributionSkeleton;

const style = {
    commonStyles: { borderRadius: '12px', transform: 'unset' },
};
