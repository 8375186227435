import axios from "axios";

export const fetchAssets = async () => {
    const response = await axios.get(`/properties`);
    return response;
}

export const fetchAssetById = async (id) => {
    const response = await axios.get(`/properties/${id}`);
    return response;
}

export const addAsset = async ({ category, type, subType, asset }) => {
    const response = axios.post("/properties", { category, type, subType, asset });
    return response;
}

export const updateAsset = async (id, { category, type, subType, asset }) => {
    const response = await axios.post(`/properties/${id}`, { category, type, subType, asset });
    return response;
}

export const deleteAsset = async (id) => {
    const response = await axios.delete(`/properties/${id}`);
    return response;
}