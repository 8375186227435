import { Skeleton } from '@mui/material';
import DashboardCard from 'components/commonComponents/cards/DashboardCard';
import { useEffect, useState } from 'react';
import { fetchTotalRevenue } from 'services/adminService';
import { fCurrencyRupee } from 'utils/formatNumber';

const TotalRevenueCard = () => {
  const [repeatRateData, setRepeatRateData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTotalRevenueData = async () => {
      try {
        const response = await fetchTotalRevenue();
        const { totalRevenue } = response.data;
        setRepeatRateData({ totalRevenue });
      } catch (error) {
        console.error('Error fetching total revenue data:', error);
      } finally {
        setLoading(false); // Set loading to false after API call
      }
    };

    fetchTotalRevenueData();
  }, []);

  const scrollToTable = () => {
    const element = document.getElementById('service-wise-revenue-container');
    if (element) {
      const elementPosition = element.getBoundingClientRect().top;
      const scrollPosition = elementPosition + window.scrollY - window.innerHeight * 0.1;
      window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    }
  };

  return (
    <>
      {
        loading ? (
          <Skeleton variant="rectangular" height={135} sx={{ borderRadius: '1.25rem' }} />
        ) : (
          <DashboardCard
            title="Total Revenue Generated"
            subtitle={fCurrencyRupee(repeatRateData?.totalRevenue || 0)}
            // handleClick={scrollToTable}
            isClickable
            sx={{
              cursor: 'default',
              '&:hover': {
                backgroundColor: 'grey.200',
                border: '1px solid transparent',
                transform: 'none',
                boxShadow: 'none',
              },
            }}
          />
        )
      }
    </>
  );
};

export default TotalRevenueCard;
