import { Popover, Box, Typography, Divider, MenuList, MenuItem, ListItemIcon, useTheme } from '@mui/material';
import { HiOutlineUserCircle } from 'react-icons/hi2';
import { IoIosLogOut } from 'react-icons/io';
import { noop } from 'lodash';

const ProfileMenu = ({
    anchorEl = false,
    onClose = noop,
    open = false,
    name = '',
    email = '',
    handleProfileClick = noop,
    handleLogoutClick = noop,
    hideProfileButton = false,
}) => {
    const theme = useTheme();

    return (
        <Popover
            anchorEl={anchorEl}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            onClose={onClose}
            open={open}
            slotProps={{ paper: { sx: { width: '240px' } } }}
        >
            <Box sx={{ p: '16px 20px ' }}>
                <Box>
                    <Typography variant="bodyText2">{name}</Typography>
                </Box>
                <Box>
                    <Typography variant="bodyText4">{email}</Typography>
                </Box>
            </Box>
            <Divider />
            <MenuList disablePadding sx={{ p: '8px', '& .MuiMenuItem-root': { borderRadius: 1 } }}>
                {!hideProfileButton && (
                    <MenuItem onClick={handleProfileClick}>
                        <ListItemIcon>
                            <HiOutlineUserCircle color={theme.palette.primary.main} fontSize="var(--icon-fontSize-md)" />
                        </ListItemIcon>
                        <Typography variant="bodyText4">Profile</Typography>
                    </MenuItem>
                )}
                <MenuItem onClick={handleLogoutClick}>
                    <ListItemIcon>
                        <IoIosLogOut color={theme.palette.primary.main} />
                    </ListItemIcon>
                    <Typography variant="bodyText4">Logout</Typography>
                </MenuItem>
            </MenuList>
        </Popover>
    );
};

export default ProfileMenu;
