import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import useSwalAlert from 'components/DesignSystem/SwalAlert/SwalAlert';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchFaqsApi } from 'services/landingPageService';

const CustomToggleButton2 = styled(ToggleButton)(({ theme }) => ({
    height: '2.5rem',
    padding: '13.5px 16px',
    minWidth: '123px',
    borderRadius: '10px',
    border: '1px solid #3CA1AA',
    backgroundColor: 'transparent',
    color: '#00838F',
    marginRight: '10px',
    textTransform: 'none',
    '&:last-child': {
        marginRight: 0,
    },
    '&.Mui-selected': {
        backgroundColor: '#FFFFFF',
        color: '#00838F',
        border: '1px solid #3CA1AA',
    },
    '&.Mui-selected:hover': {
        backgroundColor: '#FFFFFF', // No hover effect change
    },
    '&:hover': {
        backgroundColor: '#FFFFFF', // No hover effect change
    }
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    margin: '8px 0', // Margin between each question
    borderRadius: '8px', // Border radius for AccordionSummary
    '&.Mui-expanded': {
        // If you need to adjust styles when expanded
        borderRadius: '8px',
    },
}));

// Styled Accordion to ensure no border-radius conflict
const CustomAccordion = styled(Accordion)(({ theme }) => ({
    borderRadius: '8px', // Apply border radius to Accordion itself
    marginRight: '10px',
    '&:before': {
        display: 'none', // Remove default before pseudo-element
    },
}));

const FaqSection = ({ onFaqTypeChange, searchQuery, defaultFaqType }) => {
    const [faqs, setFaqs] = useState([]); // State to hold FAQs
    const [faqType, setFaqType] = useState(defaultFaqType || 'general');
    const [expanded, setExpanded] = useState(false);

    const categoryMapping = {
        general: ['Will Overview', 'Essentials of a Will', 'Types of Wills'],
        mypeople: ['Beneficiary', 'Witness', 'Executor'],
        assets: ['Properties', 'Assets'],
        distribution: ['Distribution of Property']
    };

    const [loading, setLoading] = useState(false);

    const handleFaqTypeChange = (event, newFaqType) => {
        if (newFaqType !== null) {
            setFaqType(newFaqType);
            onFaqTypeChange(newFaqType); // Call the parent function
        }
    };

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        console.log(`Panel: ${panel}, isExpanded: ${isExpanded}`);
        setExpanded(isExpanded ? panel : false);
    };
    


    const toSentenceCase = (str) => {
        return str
            .toLowerCase()
            .replace(/(^\w{1})|(\.\s*\w{1})/g, (match) => match.toUpperCase());
    };

    useEffect(() => {
        const fetchFaqs = async () => {
            try {
                const response = await fetchFaqsApi(); // Replace with your API endpoint
                setFaqs(response.data); // Assuming data is an array of FAQs
                setLoading(false); // Set loading to false after data is fetched
            } catch (err) {
                setLoading(false);
            }
        };
        fetchFaqs();
    }, []);

    // Filter FAQs based on selected category and search query
    const filteredFaqs = faqs.filter((faq) => {
        const searchText = searchQuery.toLowerCase();
        const matchesSearch = faq.question.toLowerCase().includes(searchText);
        // If searchQuery exists, we display all matching results regardless of type
        if (searchText) {
            return matchesSearch;
        }
        // Otherwise, filter by selected faqType
        const matchesCategory = categoryMapping[faqType].includes(faq.category);
        return matchesCategory;
    });

    return (
        <Grid item container xs={12} mt='15px'>
            {/* Toggle Buttons and Search Bar Row */}
            <Box display="flex" alignItems="center" width='100%' mb={2} mr='10px'>
                <ToggleButtonGroup
                    color="primary"
                    value={faqType}
                    exclusive
                    onChange={handleFaqTypeChange}
                    aria-label="FAQ and Resources"
                    sx={{
                        display: 'flex',
                        border: 'none',
                        borderRadius: '20px',
                        marginRight: '16px',
                        '& .MuiToggleButtonGroup-grouped': {
                            borderRadius: '20px',
                        },
                        '& .MuiToggleButtonGroup-grouped:not(:last-of-type)': {
                            borderRadius: '20px',
                        },
                        '& .MuiToggleButtonGroup-grouped:not(:first-of-type)': {
                            borderRadius: '20px',
                            border: '1px solid #3CA1AA',
                        },
                        '& .MuiToggleButtonGroup-grouped:not(:first-of-type).Mui-selected': {
                            borderRadius: '20px',
                            borderLeft: '1px solid #3CA1AA',  // Only apply border when selected
                        }
                    }}
                >
                    <CustomToggleButton2 value="general">
                        <Typography variant='bodyText3'>General</Typography>
                    </CustomToggleButton2>
                    <CustomToggleButton2 value="mypeople">
                        <Typography variant='bodyText3'>My People</Typography>
                    </CustomToggleButton2>
                    <CustomToggleButton2 value="assets">
                        <Typography variant='bodyText3'>My Assets</Typography>
                    </CustomToggleButton2>
                    <CustomToggleButton2 value="distribution">
                        <Typography variant='bodyText3'>My Distribution</Typography>
                    </CustomToggleButton2>
                </ToggleButtonGroup>
            </Box>

            {/* Content Section */}
            <Box width='100%'>
                {faqType && (
                    <Box width='100%'>
                        {/* FAQs Display */}
                        <Box mb={3}>
                            {filteredFaqs.map((faq, index) => (
                                <CustomAccordion
                                    key={index}
                                    expanded={expanded === `panel-${index}`}
                                    onChange={handleAccordionChange(`panel-${index}`)}
                                >
                                    <CustomAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}bh-content`} id={`panel${index}bh-header`}>
                                        <Typography variant='heading2' color='#1E1C1B'> {toSentenceCase(faq.question)}</Typography>
                                    </CustomAccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant='bodyText1' color='#707070'>{faq.answer}</Typography>
                                    </AccordionDetails>
                                </CustomAccordion>
                            ))}
                        </Box>
                    </Box>
                )}
            </Box>
        </Grid>
    );
};

export default FaqSection;