import { Box, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { useState } from 'react';
import { IoAddCircle, IoAddCircleOutline } from "react-icons/io5";

const StyledCard = styled(Box)(({ theme, isFulled }) => ({
  backgroundColor: isFulled ? '#fff' : '#FFF3EA',
  borderRadius: '12px',
  boxShadow: 'none',
  transition: 'border-color 0.10s ease', // Transition only the border color
  border: isFulled ? `2px dashed ${theme.palette.primary.main}` : `1px solid transparent`,
  minWidth: '167px',
  cursor: isFulled ? 'not-allowed' : 'pointer',
  '&:hover': {
    borderColor: isFulled ? theme.palette.primary.main : theme.palette.primary.light,
    boxShadow: isFulled ? 'none' : `0px 10px 10px -8px ${theme.palette.primary.dark}4D`,
  },
}));

const MyPeopleCard = ({ icon: Icon, title, onClick, isFulled }) => {
  const theme = useTheme();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <StyledCard
      isFulled={isFulled}
      onClick={!isFulled ? onClick : undefined} // Disable click if isFulled is true
      onMouseEnter={() => !isFulled && setIsHovered(true)}
      onMouseLeave={() => !isFulled && setIsHovered(false)}
      sx={{ maxHeight: '100px', maxWidth: '167px' }}
    >
      <Box p={'0 8px 10px 8px'} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
        <Box display="flex" alignItems="center" justifyContent="flex-start">
          <Icon style={{ fontSize: '36px' }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="bodyText3">
            {title}
          </Typography>
          {isHovered ? (
            <IoAddCircle style={{ fontSize: '18px', color: theme.palette.primary.main }} />
          ) : (
            <IoAddCircleOutline style={{ fontSize: '18px', color: theme.palette.primary.main }} />
          )}
        </Box>
      </Box>
    </StyledCard>
  );
};

export default MyPeopleCard;

