import { Typography } from '@mui/material';
import { ContextProvider } from 'App';
import useSwalAlert from 'components/DesignSystem/SwalAlert/SwalAlert';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { logoutUserApi } from 'services/profileService';

const LogoutSection = () => {
    const showSwalAlert = useSwalAlert();
    const navigate = useNavigate();
    const { setUser, setLoggedIn } = useContext(ContextProvider)

    const handleLogout = async () => {
        try {
            await logoutUserApi();
            setUser({});
            setLoggedIn(false);
            sessionStorage.removeItem('isDemoActive');
            showSwalAlert({
                position: 'top-center',
                icon: 'success',
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: 'Logged out!',
                showConfirmButton: false,
                timer: 3000,
            });
        } catch (err) {
            console.log('err ', err);
        } finally {
            navigate('/login', { replace: true });
        }
    };

    return (
        <Typography
            variant="bodyText3"
            sx={{
                color: (theme) => theme.palette.error.main,
                mb: 5,
                '&:hover': {
                    textDecoration: 'underline',
                    cursor: 'pointer'
                },
            }}
            onClick={handleLogout}
        >
            Logout
        </Typography>
    );
};

export default LogoutSection;
