import administration from './administration';
import dashboard from './dashboard';
import partnerdashboard from './partnerDashboard';
import reviewer from './reviewer';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, partnerdashboard, administration, reviewer]
};

export default menuItems;
