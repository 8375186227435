import { Box, Dialog, DialogContent, Typography, useTheme, DialogTitle, DialogActions } from '@mui/material';
import { noop } from 'lodash';
import { ContainedButton1, TextedButton1 } from '../Button';

const AlertDialog = ({
    open,
    onClose,
    modelTitle,
    title,
    primaryButtonLabel = 'Keep',
    secondaryButtonLabel = 'Delete',
    onPrimaryButtonClick = noop,
    onSecondaryButtonClick,
    onSecondaryButtonDisabled = false,
    dialogWidth = 'auto',
    children,
    isSingleButton = false,
    titleSx = {},
}) => {
    const theme = useTheme();
    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    border: `1px solid red`,
                    borderRadius: '35px',
                    minWidth: dialogWidth,
                },
            }}
        >
            {(modelTitle && <DialogTitle sx={{ m: 0, p: 2, backgroundColor: theme.palette.error.main, color: '#fff' }} textAlign='center' id="customized-dialog-title">
                <Typography variant="heading1">
                    {modelTitle}
                </Typography>
            </DialogTitle>)}
            <DialogContent sx={{ padding: '32px 40px', textAlign: 'center' }}>
                <Box sx={{ marginBottom: '24px', marginTop: '16px', ...titleSx }}>
                    <Typography variant="heading2">
                        {title}
                    </Typography>
                </Box>
                {children && (<Box sx={{ color: "black", maxHeight: "calc(100vh - 100px)", width: 'calc(100% + 16px)', m: -1, pr: 1, overflow: 'auto' }}>
                    {children}
                </Box>)}
            </DialogContent>
            {onSecondaryButtonClick && (<DialogActions sx={{ display: 'flex', justifyContent: 'center', gap: '16px', marginBottom: '20px' }}>
                <ContainedButton1
                    sx={{
                        background: theme.palette.error.main,
                        width: '160px',
                        height: '45px',
                        '&:hover': {
                            backgroundColor: `${theme.palette.error.main}`,
                            boxShadow: '0px 6px 6px -4px rgba(244, 123, 32, 0.3)',
                        },
                    }}
                    onClick={onPrimaryButtonClick}
                >
                    <Typography variant='bodyText3' color={theme.palette.common.white}>
                        {primaryButtonLabel}
                    </Typography>
                </ContainedButton1>
                <TextedButton1 onClick={onSecondaryButtonClick} disabled={onSecondaryButtonDisabled} sx={{ width: '160px', height: '45px' }}>
                    <Typography variant='bodyText3' color={theme.palette.error.main}>
                        {secondaryButtonLabel}
                    </Typography>
                </TextedButton1>
            </DialogActions>)}
            {isSingleButton && (<DialogActions sx={{ display: 'flex', justifyContent: 'center', gap: '16px', marginBottom: '20px' }}>
                <ContainedButton1
                    sx={{
                        background: theme.palette.error.main,
                        width: '160px',
                        height: '45px',
                        '&:hover': {
                            backgroundColor: `${theme.palette.error.main}`,
                            boxShadow: '0px 6px 6px -4px rgba(244, 123, 32, 0.3)',
                        },
                    }}
                    onClick={onPrimaryButtonClick}
                >
                    <Typography variant='bodyText3' color={theme.palette.common.white}>
                        {primaryButtonLabel}
                    </Typography>
                </ContainedButton1>
            </DialogActions>)}
        </Dialog >
    );
};

export default AlertDialog;
