import { ReactComponent as AssetIcon } from 'assets/panel-assets.svg';
import { ReactComponent as DistributionIcon } from 'assets/panel-distribution.svg';
import { ReactComponent as MyPeopleIcon } from 'assets/panel-my-people.svg';

const administration = {
  id: 'administration',
  type: 'group',
  children: [
    {
      id: 'adminDashboard',
      title: 'Admin Dashboard',
      type: 'item',
      url: '/admin/analyticaldashboard',
      icon: MyPeopleIcon,
      breadcrumbs: false
    },
    {
      id: 'assets',
      title: 'Coupons',
      type: 'item',
      url: '/admin/coupons',
      icon: AssetIcon,
      breadcrumbs: false
    },
    {
      id: 'advisorsPartners',
      title: 'Advisors/Partners',
      type: 'item',
      url: '/admin/partners',
      icon: DistributionIcon,
      breadcrumbs: false
    },
    {
      id: 'reports',
      title: 'Reports',
      type: 'item',
      url: '/admin/reports',
      icon: DistributionIcon,
      breadcrumbs: false
    }
  ]
};

export default administration;
