export const BankList =[
    {
        "value": "AB Bank Ltd",
        "label": "AB Bank Ltd"
    },
    {
        "value": "Airtel Payments Bank Limited",
        "label": "Airtel Payments Bank Limited"
    },
    {
        "value": "American Express Banking Corporation",
        "label": "American Express Banking Corporation"
    },
    {
        "value": "Andhra Pradesh Grameena Vikas Bank",
        "label": "Andhra Pradesh Grameena Vikas Bank"
    },
    {
        "value": "Andhra Pragathi Grameena Bank",
        "label": "Andhra Pragathi Grameena Bank"
    },
    {
        "value": "Arunachal Pradesh Rural Bank",
        "label": "Arunachal Pradesh Rural Bank"
    },
    {
        "value": "Aryavart Bank",
        "label": "Aryavart Bank"
    },
    {
        "value": "Assam Gramin Vikash Bank",
        "label": "Assam Gramin Vikash Bank"
    },
    {
        "value": "Au Small Finance Bank Limited",
        "label": "Au Small Finance Bank Limited"
    },
    {
        "value": "Australia and New Zealand Banking Group Ltd",
        "label": "Australia and New Zealand Banking Group Ltd"
    },
    {
        "value": "Axis Bank Ltd",
        "label": "Axis Bank Ltd"
    },
    {
        "value": "BNP Paribas",
        "label": "BNP Paribas"
    },
    {
        "value": "Bandhan Bank Ltd",
        "label": "Bandhan Bank Ltd"
    },
    {
        "value": "Bangiya Gramin Vikas Bank",
        "label": "Bangiya Gramin Vikas Bank"
    },
    {
        "value": "Bank of America",
        "label": "Bank of America"
    },
    {
        "value": "Bank of Bahrain & Kuwait BSC",
        "label": "Bank of Bahrain & Kuwait BSC"
    },
    {
        "value": "Bank of Baroda",
        "label": "Bank of Baroda"
    },
    {
        "value": "Bank of Ceylon",
        "label": "Bank of Ceylon"
    },
    {
        "value": "Bank of China",
        "label": "Bank of China"
    },
    {
        "value": "Bank of India",
        "label": "Bank of India"
    },
    {
        "value": "Bank of Maharashtra",
        "label": "Bank of Maharashtra"
    },
    {
        "value": "Bank of Nova Scotia",
        "label": "Bank of Nova Scotia"
    },
    {
        "value": "Barclays Bank Plc",
        "label": "Barclays Bank Plc"
    },
    {
        "value": "Baroda Gujarat Gramin Bank",
        "label": "Baroda Gujarat Gramin Bank"
    },
    {
        "value": "Baroda Rajasthan Kshetriya Gramin Bank",
        "label": "Baroda Rajasthan Kshetriya Gramin Bank"
    },
    {
        "value": "Baroda UP Bank",
        "label": "Baroda UP Bank"
    },
    {
        "value": "CSB Bank Ltd",
        "label": "CSB Bank Ltd"
    },
    {
        "value": "CTBC Bank Co.Ltd",
        "label": "CTBC Bank Co.Ltd"
    },
    {
        "value": "Canara Bank",
        "label": "Canara Bank"
    },
    {
        "value": "Capital Small Finance Bank Limited",
        "label": "Capital Small Finance Bank Limited"
    },
    {
        "value": "Central Bank of India",
        "label": "Central Bank of India"
    },
    {
        "value": "Chaitanya Godavari Grameena Bank",
        "label": "Chaitanya Godavari Grameena Bank"
    },
    {
        "value": "Chhattisgarh Rajya Gramin Bank",
        "label": "Chhattisgarh Rajya Gramin Bank"
    },
    {
        "value": "Citibank N.A.",
        "label": "Citibank N.A."
    },
    {
        "value": "City Union Bank Ltd",
        "label": "City Union Bank Ltd"
    },
    {
        "value": "Cooperatieve Rabobank U.A.",
        "label": "Cooperatieve Rabobank U.A."
    },
    {
        "value": "Credit Agricole Corporate & Investment Bank",
        "label": "Credit Agricole Corporate & Investment Bank"
    },
    {
        "value": "Credit Suisse A.G",
        "label": "Credit Suisse A.G"
    },
    {
        "value": "DBS Bank India Limited*",
        "label": "DBS Bank India Limited*"
    },
    {
        "value": "DCB Bank Ltd",
        "label": "DCB Bank Ltd"
    },
    {
        "value": "Dakshin Bihar Gramin Bank",
        "label": "Dakshin Bihar Gramin Bank"
    },
    {
        "value": "Deutsche Bank",
        "label": "Deutsche Bank"
    },
    {
        "value": "Dhanlaxmi Bank Ltd",
        "label": "Dhanlaxmi Bank Ltd"
    },
    {
        "value": "Doha Bank Q.P.S.C",
        "label": "Doha Bank Q.P.S.C"
    },
    {
        "value": "ESAF Small Finance Bank Limited",
        "label": "ESAF Small Finance Bank Limited"
    },
    {
        "value": "Ellaquai Dehati Bank",
        "label": "Ellaquai Dehati Bank"
    },
    {
        "value": "Emirates Bank NBD",
        "label": "Emirates Bank NBD"
    },
    {
        "value": "Equitas Small Finance Bank Limited",
        "label": "Equitas Small Finance Bank Limited"
    },
    {
        "value": "Federal Bank Ltd",
        "label": "Federal Bank Ltd"
    },
    {
        "value": "Fincare Small Finance Bank Limited",
        "label": "Fincare Small Finance Bank Limited"
    },
    {
        "value": "Fino Payments Bank Limited",
        "label": "Fino Payments Bank Limited"
    },
    {
        "value": "First Abu Dhabi Bank PJSC",
        "label": "First Abu Dhabi Bank PJSC"
    },
    {
        "value": "FirstRand Bank Ltd",
        "label": "FirstRand Bank Ltd"
    },
    {
        "value": "HDFC Bank Ltd",
        "label": "HDFC Bank Ltd"
    },
    {
        "value": "HSBC Ltd",
        "label": "HSBC Ltd"
    },
    {
        "value": "Himachal Pradesh Gramin Bank",
        "label": "Himachal Pradesh Gramin Bank"
    },
    {
        "value": "ICICI Bank Ltd",
        "label": "ICICI Bank Ltd"
    },
    {
        "value": "IDBI Bank Ltd",
        "label": "IDBI Bank Ltd"
    },
    {
        "value": "IDFC First Bank Ltd",
        "label": "IDFC First Bank Ltd"
    },
    {
        "value": "India Post Payments Bank Limited",
        "label": "India Post Payments Bank Limited"
    },
    {
        "value": "Indian Bank",
        "label": "Indian Bank"
    },
    {
        "value": "Indian Overseas Bank",
        "label": "Indian Overseas Bank"
    },
    {
        "value": "Induslnd Bank Ltd",
        "label": "Induslnd Bank Ltd"
    },
    {
        "value": "Industrial & Commercial Bank of China Ltd.",
        "label": "Industrial & Commercial Bank of China Ltd."
    },
    {
        "value": "Industrial Bank of Korea",
        "label": "Industrial Bank of Korea"
    },
    {
        "value": "J&K Grameen Bank",
        "label": "J&K Grameen Bank"
    },
    {
        "value": "J.P. Morgan Chase Bank N.A.",
        "label": "J.P. Morgan Chase Bank N.A."
    },
    {
        "value": "JSC VTB Bank",
        "label": "JSC VTB Bank"
    },
    {
        "value": "Jammu & Kashmir Bank Ltd",
        "label": "Jammu & Kashmir Bank Ltd"
    },
    {
        "value": "Jana Small Finance Bank Limited",
        "label": "Jana Small Finance Bank Limited"
    },
    {
        "value": "Jharkhand Rajya Gramin Bank",
        "label": "Jharkhand Rajya Gramin Bank"
    },
    {
        "value": "KEB Hana Bank",
        "label": "KEB Hana Bank"
    },
    {
        "value": "Karnataka Bank Ltd",
        "label": "Karnataka Bank Ltd"
    },
    {
        "value": "Karnataka Gramin Bank",
        "label": "Karnataka Gramin Bank"
    },
    {
        "value": "Karnataka Vikas Grameena Bank",
        "label": "Karnataka Vikas Grameena Bank"
    },
    {
        "value": "Karur Vysya Bank",
        "label": "Karur Vysya Bank"
    },
    {
        "value": "KBC Bank",
        "label": "KBC Bank"
    },
    {
        "value": "KCC Bank",
        "label": "KCC Bank"
    },
    {
        "value": "Kochi Bank",
        "label": "Kochi Bank"
    },
    {
        "value": "Kotak Mahindra Bank Ltd",
        "label": "Kotak Mahindra Bank Ltd"
    },
    {
        "value": "Laxmi Vilas Bank",
        "label": "Laxmi Vilas Bank"
    },
    {
        "value": "Madhya Bihar Gramin Bank",
        "label": "Madhya Bihar Gramin Bank"
    },
    {
        "value": "Madhya Pradesh Gramin Bank",
        "label": "Madhya Pradesh Gramin Bank"
    },
    {
        "value": "Maharashtra Gramin Bank",
        "label": "Maharashtra Gramin Bank"
    },
    {
        "value": "Meghalaya Co-operative Apex Bank Ltd",
        "label": "Meghalaya Co-operative Apex Bank Ltd"
    },
    {
        "value": "Mumbai District Central Cooperative Bank Ltd",
        "label": "Mumbai District Central Cooperative Bank Ltd"
    },
    {
        "value": "Nagaland Rural Bank",
        "label": "Nagaland Rural Bank"
    },
    {
        "value": "Nainital Bank Ltd",
        "label": "Nainital Bank Ltd"
    },
    {
        "value": "National Bank of Fujairah",
        "label": "National Bank of Fujairah"
    },
    {
        "value": "National Bank of Oman",
        "label": "National Bank of Oman"
    },
    {
        "value": "National Housing Bank",
        "label": "National Housing Bank"
    },
    {
        "value": "National Rural Livelihoods Mission",
        "label": "National Rural Livelihoods Mission"
    },
    {
        "value": "Nedbank",
        "label": "Nedbank"
    },
    {
        "value": "Netherlands Bank",
        "label": "Netherlands Bank"
    },
    {
        "value": "New India Cooperative Bank Ltd",
        "label": "New India Cooperative Bank Ltd"
    },
    {
        "value": "Nirmal Bang Securities",
        "label": "Nirmal Bang Securities"
    },
    {
        "value": "North East Small Finance Bank Limited",
        "label": "North East Small Finance Bank Limited"
    },
    {
        "value": "Odisha Gramya Bank",
        "label": "Odisha Gramya Bank"
    },
    {
        "value": "PT Bank Maybank Indonesia TBK",
        "label": "PT Bank Maybank Indonesia TBK"
    },
    {
        "value": "Paschim Banga Gramin Bank",
        "label": "Paschim Banga Gramin Bank"
    },
    {
        "value": "Paytm Payments Bank Limited",
        "label": "Paytm Payments Bank Limited"
    },
    {
        "value": "Prathama UP Gramin Bank",
        "label": "Prathama UP Gramin Bank"
    },
    {
        "value": "Puduvai Bharathiar Grama Bank",
        "label": "Puduvai Bharathiar Grama Bank"
    },
    {
        "value": "Punjab & Sind Bank",
        "label": "Punjab & Sind Bank"
    },
    {
        "value": "Punjab Gramin Bank",
        "label": "Punjab Gramin Bank"
    },
    {
        "value": "Punjab National Bank",
        "label": "Punjab National Bank"
    },
    {
        "value": "Qatar National Bank (Q.P.S.C.)",
        "label": "Qatar National Bank (Q.P.S.C.)"
    },
    {
        "value": "RBL Bank Ltd",
        "label": "RBL Bank Ltd"
    },
    {
        "value": "Rajasthan Marudhara Gramin Bank",
        "label": "Rajasthan Marudhara Gramin Bank"
    },
    {
        "value": "SBM Bank (India) Limited*",
        "label": "SBM Bank (India) Limited*"
    },
    {
        "value": "Saptagiri Grameena Bank",
        "label": "Saptagiri Grameena Bank"
    },
    {
        "value": "Sarva Haryana Gramin Bank",
        "label": "Sarva Haryana Gramin Bank"
    },
    {
        "value": "Saurashtra Gramin Bank",
        "label": "Saurashtra Gramin Bank"
    },
    {
        "value": "Sberbank",
        "label": "Sberbank"
    },
    {
        "value": "Shinhan Bank",
        "label": "Shinhan Bank"
    },
    {
        "value": "Shivalik Small Finance Bank Limited",
        "label": "Shivalik Small Finance Bank Limited"
    },
    {
        "value": "Societe Generale",
        "label": "Societe Generale"
    },
    {
        "value": "Sonali Bank PLC",
        "label": "Sonali Bank PLC"
    },
    {
        "value": "South Indian Bank Ltd",
        "label": "South Indian Bank Ltd"
    },
    {
        "value": "Standard Chartered Bank",
        "label": "Standard Chartered Bank"
    },
    {
        "value": "State Bank of India",
        "label": "State Bank of India"
    },
    {
        "value": "Sumitomo Mitsui Banking Corporation",
        "label": "Sumitomo Mitsui Banking Corporation"
    },
    {
        "value": "Suryoday Small Finance Bank Limited",
        "label": "Suryoday Small Finance Bank Limited"
    },
    {
        "value": "Tamil Nadu Grama Bank",
        "label": "Tamil Nadu Grama Bank"
    },
    {
        "value": "Tamilnad Mercantile Bank Ltd",
        "label": "Tamilnad Mercantile Bank Ltd"
    },
    {
        "value": "Telangana Grameena Bank",
        "label": "Telangana Grameena Bank"
    },
    {
        "value": "Tripura Gramin Bank",
        "label": "Tripura Gramin Bank"
    },
    {
        "value": "UCO Bank",
        "label": "UCO Bank"
    },
    {
        "value": "Ujjivan Small Finance Bank Limited",
        "label": "Ujjivan Small Finance Bank Limited"
    },
    {
        "value": "Union Bank of India",
        "label": "Union Bank of India"
    },
    {
        "value": "United Overseas Bank Ltd",
        "label": "United Overseas Bank Ltd"
    },
    {
        "value": "Unity Small Finance Bank Limited",
        "label": "Unity Small Finance Bank Limited"
    },
    {
        "value": "Utkal Grameen bank",
        "label": "Utkal Grameen bank"
    },
    {
        "value": "Utkarsh Small Finance Bank Limited",
        "label": "Utkarsh Small Finance Bank Limited"
    },
    {
        "value": "Uttar Bihar Gramin Bank",
        "label": "Uttar Bihar Gramin Bank"
    },
    {
        "value": "Uttarakhand Gramin Bank",
        "label": "Uttarakhand Gramin Bank"
    },
    {
        "value": "Uttarbanga Kshetriya Gramin Bank",
        "label": "Uttarbanga Kshetriya Gramin Bank"
    },
    {
        "value": "Vidharbha Konkan Gramin Bank",
        "label": "Vidharbha Konkan Gramin Bank"
    },
    {
        "value": "Woori Bank",
        "label": "Woori Bank"
    },
    {
        "value": "YES Bank Ltd",
        "label": "YES Bank Ltd"
    }
]
