import { Box, Button, Typography, useTheme, Stack } from '@mui/material';
import ButtonCheckBox from 'components/DesignSystem/ButtonCheckBox';
import { format } from 'date-fns';
import { formatPrice } from 'utils/formatPrice';

const ServiceDetailCard = ({ title = '', subtitle = '',
    price = '',
    row,
    checkedSubscriptions,
    onPurchaseClick,
    user,
    isCheckBoxVisible,
    activeCall,
    activeReview
}) => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                mb: 3,
                // width: '718px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '15px',
                backgroundColor: theme.palette.secondary.lighter,
                border: `1px solid ${theme.palette.secondary.lighter}`,
                borderRadius: '10px',
            }}
        >
            {/* Left Content */}
            <Box>
                <Typography variant="bodyText3" color="#1E1C1B">
                    {title}
                </Typography>
                <Typography variant="suggestiveText" color={theme.palette.grey[400]}>
                    <Box component="span" sx={{ display: 'block' }}>
                        {subtitle}
                    </Box>
                </Typography>
            </Box>

            {/* Right Content (Price and Add Button) */}
            <Box sx={{ display: 'flex', alignItems: 'center', mx: 2 }}>
                <Stack justifyContent={"center"} spacing={1} direction="row" >
                    {row.discounted_price > -1 ? <strike><Typography variant="bodyText4" color="#1E1C1B" sx={{ mr: 2 }}>₹{price === 0 ? "Free" : formatPrice(price)}</Typography></strike> :
                        <Typography variant="bodyText4" color="#1E1C1B" sx={{ mr: 2 }}>{price === 0 ? "Free" : `₹${formatPrice(price)}`}</Typography>}
                </Stack>

                {row.discounted_price > -1 &&
                    <Stack justifyContent={"center"} direction="row">
                        {row.price > 0 && <Typography variant="bodyText4" color="#1E1C1B" sx={{ mr: 2 }}>Offer Price: ₹</Typography>}
                        <Typography variant="bodyText4" color="#1E1C1B" sx={{ mr: 2 }}
                        >{row.discounted_price === 0 ? "Free" : formatPrice(row.discounted_price)}</Typography>
                    </Stack>}
                {(isCheckBoxVisible && <ButtonCheckBox
                    checked={checkedSubscriptions.some((checkedSubscription) => checkedSubscription.id === row.id)}
                    onChange={() => {
                        onPurchaseClick(row);
                    }}
                    disabled={user.role === 'partner' && user.partner_enroll_status !== 'APPROVED'}
                    bgColor='transparent'
                />)}
                {row.calls > 0 && activeCall &&
                    <Typography variant="suggestiveText" textAlign={"center"} color={"error.main"}>You already have an active
                        call. Please wait for our call.</Typography>}
                {(activeReview && row.id === 2) && (
                    <Typography variant="suggestiveText" textAlign={"center"} color={"error.main"}>You already have an active
                        review. Please wait for our call.</Typography>
                )}
            </Box>
        </Box>
    );
};

export default ServiceDetailCard;
