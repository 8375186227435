import { Skeleton } from '@mui/material';
import DashboardCard from 'components/commonComponents/cards/DashboardCard';
import { startCase } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchTopPerformingPartners } from 'services/adminService';

const TopPerformingPartnerCard = () => {
  const [topPerformingPartnerData, setTopPerformingPartnerData] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTopPerformingPartner = async () => {
      try {
        const response = await fetchTopPerformingPartners();
        setTopPerformingPartnerData(response?.data || {});
      } catch (error) {
        console.error('Error fetching top performing data:', error);
      } finally {
        setLoading(false); // Set loading to false after API call
      }
    };
    fetchTopPerformingPartner();
  }, []);

  const redirectPage = () => {
    navigate('/admin/topPerformer');
  };

  return (
    <>
      {
        loading ? (
          <Skeleton variant="rectangular" height={135} sx={{ borderRadius: '1.25rem' }} />
        ) : (
          <DashboardCard
            title="Top Performing Partner"
            subtitle={startCase(topPerformingPartnerData?.partner_name) || '--'}
            handleClick={redirectPage}
            isClickable
          />
        )
      }
    </>
  );
};

export default TopPerformingPartnerCard;
