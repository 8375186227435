import axios from "axios";

export const previewDraftApi = async (type) => {
    const response = await axios.patch(`/wills/draft/preview`);
    return response;
}


export const generateWillApi = async (apiPayload) => {
    console.log("request got at api")
    const response = await axios.patch(`/wills/draft/generate`, apiPayload);
    return response;
}