import axios from "axios";

export const fetchGenerics = async (type) => {
    const response = await axios.get(`/generics/${type}`);
    return response;
}

export const fetchAssets = async () => {
    const response = await axios.get('/properties');
    return response;
}

export const fetchWills = async () => {
    const response = await axios.get('/wills');
    return response;
}

export const deleteWill = async (scenario, id) => {
    const response = await axios.delete(`/wills/${scenario}/${id}`);
    return response;
};

export const deleteScenario = async (id) => {
    const response = await axios.delete(`/wills/scenario/${id}`);
    return response;
};

export const updateMappings = async (selectedScenario, payloadData = {}) => {
    const response = await axios.post(`/wills/bulk/map/${selectedScenario}`, payloadData)
    return response;
};