// ProfileIcon.js
import { Box, Divider, IconButton, Tooltip, useTheme } from '@mui/material';
import { ContextProvider } from 'App';
import { ReactComponent as HelpIcon } from 'assets/help-button.svg';
import { ReactComponent as ProfileIconSelected } from 'assets/profile-icon-selected.svg';
import { ReactComponent as ProfileIconNil } from 'assets/profile-icon.svg';
import ExitDemoButton from 'components/actionsButtons/ExitDemoButton';
import Icon from 'components/DesignSystem/Icon';
import ProfileMenu from 'components/DesignSystem/ProfileMenu';
import useSwalAlert from 'components/DesignSystem/SwalAlert';
import HelpSection from 'components/featureComponent/helpResourceSection/HelpResourceSection';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutUserApi } from 'services/profileService';
import { MENU_OPEN } from 'store/actions';

export const ProfileIcon = () => {
    const [isHovered, setIsHovered] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const { user, setUser, setLoggedIn } = useContext(ContextProvider);
    const navigate = useNavigate(); // Hook for navigation
    const dispatch = useDispatch();
    const showSwalAlert = useSwalAlert();
    const hideProfile = user?.role === 'admin' ||  user?.role === 'reviewer';

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const profileOpen = Boolean(anchorEl);

    const handleProfileClick = () => {
        dispatch({ type: MENU_OPEN, id: null });
        navigate('/profilePage'); // Replace with your profile screen route
        handleMenuClose();
    };

    const handleLogoutClick = async () => {
        try {
            await logoutUserApi();
            setUser({});
            setLoggedIn(false);
            sessionStorage.removeItem('isDemoActive');
            showSwalAlert({
                position: 'top-center',
                icon: 'success',
                color: '#fff',
                background: '#00838F',
                toast: true,
                title: 'Logged out!',
                showConfirmButton: false,
                timer: 3000,
            });
        } catch (err) {
            console.log('err ', err);
        } finally {
            navigate('/login', { replace: true });
            handleMenuClose();
        }
    };

    return (
        <>
            <Tooltip title="Go to Profile">
                <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleClick}>
                    {isHovered ? (
                        <ProfileIconSelected height={'48px'} style={{ border: '1px solid red', borderRadius: '50px' }} />
                    ) : (
                        <ProfileIconNil height={'48px'} />
                    )}
                </Box>
            </Tooltip>
            <ProfileMenu
                hideProfileButton={hideProfile}
                anchorEl={anchorEl}
                open={profileOpen}
                onClose={handleMenuClose}
                name={user?.full_name}
                email={user?.email}
                handleProfileClick={handleProfileClick}
                handleLogoutClick={handleLogoutClick}
            />
        </>
    );
};

const PageContainer = ({ children, showHelpIcon = true, defaultFaqType = 'general', showBackButton = false }) => {
    const theme = useTheme();
    const [openHelpSection, setOpenHelpSection] = useState(false);
    const handleHelpSectionClose = () => setOpenHelpSection(false);

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100%',
                    position: 'relative',
                    bgcolor: theme.palette.grey[0],
                }}
            >
                {/* Profile section */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        gap: '1.25rem',
                    }}
                >
                    <ExitDemoButton />
                    <ProfileIcon /> {/* Use the ProfileIcon component */}
                </Box>

                {/* <Divider sx={{ my: 2 }} /> */}
                {showBackButton ? (
                    <BackButtonWithDivider />
                ) : (
                    <Divider sx={{ marginLeft: '8px', borderBottomWidth: '1px', my: 2 }} />
                )}

                {/* Content area */}
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    {children}
                </Box>

                {/* Bulb icon */}
                {showHelpIcon && (
                    <IconButton
                        sx={{
                            position: 'fixed',
                            bottom: '3.125rem',
                            right: '3.125rem',
                            bgcolor: '#FFF3E0',
                            color: '#FF8C42',
                            '&:hover': {
                                bgcolor: '#FFE0B2',
                            },
                        }}
                        onClick={() => setOpenHelpSection(true)}
                    >
                        <HelpIcon />
                    </IconButton>
                )}
            </Box>
            <HelpSection open={openHelpSection} handleClose={handleHelpSectionClose} defaultFaqType={defaultFaqType} />
        </>
    );
};

export default PageContainer;

const BackButtonWithDivider = () => {
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1);
    };
    const theme = useTheme();
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mt: '5px' }}>
            <IconButton onClick={handleBackClick} sx={{ background: theme.palette.grey[200] }}>
                <Icon name="back-arrow-icon.png" width={6.5} height={9} />
            </IconButton>
            <Divider sx={{ flexGrow: 1, marginLeft: '8px', borderBottomWidth: '1px' }} />
        </Box>
    );
};
