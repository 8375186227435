import { useRoutes } from 'react-router-dom';
import AuthenticationRoutes from './AuthenticationRoutes';
import MainRoutes from './MainRoutes';
// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const routes = useRoutes([
        AuthenticationRoutes,
        MainRoutes]);
    return routes;
}
