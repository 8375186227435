import { Box, Button, Typography, useTheme, Stack } from '@mui/material';
import ButtonCheckBox from 'components/DesignSystem/ButtonCheckBox';
import Icon from 'components/DesignSystem/Icon';
import { format } from 'date-fns';
import { formatPrice } from 'utils/formatPrice';

const GwdImageContentCard = ({ price, checkedSubscriptions, discountedPrice, row, subscribed, getSubscribedExpiry, onPurchaseClick, user, }) => {
  const theme = useTheme();
  return (
    <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '21px', overflow: 'hidden', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', mb: '30px', position: 'relative', }} >
      <Box>
        <Icon name="make-your-own-will-img.png" width={'100%'} height={'100%'} />
      </Box>
      <Box sx={{ backgroundColor: theme.palette.secondary.main, padding: '21px 20px 24px', color: '#fff', display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'absolute', bottom: 0, width: '100%', }}>
        <Box>
          <Typography variant="smallText" sx={{ display: 'flex', alignItems: 'center', mb: 1 }} gutterBottom={false}>
            <Icon name="blue-tick-icon.png" style={{ marginRight: '5px' }} />
            Create & generate your Will easily & hassle-free
          </Typography>
          <Typography variant="smallText" sx={{ display: 'flex', alignItems: 'center' }} gutterBottom={false}>
            <Icon name="blue-tick-icon.png" style={{ marginRight: '5px' }} />
            Make any changes you need to your Will for 30 days
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: '10px', }}>
          <Stack justifyContent={'center'} spacing={1} direction="row" sx={{ marginRight: '16px' }}>
            {discountedPrice > -1 ? (
              <strike>
                <Typography variant="bodyText4">₹{price === 0 ? 'Free' : formatPrice(price)}</Typography>
              </strike>
            ) : (
              <Typography variant="bodyText4">{row.price === 0 ? 'Free' : `₹${formatPrice(price)}`}</Typography>
            )}
          </Stack>
          {discountedPrice > -1 && (
            <Stack justifyContent={'center'} direction="row">
              {row.price > 0 && (
                <Typography fontWeight={'bold'} variant="bodyText4">
                  Offer Price: ₹
                </Typography>
              )}

              <Typography variant="bodyText4" fontWeight={'bold'}>
                {row.discounted_price === 0 ? 'Free' : formatPrice(row.discounted_price)}
              </Typography>
            </Stack>
          )}
          {((row.id === 1 && !subscribed)) && (
            <ButtonCheckBox
              checked={checkedSubscriptions.some((checkedSubscription) => checkedSubscription.id === row.id)}
              onChange={() => {
                onPurchaseClick(row);
              }}
              disabled={user.role === 'partner' && user.partner_enroll_status !== 'APPROVED'}
            />
          )}
          {row.id === 1 && subscribed && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography sx={{ margin: '6px' }} variant="suggestiveText" textAlign={'center'}>
                Your subscription will expire on {format(new Date(getSubscribedExpiry), 'dd-MM-yyyy')}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default GwdImageContentCard;
