import axios from "axios";
import IdleTimeout from "components/IdleTimeout";
import GDPR from "layout/Gdpr";
import NavigationScroll from "layout/NavigationScroll";
import { createContext, useEffect, useState } from "react";
import ReactGA from 'react-ga4';
import { useLocation } from "react-router-dom";
import usePreventScreenshot from "./hooks/usePreventScreenshot";
import Routes from "./routes";
import "./styles.css";
import ThemeProvider from "./theme";

const GA4_TRACKING_ID = process.env.REACT_APP_GA4_TRACKING_ID ?? "G-HTKPF0G88W";
ReactGA.initialize(GA4_TRACKING_ID);

export const ContextProvider = createContext({});

export default function App() {
    const [user, setUser] = useState({});
    const [loggedIn, setLoggedIn] = useState(false);
    const location = useLocation();
    const fetchUser = async () => {
        const response = await axios.get('/auth/user');
        setUser(response.data.user);
        return response;
    };

    useEffect(() => {
        // Send the pageview hit to GA4
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, [location.pathname]); // Triggered on path change

    usePreventScreenshot();

    return (
        <ThemeProvider>
            {/* <LoadingContext.Provider value={{ isLoading, showHelp, setShowHelp, editProfile }}> */}
            <ContextProvider.Provider value={{ user, fetchUser, loggedIn, setLoggedIn, setUser }}>
                <NavigationScroll>
                    {/* <Router /> */}
                    <Routes />
                    {loggedIn && (
                        <IdleTimeout />
                    )}
                    {!user.consentAccepted && loggedIn && (
                        <GDPR />
                    )}
                </NavigationScroll>
            </ContextProvider.Provider>
        </ThemeProvider>
    );
}
