import {
  Box,
  Chip,
  Table,
  TableBody,
  TableContainer,
  Typography
} from '@mui/material';
import axios from 'axios';
import { CustomTableCell, CustomTablePagination, CustomTableRow } from 'components/DesignSystem/Table/CustomTable';
import dayjs from 'dayjs';
import { filter } from 'lodash';
import Scrollbar from 'oldLayouts/components/scrollbar';
import { UserListHead, UserListToolbar } from 'oldLayouts/components/user';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import PageContainer from 'views/dashboard/PageContainer';

function descendingComparator(a, b, orderBy) {
  if (orderBy === 'service') {
    // Sort by the length of the service array
    const aLength = a[orderBy] ? a[orderBy].length : 0;
    const bLength = b[orderBy] ? b[orderBy].length : 0;
    return bLength - aLength;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        JSON.stringify(_user ?? {})
          .toLowerCase()
          .indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const TABLE_HEAD = [
  {
    id: 'email',
    numeric: true,
    label: 'Email',
    alignRight: false,
  },
  {
    id: 'name',
    disablePadding: true,
    label: 'Client Name',
    alignRight: false,
  },
  {
    id: 'phone',
    numeric: true,
    label: 'Phone',
    alignRight: false,
  },
  {
    id: 'pan',
    label: 'PAN',
    alignRight: false,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    alignRight: false,
  },
  {
    id: 'status',
    label: 'Status',
    alignRight: false,
  },
  {
    id: 'service',
    label: 'Service',
    alignRight: false,
  },
];

const NumberOfClientsPage = () => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('email');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [numberOfClientsData, setNumberOfClientsData] = useState([]);
  const [filterName, setFilterName] = useState('');
  const navigate = useNavigate();

  const fetchClientsData = async () => {
    try {
      const response = await axios.get(`/admin/total-testators?list=${true}`);
      const { testatorList } = response.data;
      const updatedTestatorList = testatorList?.map((client) => {
        if (client?.subscriptions?.length > 0) {
          return {
            ...client,
            status: 'Service purchased',
            service: client.subscriptions.map((service) => (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px', margin: '4px 0' }}>
                <Chip size="small" key={service} label={service} variant="outlined" />
              </Box>
            )),
          };
        }
        return {
          ...client,
          status: 'Service not purchased',
          service: null,
        };
      });
      setNumberOfClientsData(updatedTestatorList || []);
    } catch (error) {
      console.error('Error fetching number of clients data:', error);
    }
  };

  useEffect(() => {
    fetchClientsData();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - numberOfClientsData.length) : 0;

  const visibleRows = useMemo(
    () =>
      stableSort(numberOfClientsData || [], getComparator(order, orderBy), filterName).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, numberOfClientsData, filterName]
  );

  const handleBackClick = () => {
    navigate('/admin/analyticaldashboard');
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  return (
    <PageContainer showBackButton showHelpIcon={false}>
      <Helmet>
        <title> Number of Clients | Get Will Done </title>
      </Helmet>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <Typography variant="title1">Number of Clients</Typography>
        <Box sx={{ margin: '18px 0 12px 0' }}>
          <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} />
        </Box>
      </Box>
      <Box>
        <Scrollbar>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <UserListHead order={order} orderBy={orderBy} headLabel={TABLE_HEAD} onRequestSort={handleRequestSort} />
              <TableBody>
                {visibleRows.map((row, index) => (
                  <CustomTableRow hover tabIndex={-1} key={row.id}>
                    <CustomTableCell>
                      {row.email}
                    </CustomTableCell>
                    <CustomTableCell>{row.name}</CustomTableCell>
                    <CustomTableCell>{row.phone}</CustomTableCell>
                    <CustomTableCell>{row.pan}</CustomTableCell>
                    <CustomTableCell >{dayjs(row.createdAt).format('DD-MM-YYYY')}</CustomTableCell>
                    <CustomTableCell>{row.status}</CustomTableCell>
                    <CustomTableCell>{row.service}</CustomTableCell>
                  </CustomTableRow>
                ))}
                {emptyRows > 0 && (
                  <CustomTableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <CustomTableCell colSpan={TABLE_HEAD.length} />
                  </CustomTableRow>
                )}
              </TableBody>
              {numberOfClientsData.length === 0 && (
                <TableBody>
                  <CustomTableRow>
                    <CustomTableCell align="center" colSpan={TABLE_HEAD.length} sx={{ py: 3 }}>
                      No Client Data Found
                    </CustomTableCell>
                  </CustomTableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
        <CustomTablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={numberOfClientsData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </PageContainer>
  );
};

export default NumberOfClientsPage;
